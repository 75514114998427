import { useState, useRef, useMemo } from "react";
import { useSidebar, useProfile } from "@hooks";
import { useToken } from "@chakra-ui/react";
import {
  ParticipantsSingleView,
  ParticipantsGridView,
  Avatar,
  InactiveAvatar,
} from "@components";

const variants = {
  avatar: "avatar",
  list: "list",
};

const Participants = ({ participants, guests, hostId, isGridView }) => {
  const containerRef = useRef();
  const { profile } = useProfile();
  const [gray400] = useToken("colors", ["gray.400"]);
  const [scroll, setScroll] = useState(0);
  const { participantView, handleViewUserProfile } = useSidebar();
  const isHost = (id) => hostId === id;
  const sortByHost = (participant) => (isHost(participant.props.id) ? -1 : 0);
  const view = variants[participantView];

  const orderedParticipants = (participants) => {
    return participants.slice().sort(sortByHost);
  };

  const handleScroll = (scrollOffset) => {
    containerRef.current.style.scrollBehavior = "smooth";
    containerRef.current.scrollTop += scrollOffset;
    setScroll(scroll + scrollOffset);
  };

  const participantsInTH = useMemo(() => {
    return participants.map((participant) => {
      const { _id, avatar, firstName, username, color } = participant;
      const isUserProfile = profile?._id === _id;
      const avatarImage = isUserProfile ? profile?.avatar : avatar; 
      const avatarColor = isUserProfile ? profile?.color : color;
      const avatarName = isUserProfile ? (profile?.username || firstName) : (username || firstName);
      return (
        <Avatar
          key={_id}
          id={_id}
          name={avatarName}
          color={avatarColor}
          avatar={avatarImage}
          variant={view}
          showBorder={true}
          size="lg"
          onClick={() => handleViewUserProfile(participant)}
        />
      );
    });
  }, [participants, profile, handleViewUserProfile, view]);

  // filtering to remove any user already in the participant list
  const filteredGuestList = useMemo(() => {
    const participantIds = participants.map((participant) => participant._id);
    return guests.filter((guest) => !participantIds.includes(guest._id));
  }, [participants, guests]);

  const guestParticipants = useMemo(() => {
    return filteredGuestList.map((participant) => {
      const { _id, avatar, firstName, username } = participant;
      return (
        <InactiveAvatar
          key={_id}
          id={_id}
          name={username || firstName}
          color={gray400}
          avatar={avatar}
          variant={view}
          showBorder={true}
          size="lg"
          onClick={() => handleViewUserProfile(participant)}
        />
      );
    })
  }, [filteredGuestList, handleViewUserProfile, view, gray400]);

  const renderParticipantType = () => {
    const allParticipants = participantsInTH.concat(guestParticipants);
    return orderedParticipants(allParticipants);
  };

  return isGridView ? (
    <ParticipantsGridView renderParticipantType={renderParticipantType} />
  ) : (
    <ParticipantsSingleView
      containerRef={containerRef}
      handleScroll={handleScroll}
      renderParticipantType={renderParticipantType}
    />
  );
};

export default Participants;
