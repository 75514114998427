import { Box, Flex, Text, Textarea, chakra } from "@chakra-ui/react";
import { CharacterCounter } from "@components/ActivityTypes/Send";

const SendTextArea = ({
  name,
  value,
  onChange,
  onBlur,
  isInvalid,
  placeholder,
}) => {
  return (
    <Box
      border="1px solid"
      borderColor="gray.200"
      borderRadius="8px"
      position="relative"
      shadow="sm"
    >
      <Flex bg="gray.50" borderTopRadius="8px" p="12px">
        <Text fontSize="12px" fontWeight="400" color="gray.800">
          <chakra.span color="gray.500">To:</chakra.span> Everyone
        </Text>
      </Flex>
      <Textarea
        name={name}
        size="xs"
        maxLength="75"
        maxH="75px"
        borderTopRadius="0"
        borderColor="transparent"
        placeholder={placeholder}
        _placeholder={{ color: "dark.100" }}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        isInvalid={isInvalid}
      />
      <CharacterCounter count={value.length || 0} max={75} />
    </Box>
  );
};

export default SendTextArea;
