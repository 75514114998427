import { useField } from "formik";
import {
  FormControl,
  FormLabel,
  Select as ChakraSelect,
} from "@chakra-ui/react";
import { HiOutlineSelector } from "react-icons/hi";

const Select = ({
  name,
  value,
  label,
  placeholder,
  requireValidation,
  children,
  ...restProps
}) => {
  const [field, meta] = useField(name);
  const { onChange, onBlur } = field;

  return (
    <FormControl isRequired={requireValidation}>
      <FormLabel htmlFor={name} variant="auth">
        {label}
      </FormLabel>
      <ChakraSelect
        variant="auth"
        name={name}
        icon={<HiOutlineSelector />}
        onChange={onChange}
        onBlur={onBlur}
        defaultValue={value}
        placeholder={placeholder}
        {...restProps}
      >
        {children}
      </ChakraSelect>
    </FormControl>
  );
};

export default Select;
