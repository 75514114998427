import { Button, Icon, Link } from "@chakra-ui/react";
import { $Routes } from "@routes";
import { FaChartLine } from "react-icons/fa";

const ReportButton = ({ isZoomApp, onClick, link, text }) => {
  const linkProps = {
    href: link,
    isExternal: true,
  };

  const buttonProps = isZoomApp ? { onClick } : { href: $Routes.rootPage };

  return (
    <Link {...(!isZoomApp && linkProps)}>
      <Button
        mb="1"
        size="sm"
        variant="primary"
        isFullWidth={true}
        leftIcon={<Icon w="15px" h="15px" as={FaChartLine} />}
        {...buttonProps}
      >
        {text}
      </Button>
    </Link>
  );
};

export default ReportButton;
