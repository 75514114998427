import { addTokenToHeader, refreshTokenAndRetryRequest } from "@utils/rtkApiHelpers";

//TODO: add test case for expired token
export const customBaseQuery = (fetchBaseQuery) => async (args, api, extraOptions) => {
  const defaultArgs = { ...args };
  const headers = addTokenToHeader(args.headers);
  const updatedFetchArgs = { ...defaultArgs, headers, credentials: "include" };

  let result = await fetchBaseQuery(updatedFetchArgs, api, extraOptions);

  const error = result?.error;
  const isTokenError = error?.status === 403 && error?.data?.message === "Token is expired";

  if (isTokenError) {
    const response = await refreshTokenAndRetryRequest(fetchBaseQuery, api, defaultArgs, headers);
    result = response || result;
  }

  return {
    ...result,
    data: result?.data?.content || {},
  };
};
