import activitiesIcons from "@constants/activitiesIcons";
import { Box, Flex, Text, Image } from "@chakra-ui/react";

const ReportActivityItem = ({ type, title, text }) => {
  return (
    <Box p="9px" bg="gray.50" borderRadius="6px" mb="1">
      <Flex alignItems="center">
        <Image w="1.5em" h="1.5em" mr="1" src={activitiesIcons[type]} />
        <Text fontSize="12px" fontWeight="600" color="dark.400">
          {title}
        </Text>
      </Flex>
      <Text fontSize="10px" fontWeight="400" color="dark.300" ml="1.75rem">
        {text}
      </Text>
    </Box>
  );
};

export default ReportActivityItem;
