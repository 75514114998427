import {
  participantJoinedMeeting,
  participantLeftMeeting,
  meeetingEnded,
  sendPoints,
  recieveAnalytics,
  receiveEPulse,
  disconnectSocket,
  disconnectSocketListener
} from "@socket"
import { meetingApi } from "@store/api/meeting";
import {
  updateParticipantJoinedMeeting,
  updateParticipantLeft,
  clearMeetingState,
  updateEPulseData,
} from "@store/meetingSlice";
import { clearAllNotifications } from "@store/notificationsSlice";
import { updatePoints, updateEScore } from "@store/engagementSlice";
import { stopCurrentAgenda } from "@store/agendasSlice";
import { showConnectFromAnotherClientModal } from "@store/notificationsSlice";
import {
  socketInitiated,
  removeDataFromLocalStorage,
  removeWebUserFromMeeting,
  showSuccessToast
} from "@store/customActions";
import { isZoomApp } from "@utils/helpers";
import { USER_MEETING_INFO_KEY } from "@constants";

const meetingSocketMiddlewares = (api) => (next) => (action) => {
  if (action.type === socketInitiated.type) {
    participantJoinedMeeting.subscribe((data) => {
      api.dispatch(updateParticipantJoinedMeeting(data.meetingObject));
    });
  
    participantLeftMeeting.subscribe((data) => {
      api.dispatch(
        updateParticipantLeft({
          profileId: action.payload.profileId,
          meetingObject: data.meetingObject,
        })
      );
    });
  
    meeetingEnded.subscribe((data) => {
      api.dispatch(stopCurrentAgenda());
      api.dispatch(clearAllNotifications());
      api.dispatch(clearMeetingState());
      if (!isZoomApp) {
        api.dispatch(removeDataFromLocalStorage(USER_MEETING_INFO_KEY));
      }
      disconnectSocket();
    });

    recieveAnalytics.subscribe((data) => {
      api.dispatch(updateEScore({ eScore: data.engagementPercentage }));
    });

    sendPoints.subscribe((data) => {
      const { totalPoints } = data;
      api.dispatch(updatePoints({ points: totalPoints }));
    });

    disconnectSocketListener.subscribe(() => {
      const store = api.getState();
      if (store.meeting.data?.inAMeeting) {
        api.dispatch(showConnectFromAnotherClientModal(true));
      }
      api.dispatch(clearMeetingState());
    });
  }

  if (action.type === socketInitiated.type && action.payload.isHostOrCohost) {
    receiveEPulse.subscribe((data) => {
      api.dispatch(updateEPulseData(data));
    });
  }

  if (action.type === removeWebUserFromMeeting.type) {
    api.dispatch(removeDataFromLocalStorage(USER_MEETING_INFO_KEY));
    api.dispatch(meetingApi.endpoints.leaveMeetingFromWeb.initiate({
      platformMeetingInstanceId: action.payload.platformMeetingInstanceId,
      userId: action.payload.userId,
    }));
    api.dispatch(clearMeetingState());
    api.dispatch(showSuccessToast("You have successfully left the meeting on Townhall"));
    disconnectSocket();
  }

  return next(action);
};

export default meetingSocketMiddlewares;
