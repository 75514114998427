import { useDisclosure } from "@chakra-ui/react";
import { AgendaMeetingItem, ActivityView, EditAgenda } from "@components";

const SingleAgenda = ({ agenda }) => {
  const isAgendaDisabled = !!agenda?.endTime;
  const {
    isOpen: isModalOpen,
    onOpen: onModalOpen,
    onClose: onModalClose,
  } = useDisclosure();

  const handleEdit = () => {
    onModalOpen();
  };

  return (
    <>
      <AgendaMeetingItem
        title={agenda?.title}
        onEdit={handleEdit}
        isDisabled={isAgendaDisabled}
      />
      <EditAgenda
        isOpen={isModalOpen}
        onClose={onModalClose}
        agenda={agenda}
      />
      <ActivityView agenda={agenda} />
    </>
  );
};

export default SingleAgenda;
