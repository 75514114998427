import { forwardRef } from "react";
import { Box } from "@chakra-ui/react";

const Card = forwardRef(({ children, footer, innerRef, ...restProps }) => {
  return (
    <>
      <Box
        ref={innerRef}
        borderRadius="lg"
        border="1px solid #EEEFF2"
        boxShadow="0px 10px 50px rgba(0, 35, 178, 0.05)"
        {...restProps}
      >
        {children}
      </Box>
      {footer}
    </>
  );
});

export default Card;
