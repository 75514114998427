import { useState, useEffect, useRef } from "react";
import { Text, Box, Flex, Progress, Image } from "@chakra-ui/react";
import { LOADER_MESSAGES } from "@constants/ePulseLoadingMessages";

const EPulseChartMessageLoader = ({ ePulseDataIsShowing }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const intervalRef = useRef();

  const updateIndex = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % LOADER_MESSAGES.length);
  };

  useEffect(() => {
    if (ePulseDataIsShowing) {
      return;
    }

    intervalRef.current = setInterval(updateIndex, 5000);

    return () => {
      clearInterval(intervalRef.current);
    };
  }, [ePulseDataIsShowing, LOADER_MESSAGES.length]);

  return (
    <Box
      position="absolute"
      left="50%"
      top="50%"
      transform="translate(-50%, -50%)"
      w="200px"
      borderRadius="7px"
      shadow="md"
      bg="white"
      p="3"
      zIndex="2"
    >
      <Progress
        borderRadius="full"
        size="sm"
        value={LOADER_MESSAGES[currentIndex]?.progress}
        bgColor="gray.200"
        colorScheme="purple"
        mb="2"
        sx={{
          "& > div:first-of-type": {
            transitionProperty: "width",
          },
        }}
      />
      <Box borderRadius="7px" shadow="sm" p="1">
        <Flex alignItems="flex-start">
          <Image mr="1" boxSize="3" src={LOADER_MESSAGES[currentIndex]?.icon} />
          <Text color="gray.600" fontSize="x-small" fontWeight={500}>
            {LOADER_MESSAGES[currentIndex]?.text}
          </Text>
        </Flex>
      </Box>
    </Box>
  );
};

export default EPulseChartMessageLoader;
