import { Button, Tooltip } from "@chakra-ui/react";

const AgendaButton = ({
  variant,
  disabled,
  onClick,
  isLoading,
  tooltip,
  children,
  ...rest
}) => {
  const button = (
    <Button
      variant={variant}
      size="sm"
      onClick={onClick}
      disabled={disabled}
      isLoading={isLoading}
      {...rest}
    >
      {children}
    </Button>
  );

  if (tooltip) {
    return (
      <Tooltip hasArrow shouldWrapChildren label={tooltip}>
        {button}
      </Tooltip>
    );
  }

  return button;
};

export default AgendaButton;
