import { Box, Image, Text, Flex } from "@chakra-ui/react";
import nsiLogo from "@assets/images/logo-nsi.png";
import finraLogo from "@assets/images/logo-finra.png";
import armyLogo from "@assets/images/logo-army.png";
import hpLogo from "@assets/images/logo-hp.png";

const MarketingQuote = () => {
  return (
    <Box w="sm" mt="4">
      <Text fontSize="xs" fontWeight={500} color="gray.300" mb="2">
        Built on Research with:
      </Text>
      <Flex
        alignItems="center"
        justifyContent="space-between"
        borderRadius="full"
        bg="white"
        p="3"
      >
        <Image src={nsiLogo} />
        <Image src={finraLogo} />
        <Image src={armyLogo} />
        <Image src={hpLogo} />
      </Flex>
    </Box>
  );
};

export default MarketingQuote;
