import { Button, DarkMode } from "@chakra-ui/react";
import { ConditionalWrapper } from "@components";

const ClearAllButton = ({ isProView, handleRemoveAllNotifications }) => {
  return (
    <ConditionalWrapper
      condition={!isProView}
      wrapper={(children) => <DarkMode>{children}</DarkMode>}
    >
      <Button
        variant="secondary"
        size="xs"
        mb="1"
        onClick={handleRemoveAllNotifications}
      >
        Clear all
      </Button>
    </ConditionalWrapper>
  );
};

export default ClearAllButton;
