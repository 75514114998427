import { useZoomSdk, useWebView } from "@hooks";
import { isZoomApp } from "@utils/helpers";

const hooks = {
  zoomApp: useZoomSdk,
  default: useWebView,
};

const useSdk = () => {
  const sdk = isZoomApp ? hooks.zoomApp : hooks.default;

  return sdk();
}

export default useSdk;
