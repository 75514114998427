import { RequireAuth } from "@components";
import { HostContainer } from "@components";

class ElementBuilder {
  constructor(config, licenseType) {
    this.component = <config.component />;
    this.builtComponent = <config.component />;
    this.config = config;
    this.licenseType = licenseType;
  }

  withAuthContainer() {
    if (this.config.container) {
      return this;
    }

    this.builtComponent = this.config.requiresAuthentication ? (
      <RequireAuth>{this.builtComponent}</RequireAuth>
    ) : (
      this.builtComponent
    );
    return this;
  }

  withProContainer() {
    if (this.config.isPro) {
      this.builtComponent = <HostContainer>{this.builtComponent}</HostContainer>;
    }
    return this;
  }

  withConditionalRender(conditionalvalue) {
    if (this.config.conditionalRender) {
      this.builtComponent = conditionalvalue ? (
        <this.config.conditionalRender />
      ) : (
        this.builtComponent
      );
    }
    return this;
  }

  withOwnContainer() {
    const Container = this.config.container;
    if (Container) {
      this.builtComponent = <Container>{this.component}</Container>;
    }
    return this;
  }

  build() {
    return this.builtComponent;
  }
}

export { ElementBuilder };
