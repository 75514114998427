import { Text, Image } from "@chakra-ui/react";
import stepTwoImage from "@assets/images/host-onboarding-step2.gif";
import stepThreeImage from "@assets/images/host-onboarding-step3.gif";
import stepFourImage from "@assets/images/host-onboarding-step4.gif";

export const TOUR_SAMPLE_AGENDA = {
  _id: "64c2b55ef5b288dad074c2ab",
  userId: "640a1b0b69b7086d55f776e0",
  title: "Sample Agenda",
};

const StepText = ({ text }) => (
  <Text fontSize="12px" fontWeight="400" color="dark.400" mb="2">
    {text}
  </Text>
);

export const TOUR_STEPS = [
  {
    target: "body",
    placement: "center",
    title: "Host meetings with Townhall PRO",
    content: (
      <StepText text="In this quick tutorial, we'll show you how to host meetings like a PRO in just a few simple steps." />
    ),
  },
  {
    target: "#host-onboarding-step-1 > li:nth-child(1)",
    placement: "bottom-end",
    content: (
      <StepText
        text={`Townhall is organized through agendas. Let's take a look at the
    "Sample Agenda".`}
      />
    ),
  },
  {
    target: "#host-onboarding-step-2",
    placement: "bottom-end",
    content: (
      <>
        <Image src={stepTwoImage} mb="2" />
        <StepText
          text={`To get instant and continuous feedback on audience engagement, click
          the "Go Live" button within each agenda.`}
        />
      </>
    ),
  },
  {
    target: "#host-onboarding-step-2",
    placement: "bottom-end",
    offset: -100,
    content: (
      <>
        <Image src={stepThreeImage} mb="2" />
        <StepText
          text="With the E-Pulse, you can read the room even if cameras and mics are
          off!"
        />
        <StepText
          text={[
            "Is engagement dropping? Add an Activity like a ",
            <strong>poll</strong>,
            " or ",
            <strong>quiz</strong>,
            ".",
          ]}
        />
      </>
    ),
  },
  {
    target: "#host-onboarding-step-2",
    placement: "bottom-end",
    offset: -100,
    content: (
      <>
        <Image src={stepFourImage} mb="2" />
        <StepText
          text={`After you "Stop" the Agenda, you can check out the data in the
          E-Report.`}
        />
      </>
    ),
  },
  {
    target: ".host-onboarding-step-4",
    placement: "bottom-end",
    content: (
      <StepText
        text="Townhall PRO provides a summary E-Report instantly! You can also view
    the detailed report (.csv or .pdf) to look at performance and
    attendance."
      />
    ),
  },
  {
    target: "body",
    placement: "center",
    title: "Success!",
    content: (
      <>
        <StepText text="You're now a PRO host. We just sent a sample E-Report to your email." />
        <StepText text="Check out the attendance and engagement analytics!" />
      </>
    ),
  },
];
