import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  points: 0,
  coins: 0,
  eScore: 0,
};

export const notificationsSlice = createSlice({
  name: "engagement",
  initialState,
  reducers: {
    updateCoins: (state, action) => {
      const { coins } = action.payload;
      state.coins = coins;
    },
    updatePoints: (state, action) => {
      const { points } = action.payload;
      state.points = points;
    },
    updateEScore: (state, action) => {
      const { eScore } = action.payload;
      state.eScore = eScore;
    },
    clearEngagementState: () => initialState,
  },
});

const { reducer, actions } = notificationsSlice;

export const { updateCoins, updatePoints, updateEScore, clearEngagementState } = actions;

export default reducer;
