// Define color scales
const dark = {
  100: "#AAAEBE",
  200: "#7F859F",
  300: "#535D7F",
  400: "#2B355C",
  500: "#000C3E",
  600: "#000C3B",
  700: "#1A1F46",
  800: "#00092B",
};

const gray = {
  25: "#FCFCFD",
  50: "#F9FAFB",
  100: "#F2F4F7",
  200: "#EAECF0",
  300: "#D0D5DD",
  400: "#98A2B3",
  500: "#647187",
  600: "#475467",
  700: "#344054",
  800: "#1D2939",
  900: "#101828",
};

const blue = {
  25: "#EBF9FF",
  50: "#D7F2FE",
  100: "#C2ECFE",
  200: "#AEE6FE",
  300: "#86D9FD",
  400: "#5DCDFD",
  500: "#35C0FC",
  600: "#2A9BCB",
  700: "#20769B",
  800: "#15506A",
  900: "#103E52",
};

const red = {
  25: "#FFFBFA",
  50: "#FEF3F2",
  100: "#FEE4E2",
  200: "#FECDCA",
  300: "#FDA29B",
  400: "#F97066",
  500: "#F04438",
  600: "#D92D20",
  700: "#B42318",
  800: "#912018",
  900: "#7A271A",
};

const yellow = {
  25: "#FFFCF5",
  50: "#FFFAEB",
  100: "#FEF0C7",
  200: "#FEDF89",
  300: "#FEC84B",
  400: "#FDB022",
  500: "#F79009",
  600: "#DC6803",
  700: "#B54708",
  800: "#93370D",
  900: "#7A2E0E",
};

const green = {
  25: "#F6FEF9",
  50: "#ECFDF3",
  100: "#D1FADF",
  200: "#A6F4C5",
  300: "#6CE9A6",
  400: "#32D583",
  500: "#12B76A",
  600: "#039855",
  700: "#027A48",
  800: "#05603A",
  900: "#054F31",
};

const purple = {
  25: "#ECE5FD",
  50: "#D8CCFB",
  100: "#C5B3F9",
  200: "#B299F7",
  300: "#8B66F2",
  400: "#6533EE",
  500: "#3E00EA",
  600: "#3200BB",
  700: "#25008C",
  800: "#19005E",
  900: "#130046",
};

// Export color styles
const colors = {
  dark,
  gray,
  purple,
  blue,
  yellow,
  red,
  green,

  // Default added colors
  black: "#000000",
  white: "#FFFFFF",
  link: "#13D1FB",
  granite: "#4E5378",

  primary: {
    400: purple[400],
  },
  success: green[500],
  error: red[500],
  warning: yellow[500],
  gradient: `linear-gradient(268.62deg, ${purple[200]} -50.64%, ${purple[600]} 73.74%)`,
};

export default colors;
