import PropTypes from "prop-types";
import { Avatar } from "@components";
import { Image } from "@chakra-ui/react";
import pointsIcon from "@assets/svg/icon-points.svg";
import coinsIcon from "@assets/svg/icon-coin.svg";

const NotificationImage = ({ type, meta }) => {
  let data;

  switch (type) {
    case "participant":
      const { avatar, firstName, color } = meta || {};
      data = {
        avatar,
        firstName,
        color,
      };
      break;
    case "points":
      data = pointsIcon;
      break;
    case "coins":
      data = coinsIcon;
      break;

    default:
      break;
  }

  return type === "participant" ? (
    <Avatar size="xs" showBorder={true} {...data} />
  ) : (
    <Image src={data} />
  );
};

NotificationImage.propTypes = {
  type: PropTypes.string,
};

export default NotificationImage;
