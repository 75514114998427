import React from "react";
import { Box, Flex, Icon, Text, Button } from "@chakra-ui/react";
import { IoIosStats } from "react-icons/io";
import { Tooltip } from "@components";
import { generateSendToolUrl } from "@utils/helpers";
import { useSdkUtils } from "@hooks";
import { SAMPLE_EREPORT_PDF, SAMPLE_EREPORT_CSV }  from "@constants";

const INFO_TEXT = "Townhall’s engagement data, insights and research-based gamification improve your remote team’s focus and productivity.";

const sharedButtonProps = {
  variant: "link",
  p: 0,
  m: 0,
  minW: "auto",
  color: "primary.400",
  fontSize: "inherit",
  fontWeight: 600
};

const FirstTimerViewSampleReport = () => {
  const { openUrl } = useSdkUtils();

  return (
    <>
      <Text fontSize="xs" lineHeight={1.5}>Ready to boost and measure engagement?</Text>
      <Box bg="gray.200" p={2} borderRadius="md" mt={2}>
        <Flex justifyContent="space-between" alignItems="center" width="100%">
          <Flex alignItems="center" width="calc(100% - 25px)">
            <Icon as={IoIosStats} w={4} h={4} mr={1} />
            <Text fontSize="xs" lineHeight={1.5}>
              View sample {" "}
              <Button
                {...sharedButtonProps}
                onClick={() => openUrl(generateSendToolUrl(SAMPLE_EREPORT_PDF))}
              >
                E-Report
              </Button>
              {" or "}
              <Button
                {...sharedButtonProps}
                onClick={() => openUrl(generateSendToolUrl(SAMPLE_EREPORT_CSV))}
              >
                CSV
              </Button>
              {" file"}
            </Text>
          </Flex>
          <Tooltip label={INFO_TEXT} hasArrow position="auto" />
        </Flex>
      </Box>
    </>
  )
}

export default FirstTimerViewSampleReport;
