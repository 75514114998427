import { Flex, Button, Text } from "@chakra-ui/react";
import { Modal } from "@components";

const LeaveMeetingModal = ({ isOpen, handleClose, handleLeaveMeeting }) => {
  return (
    <Modal
      isOpen={isOpen}
      onClose={handleClose}
      title="Leave Meeting"
      size="xs"
      showCloseButton
      heading="Leave Meeting?"
    >
      <>
        <Text color="gray.900" fontSize="14px" mb={4}>
          Are you sure you want to leave this meeting?
        </Text>
        <Flex width="full">
          <Button size="sm" onClick={handleClose} flex={1}>
            Cancel
          </Button>
          <Button
            variant="error"
            size="sm"
            onClick={handleLeaveMeeting}
            ml={3}
            flex={1}
          >
            Leave
          </Button>
        </Flex>
      </>
    </Modal>
  );
};

export default LeaveMeetingModal;
