import React, { useState, useMemo } from "react";
import { CheckboxGroup, VStack } from "@chakra-ui/react";
import { Checkbox } from "@components";

const DefaultActivityView = ({
  options = [],
  setModalState,
}) => {
  const valueArray = useMemo(() => options.map(({ option, _id }) => ({
    value: option,
    checked: false,
    id: _id
  })), [options]);
  const [checked, setChecked] = useState(valueArray);

  const handleChange = (index) => {
    const optionsCopy = [...checked];
    const checkedArray = optionsCopy.map((opt) => {
      if (opt.id === optionsCopy[index].id) {
        return { ...opt, checked: !opt.checked }
      }
      return { ...opt, checked: false };
    });
    setChecked(checkedArray);
    setModalState({
      userResponse: checkedArray.filter((option) => option.checked).map((opt) => opt.id),
      isValidInput: checkedArray.filter((option) => option.checked).length === 1,
    });
  };

  return (
    <CheckboxGroup>
      <VStack spacing="2" align="stretch">
        {options.map(({ option, _id }, index) => (
          <Checkbox
            key={_id}
            placement="right"
            width="100%"
            color={checked[index]?.checked ? "white" : "gray.600"}
            value={option}
            isChecked={checked[index]?.checked}
            onChange={() => handleChange(index)}
          >
            {option}
          </Checkbox>
        ))}
      </VStack>
    </CheckboxGroup>
  );
};

export default DefaultActivityView;
