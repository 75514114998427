import { Grid, GridItem, Button, Image } from "@chakra-ui/react";
import { COLORS } from "@constants";
import tick from "@assets/small-tick.svg";

const ProfileColorSettings = ({ colorSrc, setColorSrc, setIsSaveDisabled }) => {
  return (
    <Grid templateColumns="repeat(6, 1fr)" gap={4} mt={3}>
      {COLORS.map((currentColor) => (
        <GridItem
          key={currentColor}
          display="flex"
          justifyContent="center"
          alignItems="center"
          width="28px"
          height="28px"
          outline="3px solid"
          outlineColor={
            colorSrc === currentColor
              ? "rgba(53, 192, 252, 0.2)"
              : "transparent"
          }
          borderRadius="50%"
        >
          <Button
            variant="picker"
            bg={currentColor}
            _hover={{
              bg: currentColor,
            }}
            _active={{
              bg: currentColor,
            }}
            onClick={() => {
              setColorSrc(currentColor);
              setIsSaveDisabled(false);
            }}
          >
            {colorSrc === currentColor && <Image src={tick} alt="" />}
          </Button>
        </GridItem>
      ))}
    </Grid>
  );
};

export default ProfileColorSettings;
