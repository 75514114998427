import { useLocation } from "react-router-dom";
import { Box } from "@chakra-ui/react";
import { $Routes } from "@routes";

function Layout({ children }) {
  const location = useLocation();
  const bgColor = location.pathname === $Routes.rootPage ? "purple.900" : "dark.800";

  return (
    <Box backgroundColor={bgColor} height="100vh" overflow="hidden">
      {children}
    </Box>
  );
}

export default Layout;
