import { useCallback, useEffect } from "react";
import {
  Flex,
  Button,
  Heading,
  Image,
  VStack,
  Text,
  Box,
  useDisclosure,
} from "@chakra-ui/react";
import { useValidateMutation } from "@store/api/payments";
import { useToastNotification, useProfile } from "@hooks";
import { Modal } from "@components";
import { LICENSE_TYPES } from "@constants";
import { PRO_TRIAL_LIST_ITEMS, TRIAL_CODE, TRIAL_DAYS } from "@constants/proTrial";
import confettiBgImage from "@assets/images/confetti.gif";

const ProStartTrialModal = ({ handleBackToHome }) => {
  const {
    profile: { licenseType, trialPeriodStartDate },
  } = useProfile();
  const [startTrial, { isLoading: isActivatingTrial }] = useValidateMutation();
  const { showSuccessToast, showErrorToast } = useToastNotification();
  const {
    isOpen: isModalOpen,
    onOpen: onOpenModal,
    onClose: onCloseModal,
  } = useDisclosure();

  const isBasicAndNotTrialedUser =
    licenseType === LICENSE_TYPES.Basic && !trialPeriodStartDate;

  const handleStartTrial = useCallback(async () => {
    try {
      await startTrial({
        accessCode: TRIAL_CODE,
      }).unwrap();

      onCloseModal();
      showSuccessToast("Trial activated successfully");
    } catch (error) {
      console.log(error);
      showErrorToast(
        "There was an error activating your free trial. Please try again."
      );
    }
  }, [showSuccessToast, showErrorToast, startTrial, onCloseModal]);

  useEffect(() => {
    if (isBasicAndNotTrialedUser) {
      onOpenModal();
    }
  }, [isBasicAndNotTrialedUser, onOpenModal]);

  return (
    <Modal
      size="xs"
      isOpen={isModalOpen}
      closeOnOverlayClick={false}
      onClose={handleBackToHome}
      showCloseButton
    >
      <VStack
        spacing="3"
        mb="3"
        bg={`url(${confettiBgImage}) no-repeat center center / cover`}
      >
        <Heading
          color="dark.600"
          fontWeight="700"
          fontSize="md"
          textAlign="center"
        >
          Premium Host
          <br />
          Features Unlocked!
        </Heading>
        <Box>
          {PRO_TRIAL_LIST_ITEMS.map((item, index) => (
            <Flex
              justify="flex-start"
              alignItems="center"
              key={`list-item-${index}`}
              my="2"
            >
              <Image src={item.icon} boxSize="5" mr="3" />
              <Flex flexDirection="column">
                <Text color="purple.800" fontSize="sm">
                  {item.text}
                </Text>
              </Flex>
            </Flex>
          ))}
        </Box>
        <Box background="purple.25" borderRadius="md" p="5">
          <VStack spacing="2">
            <Text fontSize="xs" color="purple.700">
              {`Enjoy these FREE for the next ${TRIAL_DAYS} days!`}
            </Text>
            <Button
              variant="primary"
              w="full"
              size="sm"
              isLoading={isActivatingTrial}
              loadingText="Activating"
              onClick={handleStartTrial}
            >
              Let's Go!
            </Button>
          </VStack>
        </Box>
      </VStack>
    </Modal>
  );
};

export default ProStartTrialModal;
