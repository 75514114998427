const FormLabel = {
  variants: {
    auth: {
      color: "dark.200",
      fontSize: "small",
    },
  },
};

export default FormLabel;
