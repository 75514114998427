import { useNotifications, useSidebar } from "@hooks";
import { NotificationItem } from "@components";
import { Box } from "@chakra-ui/react";

const Notifications = ({ notifications }) => {
  const { handleRemoveNotification } = useNotifications();
  const { isProView } = useSidebar();
  const scrollViewBg = isProView ? "transparent" : "dark.600";
  const scrollViewBorder = isProView ? "gray.100" : "dark.400";
  const hoverBgColor = isProView ? "gray.200" : "dark.400";
  const buttonColorHover = isProView ? "#FFFFFF" : "dark.600";

  return (
    <Box
      borderRadius="8px"
      border="1px solid"
      borderColor={scrollViewBorder}
      bg={scrollViewBg}
      height="calc(100vh - 550px)"
      overflowY="scroll"
      sx={{
        "&::-webkit-scrollbar": {
          display: "none",
        },
      }}
    >
      {notifications.map((notification) => (
        <NotificationItem
          key={notification._id}
          notification={notification}
          containerProps={{
            _hover: {
              bg: hoverBgColor,
            },
          }}
          buttonProps={{
            variant: "ghost",
            color: "#98A2B3",
            _hover: { bg: buttonColorHover },
            _active: { bg: buttonColorHover },
          }}
          onClose={() =>
            handleRemoveNotification(notification._id, notification.type)
          }
        />
      ))}
    </Box>
  );
};

export default Notifications;
