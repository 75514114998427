import colors from "@utils/theme/colors";

const Tabs = {
  variants: {
    profile: {
      tablist: {
        borderBottom: `1px solid ${colors.dark[400]}`,
      },
      tab: {
        fontSize: "14px",
        pb: 3,
        color: colors.dark[200],
        borderBottom: "3px solid transparent",

        _selected: {
          fontSize: "14px",
          color: colors.white,
          borderBottom: `1px solid ${colors.blue[600]}`,
          pb: 3,
        },
      },
      tabpanel: {
        p: 0,
      },
    },
    agenda: {
      tablist: {
        borderBottom: `1px solid ${colors.gray[100]}`,
      },
      tab: {
        fontSize: "11px",
        fontWeight: 600,
        pb: 2,
        color: colors.dark[200],
        borderBottom: "1px solid transparent",

        _selected: {
          fontSize: "11px",
          color: colors.purple[500],
          borderBottom: `1px solid ${colors.purple[500]}`,
          pb: 2,
        },
      },
      tabpanel: {
        p: 0,
      },
    },
    sidebar: {
      tablist: {
        borderBottom: `1px solid ${colors.dark[400]}`,
      },
      tab: {
        fontSize: "12px",
        pb: 2,
        px: "10px",
        color: colors.white,
        borderBottom: "3px solid transparent",

        _selected: {
          fontSize: "12px",
          color: colors.blue[600],
          borderBottom: `1px solid ${colors.blue[600]}`,
        },
      },
      tabpanel: {
        p: "20px 0",
      },
    },
    icons: {
      tab: {
        borderLeft: "4px solid transparent",

        "& svg": {
          color: "gray.400",
        },

        _selected: {
          bg: "dark.500",
          borderLeft: "4px solid",
          borderColor: "blue.500",

          "& svg": {
            color: "blue.500",
          },
        },
      },
      tabpanel: {
        p: 1,
        bg: "dark.700",
      },
    },
  },
};

export default Tabs;
