import { Tooltip, Icon } from "@chakra-ui/react";
import { FaRegQuestionCircle } from "react-icons/fa";

const HelpTooltip = ({ label, placement }) => {
  return (
    <Tooltip placement={placement} hasArrow shouldWrapChildren label={label}>
      <Icon as={FaRegQuestionCircle} color="gray.400" w="0.5em" h="0.5em" />
    </Tooltip>
  );
};

export default HelpTooltip;
