import { isRejectedWithValue } from "@reduxjs/toolkit";
import {
  showErrorToast,
  removeDataFromLocalStorage,
  removeWebUserFromMeeting,
  triggerUserReauthenticate
} from "@store/customActions";
import { logOutUser } from "@store/profileSlice";
import { clearMeetingState } from "@store/meetingSlice";
import { clearActivityState } from "@store/activitySlice";
import { clearAgendaState } from "@store/agendasSlice";
import { clearEngagementState } from "@store/engagementSlice";
import { $Routes } from "@routes";
import { isZoomApp } from "@utils/helpers";
import { JWT_TOKEN_NAME, LOGOUT_STATUS_CODES } from "@constants";
import { DEFAULT_TOAST_ERROR_MESSAGE } from "@constants/errorMessages";

const authenticationMiddleware = (api) => (next) => (action) => {
  // check if error is rejected with 401 and logout user
  // TODO: edit to only happen when token cannot be verified or expired
  if (isRejectedWithValue(action) && LOGOUT_STATUS_CODES.includes(action.payload.status) && !isZoomApp) {
    api.dispatch(logOutUser(action.payload?.data?.statusCode));
  }

  if (logOutUser.type === action.type) {
    const state = api.getState();
    const meetingState = state.meeting;

    if (meetingState?.data?.inAMeeting && !isZoomApp) {
      const userState = state.profile;
      const userId = userState.data?._id;
      const meetingObject = meetingState?.data?.meetingObject;
      const platformMeetingInstanceId = meetingObject?.meetingInstances?.[0].platformMeetingInstanceId;
      api.dispatch(removeWebUserFromMeeting({ platformMeetingInstanceId, userId }));
    }

    api.dispatch(removeDataFromLocalStorage(JWT_TOKEN_NAME));
    api.dispatch(clearMeetingState());
    api.dispatch(clearActivityState());
    api.dispatch(clearAgendaState());
    api.dispatch(clearEngagementState());

    if (!isZoomApp && action.payload === "10003") {
      window.location.replace($Routes.loginPage)
    }
  }

  if (triggerUserReauthenticate.type === action.type) {
    api.dispatch(logOutUser());
  }

  // check if calls to any of the auth endpoints fails and display error message
  if (action.type.endsWith("authApi/executeMutation/rejected")) {
    api.dispatch(showErrorToast(action.payload?.data?.message || DEFAULT_TOAST_ERROR_MESSAGE));
  }

  return next(action);
};

export default authenticationMiddleware;
