import { AuthInput } from "./AuthInput";
import { DefaultInput } from "./DefaultInput";

const InputComponents = {
  auth: AuthInput,
  default: DefaultInput
}

const Input = (props) => {
  const Input = InputComponents[props.variant];
  return Input ? <Input {...props} /> : <DefaultInput {...props} />
}

export default Input;
