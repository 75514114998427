import { useState } from "react";
import { useFetchAgendasQuery } from "@store/api/agenda";
import { Box } from "@chakra-ui/react";
import {
  CreateAgenda,
  DuplicateAgendaModal,
  DeleteAgendaModal,
  AgendaTabs
} from "@components";

const AgendaContainer = () => {
  const { isLoading: isLoadingAgendas } = useFetchAgendasQuery({}, { refetchOnMountOrArgChange: true });
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isDuplicateModalOpen, setDuplicateModalOpen] = useState(false);
  const [agendaId, setAgendaId] = useState("");

  return (
    <>
      <DeleteAgendaModal
        agendaId={agendaId}
        onClose={setIsDeleteModalOpen}
        isOpen={isDeleteModalOpen}
      />
      <DuplicateAgendaModal
        agendaId={agendaId}
        onClose={setDuplicateModalOpen}
        isOpen={isDuplicateModalOpen}
      />
      <Box
        borderColor="gray.200"
        mb="2"
        bg="white"
        shadow="sm"
      >
        <CreateAgenda/>
        <AgendaTabs
          isLoadingAgendas={isLoadingAgendas}
          setAgendaId={setAgendaId}
          setIsDeleteModalOpen={setIsDeleteModalOpen}
          setDuplicateModalOpen={setDuplicateModalOpen}
        />
      </Box>
    </>
  );
};

export default AgendaContainer;
