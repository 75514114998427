import React from "react";
import { ButtonGroup as CBG, Box } from "@chakra-ui/react";

const inactiveStyles = {
  color: "white",
  borderBottom: "none",
  bg: "none",
  boxShadow: "none",
  _hover: {
    bg: "none",
  },
};

export const ButtonGroup = React.forwardRef((props, ref) => {
  return <CBG ref={ref} styles={{ m: 0, p: 0 }} {...props} />;
});


const ToggleButtonGroup = React.forwardRef(({ onChange, value, children, groupProps, ...rest }, ref) => {
  if (!children) {
    throw new Error("ToggleButtonGroup must have children");
  }

  return (
    <Box {...rest} ref={ref}>
      <ButtonGroup {...groupProps}>
        {React.Children.map(children, CHILD => {
          return React.cloneElement(CHILD, {
            onClick: () => {
              if (value === CHILD?.props?.value) return;
              onChange(CHILD?.props?.value);
              CHILD?.props?.onClick()
            },
            ...(value !== CHILD?.props?.value && { sx: inactiveStyles }),
          });
        })}
      </ButtonGroup>
    </Box>
  );
});

export default ToggleButtonGroup;
