export const COUNTRY_LIST = [
  "United States of America",
  "Afghanistan",
  "Albania",
  "Algeria",
  "American Samoa",
  "Andorra",
  "Angola",
  "Anguilla",
  "Antarctica",
  "Antigua and Barbuda",
  "Argentina",
  "Armenia",
  "Aruba",
  "Australia",
  "Austria",
  "Azerbaijan",
  "Bahamas",
  "Bahrain",
  "Bangladesh",
  "Barbados",
  "Belarus",
  "Belgium",
  "Belize",
  "Benin",
  "Bermuda",
  "Bhutan",
  "Bolivia (Plurinational State of)",
  "Bonaire, Sint Eustatius and Saba",
  "Bosnia and Herzegovina",
  "Botswana",
  "Bouvet Island",
  "Brazil",
  "British Indian Ocean Territory",
  "Brunei Darussalam",
  "Bulgaria",
  "Burkina Faso",
  "Burundi",
  "Cabo Verde",
  "Cambodia",
  "Cameroon",
  "Canada",
  "Cayman Islands",
  "Central African Republic",
  "Chad",
  "Chile",
  "China",
  "Christmas Island",
  "Cocos (Keeling) Islands",
  "Colombia",
  "Comoros",
  "Congo (the Democratic Republic of the)",
  "Congo",
  "Cook Islands",
  "Costa Rica",
  "Croatia",
  "Cuba",
  "Curaçao",
  "Cyprus",
  "Czechia",
  "Côte d'Ivoire",
  "Denmark",
  "Djibouti",
  "Dominica",
  "Dominican Republic",
  "Ecuador",
  "Egypt",
  "El Salvador",
  "Equatorial Guinea",
  "Eritrea",
  "Estonia",
  "Eswatini",
  "Ethiopia",
  "Falkland Islands [Malvinas]",
  "Faroe Islands",
  "Fiji",
  "Finland",
  "France",
  "French Guiana",
  "French Polynesia",
  "French Southern Territories",
  "Gabon",
  "Gambia",
  "Georgia",
  "Germany",
  "Ghana",
  "Gibraltar",
  "Greece",
  "Greenland",
  "Grenada",
  "Guadeloupe",
  "Guam",
  "Guatemala",
  "Guernsey",
  "Guinea",
  "Guinea-Bissau",
  "Guyana",
  "Haiti",
  "Heard Island and McDonald Islands",
  "Holy See",
  "Honduras",
  "Hong Kong",
  "Hungary",
  "Iceland",
  "India",
  "Indonesia",
  "Iran (Islamic Republic of)",
  "Iraq",
  "Ireland",
  "Isle of Man",
  "Israel",
  "Italy",
  "Jamaica",
  "Japan",
  "Jersey",
  "Jordan",
  "Kazakhstan",
  "Kenya",
  "Kiribati",
  "Korea (the Democratic People's Republic of)",
  "Korea (the Republic of)",
  "Kuwait",
  "Kyrgyzstan",
  "Lao People's Democratic Republic",
  "Latvia",
  "Lebanon",
  "Lesotho",
  "Liberia",
  "Libya",
  "Liechtenstein",
  "Lithuania",
  "Luxembourg",
  "Macao",
  "Madagascar",
  "Malawi",
  "Malaysia",
  "Maldives",
  "Mali",
  "Malta",
  "Marshall Islands",
  "Martinique",
  "Mauritania",
  "Mauritius",
  "Mayotte",
  "Mexico",
  "Micronesia (Federated States of)",
  "Moldova (the Republic of)",
  "Monaco",
  "Mongolia",
  "Montenegro",
  "Montserrat",
  "Morocco",
  "Mozambique",
  "Myanmar",
  "Namibia",
  "Nauru",
  "Nepal",
  "Netherlands",
  "New Caledonia",
  "New Zealand",
  "Nicaragua",
  "Niger",
  "Nigeria",
  "Niue",
  "Norfolk Island",
  "Northern Mariana Islands",
  "Norway",
  "Oman",
  "Pakistan",
  "Palau",
  "Palestine, State of",
  "Panama",
  "Papua New Guinea",
  "Paraguay",
  "Peru",
  "Philippines",
  "Pitcairn",
  "Poland",
  "Portugal",
  "Puerto Rico",
  "Qatar",
  "Republic of North Macedonia",
  "Romania",
  "Russian Federation",
  "Rwanda",
  "Réunion",
  "Saint Barthélemy",
  "Saint Helena, Ascension and Tristan da Cunha",
  "Saint Kitts and Nevis",
  "Saint Lucia",
  "Saint Martin (French part)",
  "Saint Pierre and Miquelon",
  "Saint Vincent and the Grenadines",
  "Samoa",
  "San Marino",
  "Sao Tome and Principe",
  "Saudi Arabia",
  "Senegal",
  "Serbia",
  "Seychelles",
  "Sierra Leone",
  "Singapore",
  "Sint Maarten (Dutch part)",
  "Slovakia",
  "Slovenia",
  "Solomon Islands",
  "Somalia",
  "South Africa",
  "South Georgia and the South Sandwich Islands",
  "South Sudan",
  "Spain",
  "Sri Lanka",
  "Sudan",
  "Suriname",
  "Svalbard and Jan Mayen",
  "Sweden",
  "Switzerland",
  "Syrian Arab Republic",
  "Taiwan",
  "Tajikistan",
  "Tanzania, United Republic of",
  "Thailand",
  "Timor-Leste",
  "Togo",
  "Tokelau",
  "Tonga",
  "Trinidad and Tobago",
  "Tunisia",
  "Turkey",
  "Turkmenistan",
  "Turks and Caicos Islands",
  "Tuvalu",
  "Uganda",
  "Ukraine",
  "United Arab Emirates",
  "United Kingdom of Great Britain and Northern Ireland",
  "United States Minor Outlying Islands",
  "United States of America",
  "Uruguay",
  "Uzbekistan",
  "Vanuatu",
  "Venezuela (Bolivarian Republic of)",
  "Viet Nam",
  "Virgin Islands (British)",
  "Virgin Islands (U.S.)",
  "Wallis and Futuna",
  "Western Sahara",
  "Yemen",
  "Zambia",
  "Zimbabwe",
  "Åland Islands",
];

export const USA_STATES = [
  "Alaska",
  "Alabama",
  "Arkansas",
  "American Samoa",
  "Arizona",
  "California",
  "Colorado",
  "Connecticut",
  "District of Columbia",
  "Delaware",
  "Florida",
  "Georgia",
  "Guam",
  "Hawaii",
  "Iowa",
  "Idaho",
  "Illinois",
  "Indiana",
  "Kansas",
  "Kentucky",
  "Louisiana",
  "Massachusetts",
  "Maryland",
  "Maine",
  "Michigan",
  "Minnesota",
  "Missouri",
  "Mississippi",
  "Montana",
  "North Carolina",
  "North Dakota",
  "Nebraska",
  "New Hampshire",
  "New Jersey",
  "New Mexico",
  "Nevada",
  "New York",
  "Ohio",
  "Oklahoma",
  "Oregon",
  "Pennsylvania",
  "Puerto Rico",
  "Rhode Island",
  "South Carolina",
  "South Dakota",
  "Tennessee",
  "Texas",
  "Utah",
  "Virginia",
  "Virgin Islands",
  "Vermont",
  "Washington",
  "Wisconsin",
  "West Virginia",
  "Wyoming",
];

export const COLORS = [
  "#35C0FC",
  "#4361EE",
  "#FFCD40",
  "#F79009",
  "#8912CA",
  "#0BCEBB",
  "#C863FF",
  "#DE970B",
  "#E56B6F",
  "#1DD3B0",
  "#AA5042",
  "#9A0680",
  "#FF5C85",
];

export const MINIMUM_ESCORE = 15;

export const SIDEBAR_WIDTH = "250px";

// Guest mode
export const CONTEXT_STATUS = {
  AUTHORIZED: "authorized",
  UNAUTHENTICATED: "unauthenticated",
  AUTHENTICATED: "authenticated",
};

export const LICENSE_TYPES = {
  Basic: "Basic",
  Pro: "Pro",
  Trial: "Trial",
  Dev: "Dev",
};

export const DEFAULT_AGENDA_TITLE = "Agenda Title";

export const AUDIO_EVENTS = {
  START_ACTIVITY: "START_ACTIVITY",
};

export const APP_TYPE = {
  WebApp: "Web",
  ZoomApp: "zoomApp",
};

export const MEETING_INFO_ENCRYPTION_KEY = "3fc90a647b9b9874b390b44b8";

export const ACCEPTED_FILE_FORMATS =
  "image/jpg, image/jpeg, image/png, image/gif";

export const USE_CASE_OPTIONS = [
  {
    label: "Select multiple...",
    options: [
      { value: "all-hands-meetings", label: "All Hands Meetings" },
      { value: "team-meetings", label: "Team Meetings" },
      { value: "pr-communications", label: "PR/Communications" },
      { value: "training", label: "Training" },
      { value: "onboarding", label: "Onboarding" },
      { value: "sales", label: "Sales" },
      { value: "other", label: "Other" },
    ],
  },
];

export const MEETING_FREQUENCY_OPTIONS = [
  { value: "Daily", label: "Daily" },
  { value: "Weekly", label: "Weekly" },
  { value: "Monthly", label: "Monthly" },
  { value: "Quarterly", label: "Quarterly" },
  { value: "Yearly", label: "Yearly" },
];

export const COMPANY_SIZE_OPTIONS = [
  { value: "0-10", label: "0-10" },
  { value: "10-50", label: "10-50" },
  { value: "50-200", label: "50-200" },
  { value: "200-1000", label: "200-1000" },
  { value: "1000-5000", label: "1000-5000" },
  { value: "5000-10000", label: "5000-10,000" },
  { value: "10,000+", label: "10,000+" },
];

export const SUBSCRIPTION_TYPES = {
  Presenter: "PRO Presenter",
  Enterprise: "PRO Enterprise",
};

export const STEPS = {
  One: "one",
  Two: "two",
  Three: "three",
};

export const JWT_TOKEN_NAME = "th-token";
export const USER_MEETING_INFO_KEY = "userMeetingInfo";
export const LOGOUT_STATUS_CODES = [403, 401];

export const ENGAGEMENT_MODAL_HIDDEN_TOKEN_NAME = "engagement-modal-hidden";
export const ICON_SECTION_HEIGHT = 200;
export const ICON_SPAM_COUNT_TOKEN = "iconSpamCount";
export const ICONS_UNLOCK_TIME_TOKEN = "iconsUnlockTime";
export const MINUTES_TO_LOCK = 4;
export const OFFENSE_COUNT = 4;
export const MAX_ABUSE_CLICK = 10;
export const ABUSE_MESSAGES = [
  {
    heading: "Whoa!",
    body: "Love the excitement, but too many reactions can be distracting!",
  },
  {
    heading: "Love the passion, but...",
    body: "Please use reactions a little less. Too many can distract the session.",
  },
  {
    heading: "Reaction overload!",
    body: "Too many emojis will lock you out of Townhall!",
  },
  {
    heading: "Account Locked!",
    body: "Unfortunately, your reaction passion is too distracting. Please use other methods to engage.",
  },
];

// URLs
export const HELP_PAGE = "https://help.townhall.pro/en/";
export const VIDEO_URL = "https://www.youtube.com/watch?v=D-PinFDJbn8";

export const SAMPLE_EREPORT_PDF =
  "https://townhall-ereport-prod.s3.us-east-2.amazonaws.com/samples/Townhall.pro+Sample+E-Report.pdf";
export const SAMPLE_EREPORT_CSV =
  "https://townhall-ereport-prod.s3.us-east-2.amazonaws.com/samples/Sample+E-Report.csv";
export const PRICING_PAGE = "https://www.townhall.pro/pricing-subscription";

export const AUTO_REDIRECT_URLS = [
  SAMPLE_EREPORT_PDF,
  SAMPLE_EREPORT_CSV,
  PRICING_PAGE,
];

export const WEB_INVITE_INFO =
  "Click to generate link for web and mobile attendees. Zoom login not required.";
export const ZOOM_INVITE_INFO =
  "Click to send Townhall invite to chat. Zoom login required.";

export const TOP_HEIGHT = "60px";
export const CONTENT_HEIGHT = "120px";
export const POLL_ICONS_ORDER = [
  "letterA",
  "letterB",
  "letterC",
  "letterD",
  "one",
  "two",
  "three",
  "four",
  "five",
  "checked",
  "close",
  "question",
];
