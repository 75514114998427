import { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import { useProfile } from "@hooks";

const useMeeting = () => {
  const [hostId, setHostId] = useState(null);
  const [meetingParticipants, setMeetingParticipants] = useState([]);
  const [guests, setGuests] = useState([]);
  const { profile } = useProfile();
  const [meetingObj, setMeetingObj] = useState({});
  const meeting = useSelector((state) => state.meeting.data);
  const isMeetingActive = meeting.inAMeeting;
  const isFetchingMeeting = useSelector((state) => state.meeting.isFetchingMeeting);
  const platformMeetingInstanceId = useSelector((state) => state.meeting.data.meetingObject?.meetingInstances?.[0]?.platformMeetingInstanceId);
  const meetingInstanceId = useSelector((state) => state.meeting.data.meetingObject?.meetingInstances?.[0]?._id);
  const platformMeetingId = useSelector((state) => state.meeting.data.meetingObject?.platformMeetingId);
  const meetingHosts = useSelector((state) => state.meeting.meetingHost);

  const isHost = useCallback((id) => {
    return meetingHosts.host && meetingHosts.host?.userId === id;
  }, [meetingHosts.host]);

  const isCoHost = useCallback((id) => {
    return !!(meetingHosts.coHosts.length && meetingHosts.coHosts.find((coHost) => coHost.userId === id));
  }, [meetingHosts.coHosts]);

  const isHostOrCoHost = useCallback((id) => isHost(id) || isCoHost(id), [isHost, isCoHost]);

  useEffect(() => {
    if (meeting.meetingObject?.meetingInstances?.length) {
      const participants = meeting.meetingObject?.meetingInstances[0].participantsInMeetingOnTH;
      setMeetingObj(meeting.meetingObject)
      setMeetingParticipants(participants)
      setHostId(meeting?.meetingObject?.hostId);

      const guests = meeting.meetingObject?.meetingInstances[0].participantsInMeetingOnZoom;
      setGuests(guests);
    } else {
      setMeetingParticipants([profile]);
      setGuests([]);
    }
  }, [meeting, profile]);

  return {
    hostId,
    isCoHost,
    isHost,
    isHostOrCoHost,
    isFetchingMeeting,
    meetingParticipants,
    guests,
    isMeetingActive,
    meetingObj,
    meetingInstanceId,
    platformMeetingId,
    platformMeetingInstanceId,
    meetingInstance: meetingObj?.meetingInstances?.[0] || {}
  };
};

export default useMeeting;
