import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { customBaseQuery } from "@store/api/baseQueries";

export const activitiesApi = createApi({
  reducerPath: "activitiesApi",
  baseQuery: customBaseQuery(
    fetchBaseQuery({
      baseUrl: `${process.env.REACT_APP_ZOOMAPP_BACKEND_BASE_URL}/activities`,
    }),
  ),
  endpoints: (builder) => {
    return {
      createActivity: builder.mutation({
        query: (body) => ({
          url: `/`,
          method: "POST",
          body,
        }),
      }),
      updateActivity: builder.mutation({
        query: ({ activityId, body }) => ({
          url: `/${activityId}`,
          method: "PUT",
          body,
        }),
      }),
      deleteActivity: builder.mutation({
        query: (activityId) => ({
          url: `/${activityId}`,
          method: "DELETE",
        }),
      }),
      getActivities: builder.query({
        query: (query) => ({
          url: `/`,
          method: "GET",
          params: query,
        }),
        transformResponse: (response) => [...response].reverse(),
      }),
      goLive: builder.mutation({
        query: (id) => ({
          url: `/${id}/live`,
          method: "PUT",
        }),
      }),
      stopLive: builder.mutation({
        query: (id) => ({
          url: `/${id}/stop`,
          method: "PUT",
        }),
      }),
      respondToActivity: builder.mutation({
        query: ({ id, body}) => ({
          url: `/${id}/respond`,
          method: "PUT",
          body,
        }),
      }),
    };
  },
});

export const {
  useRespondToActivityMutation,
  useGetActivitiesQuery,
  useGoLiveMutation,
  useStopLiveMutation,
  useCreateActivityMutation,
  useUpdateActivityMutation,
  useDeleteActivityMutation,
} = activitiesApi;
