import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updatePoints, updateCoins } from "@store/engagementSlice";

const useEngagement = () => {
  const dispatch = useDispatch();
  const profile = useSelector((state) => state.profile.data)
  const points = useSelector((state) => state.engagement.points);
  const coins = useSelector((state) => state.engagement.coins);

  useEffect(() => {
    if (profile) {
      dispatch(updatePoints({ points: points || profile?.points }));
      dispatch(updateCoins({ coins: coins || profile?.coins }));
    }
  }, [profile, dispatch, profile?.points, points, profile?.coins, coins]);

  return {
    points,
    coins,
  };
};

export default useEngagement;
