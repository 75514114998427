import React, { useEffect, useCallback, useReducer, useMemo } from "react";
import {
  Avatar,
  chakra,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  CloseButton,
  Flex,
  Box,
  Image,
  Icon,
  Text,
} from "@chakra-ui/react";
import { useSelector, useDispatch } from "react-redux";
import Countdown from "react-countdown";
import dayjs from "dayjs";
import { useMeeting } from "@hooks";
import { Card, CardFooter, Duration } from "@components";
import {
  QuizView,
  QuizResult,
  QuizFooter,
  PollFooter,
  PollView,
  PollResult,
} from "@components/ActivityTypes";
import { useRespondToActivityMutation } from "@store/api/activities";
import { activityTypes } from "@constants/activities";
import {
  prepareStateForLiveActivity,
  addUserResponse,
} from "@store/activitySlice";
import { IoMdCloseCircle } from "react-icons/io";
import stopwatchIcon from "@assets/svg/stopwatch-icon.svg";
import hostIcon from "@assets/svg/host-status.svg";
import answerCorrect from "@assets/images/answer-correct.png";
import confettiBgImage from "@assets/images/confetti.gif";
import avatarDefaultIcon from "@assets/avatar-default.svg";

const participantModalPartsObject = {
  [activityTypes.quiz]: {
    view: QuizView,
    footer: QuizFooter,
    result: QuizResult,
  },
  [activityTypes.poll]: {
    view: PollView,
    footer: PollFooter,
    result: PollResult,
  },
};

const getModalParts = (type) => participantModalPartsObject[type] || {};

const ActivityParticipantModal = () => {
  const initialModalState = useMemo(
    () => ({
      isOpen: false,
      userResponse: [],
      isValidInput: false,
      otherProps: {},
    }),
    []
  );

  const [modalState, setModalState] = useReducer(
    (prevState, newState) => ({ ...prevState, ...newState }),
    initialModalState
  );
  const dispatch = useDispatch();
  const { isHostOrCoHost } = useMeeting();
  const [respondToActivity] = useRespondToActivityMutation();
  const meetingData = useSelector((state) => state.meeting.data);
  const profile = useSelector((state) => state.profile.data);
  const activitySelector = useSelector((state) => state.activity);
  const liveActivity = activitySelector.liveActivity;
  const showResult = useSelector((state) => state.activity.showResult);
  const cachedTitle = useSelector((state) => state.activity.cachedTitle);
  const cachedType = useSelector((state) => state.activity.cachedType);
  const host = meetingData?.meetingHost;
  const participantModalParts = useMemo(
    () => getModalParts(liveActivity.type || cachedType),
    [liveActivity.type, cachedType]
  );
  const ViewComponent = participantModalParts.view;
  const FooterComponent = participantModalParts.footer;
  const ResultComponent = participantModalParts.result;
  const isParticipant = profile?._id !== host?._id;

  const showQuizResult = showResult && cachedType === activityTypes.quiz;
  const showPollResult = showResult && cachedType === activityTypes.poll;

  const hasResponded = useCallback(
    (responseArray = []) =>
      !!responseArray.find(
        (response) => response?.user?.userId === profile?._id
      ),
    [profile?._id]
  );

  useEffect(() => {
    if (!liveActivity || !isParticipant) {
      return;
    }

    if (
      (liveActivity.live && !hasResponded(liveActivity.response)) ||
      activitySelector.showResult
    ) {
      setModalState({ isOpen: true });
    } else {
      setModalState({ isOpen: false });
    }
  }, [
    liveActivity,
    profile,
    meetingData,
    showResult,
    isParticipant,
    hasResponded,
  ]);

  useEffect(() => {
    if (isParticipant && showResult) {
      setModalState({ isOpen: true });
    }
  }, [showResult, isParticipant]);

  const handleSubmit = useCallback(() => {
    try {
      dispatch(addUserResponse({ userResponse: modalState.userResponse }));
      respondToActivity({
        id: liveActivity._id,
        body: { response: modalState.userResponse },
      });
      setModalState({ isOpen: false });
    } catch (error) {}
  }, [modalState.userResponse, liveActivity._id, dispatch, respondToActivity]);

  const handleClose = () => {
    dispatch(prepareStateForLiveActivity());
    setModalState({ isOpen: false });
  };

  const isAnswerCorrect = modalState?.otherProps?.isCorrect;

  const backgroundColor = showQuizResult
    ? isAnswerCorrect
      ? "green.50"
      : "red.50"
    : "white";

  const headerText = showQuizResult ? (
    isAnswerCorrect ? (
      <>
        {"Congratulations"}
        <br />
        {profile?.firstName}!
      </>
    ) : (
      "Sorry, good effort!"
    )
  ) : (
    liveActivity.title || cachedTitle
  );

  const headerIcon =
    showQuizResult && isAnswerCorrect ? (
      <Image src={answerCorrect} boxSize="12" />
    ) : showQuizResult && !isAnswerCorrect ? (
      <Icon as={IoMdCloseCircle} color="red.300" fontSize="7xl" />
    ) : null;

  const showBgImage =
    (showQuizResult && isAnswerCorrect) || showPollResult
      ? `url(${confettiBgImage}) no-repeat top center / contain`
      : null;

  return (
    <Modal
      closeOnOverlayClick={false}
      isOpen={modalState.isOpen}
      onClose={handleClose}
      isCentered
      size="xs"
    >
      <ModalOverlay />
      <ModalContent bg="transparent">
        <ModalBody position="relative">
          <Card
            p={4}
            border="none"
            borderRadius="10px 10px 0 0"
            background={showBgImage}
            backgroundColor="white"
            footer={
              !showResult ? (
                <CardFooter
                  p={4}
                  background={backgroundColor || "white"}
                  border="none"
                >
                  {FooterComponent && (
                    <FooterComponent
                      otherProps={modalState?.otherProps}
                      isValidInput={modalState.isValidInput}
                      handleSubmit={handleSubmit}
                    />
                  )}
                </CardFooter>
              ) : (
                <CardFooter p={1} background="white" border="none" />
              )
            }
          >
            <Flex justifyContent="space-between">
              <Flex alignItems="center">
                {!showResult ? (
                  <Box position="relative">
                    <Image
                      src={hostIcon}
                      position="absolute"
                      top="0"
                      right="0"
                      zIndex="1"
                    />
                    <Avatar
                      size="sm"
                      shadow="lg"
                      mr={2}
                      src={host?.avatar}
                      icon={<Image src={avatarDefaultIcon} boxSize="4" />}
                      bg={host?.color}
                    />
                  </Box>
                ) : null}
              </Flex>
              {liveActivity?.live ? (
                <Flex alignItems="center">
                  <Countdown
                    date={dayjs(liveActivity?.startTime)
                      .add(liveActivity?.settings?.duration, "minute")
                      .toDate()}
                    renderer={Duration}
                    onComplete={() => setModalState({ isOpen: false })}
                  />
                  <chakra.span>
                    <Image
                      src={stopwatchIcon}
                      alt="stopwatch"
                      boxSize="4"
                      ml="1"
                    />
                  </chakra.span>
                </Flex>
              ) : (
                <CloseButton
                  border="1px solid"
                  borderColor="gray.100"
                  shadow="sm"
                  onClick={handleClose}
                />
              )}
            </Flex>
            <Flex direction="column" alignItems="center" py={2} mb={4}>
              {headerIcon}
              <Text
                align="center"
                fontWeight="600"
                fontSize="lg"
                color="dark.600"
                lineHeight="1.2"
              >
                {headerText}
              </Text>
            </Flex>
            <Box>
              {showResult ? (
                ResultComponent ? (
                  <ResultComponent
                    title={liveActivity.title || cachedTitle}
                    setModalState={setModalState}
                  />
                ) : null
              ) : (
                ViewComponent && (
                  <ViewComponent
                    setModalState={setModalState}
                    options={liveActivity.options}
                  />
                )
              )}
            </Box>
          </Card>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ActivityParticipantModal;
