import { useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  useCreateNewAgendaMutation,
  useDeleteAgendaMutation,
  useDuplicateAgendaMutation,
  useInstantStartMutation,
  useGoLiveAgendaMutation,
  useStopLiveAgendaMutation,
  useUpdateSingleAgendaMutation
} from "@store/api/agenda";
import { useHandleApiCall, useSdkUtils } from "@hooks";
import { DEFAULT_AGENDA_TITLE } from "@constants";
import {
  DEFAULT_ERROR_GOING_LIVE_MESSAGE,
  DEFAULT_ERROR_STOPPING_AGENDA_MESSAGE,
  DEFAULT_INVITE_PARTICIPANTS_ERROR_MESSAGE,
  DEFAULT_UPDATE_AGENDA_ERROR_MESSAGE
} from "@constants/errorMessages";
import { showErrorToast, showSuccessToast } from "@store/customActions";
import { $Routes } from "@routes";

const useAgendaActions = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const { handleApiCall } = useHandleApiCall();
  const { inviteParticipantsToApp } = useSdkUtils();
  const agendas = useSelector((state) => state.agendas.agendas);
  const [createAgenda, { isLoading: isCreateAgendaLoading }] = useCreateNewAgendaMutation();
  const [deleteAgenda, { isLoading: isDeletingAgenda }] = useDeleteAgendaMutation();
  const [duplicateAgenda, { isLoading: isDuplicatingAgenda }] = useDuplicateAgendaMutation();
  const [instantStart, { isLoading: isInstantStartLoading }] = useInstantStartMutation();
  const [goLiveAgenda, { isLoading: isGoingLive }] = useGoLiveAgendaMutation();
  const [stopLiveAgenda, { isLoading: isStoppingLive }] = useStopLiveAgendaMutation();
  const [updateAgenda, { isLoading: isUpdatingAgenda }] = useUpdateSingleAgendaMutation();

  const createAgendaTitle = useMemo(
    () => `${DEFAULT_AGENDA_TITLE} (${agendas.length + 1})`,
    [agendas.length]
  );

  const inviteParticipants = useCallback(() => {
    inviteParticipantsToApp({
      onError: () => dispatch(showErrorToast(DEFAULT_INVITE_PARTICIPANTS_ERROR_MESSAGE))            
    });
  }, [dispatch, inviteParticipantsToApp]);

  const handleCreateAgenda = useCallback(async () => {
    handleApiCall({
      data: {
        title: createAgendaTitle,
        description: ""
      },
      endpoint: createAgenda,
      onSuccess: (response) => {
        navigate(`${$Routes.agendaDetailPage}/${response.agenda._id}`);
      },
      onError: (error) => {
        dispatch(showErrorToast(error.data?.message));
      }
    });
  }, [createAgenda, createAgendaTitle, dispatch, handleApiCall, navigate]);

  const handleDeleteAgenda = useCallback(async (agendaId, afterAllCallback) => {
    handleApiCall({
      data: agendaId,
      endpoint: deleteAgenda,
      onSuccess: () => {
        dispatch(showSuccessToast("Deleted agenda successfully"));
      },
      onError: (error) => {
        dispatch(showErrorToast(error.data?.message));
      },
      afterAll: () => {
        afterAllCallback();
      }
    });
  }, [deleteAgenda, dispatch, handleApiCall]);

  const handleDuplicateAgenda = useCallback(async (agendaId, afterAllCallback) => {
    handleApiCall({
      data: agendaId,
      endpoint: duplicateAgenda,
      onSuccess: (response) => {
        dispatch(showSuccessToast("Duplicated agenda successfully"));
        navigate(`${$Routes.agendaDetailPage}/${response._id}`);
      },
      onError: (error) => {
        dispatch(showErrorToast(error.data?.message));
      },
      afterAll: () => {
        afterAllCallback();
      }
    });
  }, [dispatch, duplicateAgenda, handleApiCall, navigate]);

  const handleInstantStart = useCallback(async ({ meetingInstanceId, onError }) => {
    handleApiCall({
      data: {
        title: createAgendaTitle,
        meetingInstanceUuid: meetingInstanceId,
      },
      endpoint: instantStart,
      onSuccess: (response) => {
        inviteParticipants();
        dispatch(showSuccessToast("Agenda instantly started successfully"));
        navigate(`${$Routes.agendaDetailPage}/${response._id}`);
      },
      onError
    });
  }, [inviteParticipants, createAgendaTitle, dispatch, handleApiCall, instantStart, navigate]);

  const handleGoLive = useCallback(async ({ agendaId, meetingInstanceId, onError }) => {
    handleApiCall({
      data: { agendaId, meetingInstanceId },
      endpoint: goLiveAgenda,
      onSuccess: () => {
        inviteParticipants();
      },
      onError
    });
  }, [dispatch, goLiveAgenda, handleApiCall, inviteParticipants]);

  const handleStopLive = useCallback(async ({ agendaId, onSuccess }) => {
    handleApiCall({
      data: agendaId,
      endpoint: stopLiveAgenda,
      onSuccess: (response) => {
        onSuccess(response);
      },
      onError: (error) => {
        dispatch(showErrorToast(error.message || DEFAULT_ERROR_STOPPING_AGENDA_MESSAGE));
      }
    });
  }, [dispatch, handleApiCall, stopLiveAgenda]);

  const handleUpdateAgenda = useCallback(async (updateAgendaPayload, afterAllCallback) => {
    const { agendaId, ...rest } = updateAgendaPayload;

    const data = {
      agendaId,
      meetingData: rest
    };

    handleApiCall({
      data,
      endpoint: updateAgenda,
      onSuccess: () => {
        dispatch(showSuccessToast("Agenda updated successfully"));
      },
      onError: (error) => {
        dispatch(showErrorToast(error.message || DEFAULT_UPDATE_AGENDA_ERROR_MESSAGE));
      },
      afterAll: () => {
        afterAllCallback();
      }
    });
  }, [dispatch, handleApiCall, updateAgenda]);

  return {
    createAgendaTitle,
    createAgenda: {
      handleCreateAgenda,
      isLoading: isCreateAgendaLoading,
    },
    deleteAgenda: {
      handleDeleteAgenda,
      isLoading: isDeletingAgenda,
    },
    duplicateAgenda: {
      handleDuplicateAgenda,
      isLoading: isDuplicatingAgenda
    },
    instantStart: {
      handleInstantStart,
      isLoading: isInstantStartLoading
    },
    goLiveAgenda: {
      handleGoLive,
      isLoading: isGoingLive
    },
    stopLiveAgenda: {
      handleStopLive,
      isLoading: isStoppingLive
    },
    updateAgenda: {
      handleUpdateAgenda,
      isLoading: isUpdatingAgenda
    }
  };
}

export default useAgendaActions;
