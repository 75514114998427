import { useEffect, useMemo, useState, Suspense } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import "focus-visible/dist/focus-visible";
import { AppRoutes, $Routes } from "@routes";
import {
  FullPageLoader,
  ZoomAppAuthState,
  TabChangeDisconnectModal,
  MainContainer,
} from "@components";
import {
  useHandleIconClick,
  useSocketConnections,
  useNotifications,
  useMeeting,
  useSdk,
  useProfile,
} from "@hooks";
import {
  initiateSocket,
  iconChat,
  notification,
  disconnectSocket,
} from "@socket";
import { socketInitiated } from "@store/customActions";
import { updateCoins } from "@store/engagementSlice";
import { useGetAllIconsQuery } from "@store/api/icon";
import { LoadGoogleAnalytics, isZoomApp } from "@utils/helpers";
import {
  ActivityParticipantModal,
  ActivityPrepModal,
} from "@components/Activity";
import { APP_TYPE } from "@constants";

function App() {
  const dispatch = useDispatch();
  const location = useLocation();
  const { handleAddNotification } = useNotifications();
  const {
    hasToken,
    isAuthenticated,
    profileId,
    hasFetchProfileError,
    isFetchingProfile,
  } = useProfile();
  const { userContextStatus, isAuthorized, appType } = useSdk();
  const analytics = useSelector((state) => state.meeting.analytics);
  const { setTriggerLiveAnaltyics } = useSocketConnections();
  const { broadcastIconClick } = useHandleIconClick();
  useGetAllIconsQuery(null, {
    skip: appType === APP_TYPE.ZoomApp ? !profileId : !profileId || !hasToken,
  });
  const { isMeetingActive, isHostOrCoHost, isFetchingMeeting } = useMeeting();
  const liveActivity = useSelector((state) => state.activity.liveActivity);
  const showResult = useSelector((state) => state.activity.showResult);
  // TODO: move to useMeeting hook
  const meetingHostId = useSelector(
    (state) => state.meeting.data.meetingObject?.hostId
  );
  const meetingUuid = useSelector(
    (state) => state.meeting.data.meetingObject?._id
  );
  const meetingInstanceId = useSelector(
    (state) => state.meeting.data.meetingObject?.meetingInstances?.[0]?._id
  );
  const [showPrepModal, setShowPrepModal] = useState(true);

  const isProfileLoading = isZoomApp
    ? isFetchingMeeting || !profileId || isFetchingProfile
    : isFetchingProfile;

  useEffect(() => {
    if (isMeetingActive && liveActivity.live) {
      const timer = setTimeout(() => {
        setShowPrepModal(false);
      }, 5000);

      return () => {
        clearTimeout(timer);
        setShowPrepModal(true);
      };
    }
  }, [isMeetingActive, liveActivity.live]);

  // TODO: clean up and remove useSocketConnection hook
  useEffect(() => {
    if (analytics.live) {
      setTriggerLiveAnaltyics(true);
    } else {
      setTriggerLiveAnaltyics(false);
    }
  }, [analytics.live, setTriggerLiveAnaltyics]);

  useEffect(() => {
    if (!isAuthenticated || !meetingInstanceId) {
      console.info("No meetingInstanceId or user is not authenticated");
      return;
    }

    if (!location.pathname.includes($Routes.sendToolRedirectPage)) {
      initiateSocket(profileId, meetingInstanceId);
      // TODO: Remove meetingUuid if not used
      dispatch(
        socketInitiated({
          meetingUuid,
          profileId,
          isHostOrCohost: isHostOrCoHost(profileId),
        })
      );

      // TODO: clean up iconChat and notification subscription
      iconChat.subscribe((data) => {
        broadcastIconClick({ iconId: data.iconId, color: data.color });
      });

      notification.subscribe((data) => {
        const { type, meta } = data;

        if (type === "coins") {
          dispatch(updateCoins({ coins: meta?.value }));
        }

        handleAddNotification(data);
      });
    }

    return () => {
      iconChat.unsubscribe();
      notification.unsubscribe();
      disconnectSocket();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    profileId,
    meetingHostId,
    meetingUuid,
    meetingInstanceId,
    isAuthenticated,
    broadcastIconClick,
    dispatch,
    handleAddNotification,
  ]);

  const renderAppComponents = useMemo(() => {
    if (isProfileLoading) {
      return <FullPageLoader />;
    }

    if (
      appType === APP_TYPE.ZoomApp &&
      (!isAuthorized || hasFetchProfileError)
    ) {
      return <ZoomAppAuthState userContextStatus={userContextStatus} />;
    }

    return (
      <>
        {!isZoomApp && <TabChangeDisconnectModal />}
        {isMeetingActive && (liveActivity.live || showResult) && (
          <>
            {showPrepModal &&
            liveActivity.live &&
            !isHostOrCoHost(profileId) ? (
              <ActivityPrepModal />
            ) : (
              <ActivityParticipantModal />
            )}
          </>
        )}
        <Suspense fallback={<FullPageLoader />}>
          <AppRoutes />
        </Suspense>
      </>
    );
  }, [
    appType,
    hasFetchProfileError,
    isProfileLoading,
    isAuthorized,
    isMeetingActive,
    liveActivity.live,
    showResult,
    userContextStatus,
    showPrepModal,
    isHostOrCoHost,
    profileId,
  ]);

  return (
    <HelmetProvider>
      <LoadGoogleAnalytics />
      <MainContainer>{renderAppComponents}</MainContainer>
    </HelmetProvider>
  );
}

export default App;
