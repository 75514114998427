import { Box, Image, Flex, Heading, Text } from "@chakra-ui/react";
import { HelpTooltip } from "@components";
import reportTitleBg from "./img/report-title-bg.png";
import calendarIcon from "./svg/icon-calendar.svg";

const ReportCard = ({ icon, title, tooltip, summary, date, children }) => {
  return (
    <Box borderRadius="10px" border="1px solid" borderColor="gray.100" mb="1">
      {summary && (
        <Box
          bg="gray.50"
          p="12px"
          mb="3"
          borderRadius="10px 10px 0 0"
          bgImage={`url(${reportTitleBg})`}
          bgRepeat="no-repeat"
          bgPos="bottom right"
        >
          <Heading
            variant="reportSummaryHeading"
            mb="1"
            isTruncated={true}
            maxW="160px"
          >
            {title}
          </Heading>
          <Flex alignItems="center">
            <Image mr="1" src={calendarIcon} />
            <Text variant="reportSummaryDate">{date}</Text>
          </Flex>
        </Box>
      )}
      {!summary && (
        <Flex alignItems="center" p="12px">
          {icon && <Image src={icon} mr="1" />}
          <Heading variant="reportHeading">{title}</Heading>
          <HelpTooltip placement="bottom" label={tooltip} />
        </Flex>
      )}
      <Box p="12px" pt="0">
        {children}
      </Box>
    </Box>
  );
};

export default ReportCard;
