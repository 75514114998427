const reportTextShared = {
  color: "dark.400",
  fontWeight: "400",
  fontSize: "10px",
};

const Text = {
  variants: {
    authPageText: {
      color: "gray.400",
      paddingTop: "3",
      fontSize: "xs",
      fontWeight: "normal",
    },
    reportPercentage: {
      color: "dark.500",
      fontWeight: "500",
      fontSize: "14px",
      marginBottom: "1",
    },
    reportText: {
      ...reportTextShared,
      color: "dark.200",
    },
    reportSummaryText: {
      ...reportTextShared,
    },
    reportSummaryDate: {
      ...reportTextShared,
      fontSize: "12px",
    },
    reportLeaderboardText: {
      ...reportTextShared,
    },
    navHeader: {
      color: "white",
      fontSize: 24,
      fontWeight: 600,
      alignSelf: "center",
    },
    proOnboardingText: {
      fontSize: "12px",
      color: "gray.700",
      textAlign: "center",
      padding: "10px",
    },
  },
};

export default Text;
