import { Text, Flex, Box } from "@chakra-ui/react";
import { Layout } from "@components";
import { APP_TYPE } from "@constants";
import { REFRESH_TOKEN_FAILED } from "@constants/errorMessages";

export const ZoomAppAuthFailed = () => {
  return (
    <Layout>
      <Flex justifyContent="center" alignItems="center" height="80%">
        <Flex
          flexDirection="column"
          alignItems="center"
          width="80%"
          zIndex="0"
          position="relative"
        >
          <Box>
            <Text as="h2" textAlign="center" mb={2} textColor="red.300">Authorization Error</Text>
            <Text textAlign="center" textColor="gray.100" fontSize="sm">
              {REFRESH_TOKEN_FAILED[APP_TYPE.ZoomApp]}
            </Text>
          </Box>
        </Flex>
      </Flex>
    </Layout>
  )
}
