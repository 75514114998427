import { Text, Flex, Icon, Box } from "@chakra-ui/react";
import { ProgressBar } from "@components";
import { getActivityAnswers } from "@utils/helpers";
import { IoCheckmarkCircleSharp } from "react-icons/io5";

const QuizResults = ({ title, options, response }) => {
  const filterAnswerCallback = (response, option) => response.responseIds.includes(option._id);
  const responseArray = getActivityAnswers(options, response, filterAnswerCallback, response.length);

  return (
    <>
      <Text fontWeight="600" fontSize="12px" color="dark.400" mb="1">
        {title}
      </Text>
      {responseArray.map((res) => (
        <Box mb={1} key={res.id}>
          <Flex alignItems="center" >
            <Text fontWeight="400" fontSize="12px" color="dark.400">
              {res.label}
            </Text>
            {res.correct && (
              <Icon
                as={IoCheckmarkCircleSharp}
                color="green.400"
                w={3}
                h={3}
                ml={1}
              />
            )}
          </Flex>
          <ProgressBar
            key={res.id}
            height={4}
            value={res.value}
            color={res.correct ? "green.400" : "gray.300"}
          />
        </Box>
      ))}
    </>
  );
};

export default QuizResults;
