import { io } from "socket.io-client";

let socket;

export const connectSocket = (url, options) => {
  console.log("Connecting socket...");
  socket = io(url, options);

  socket.on("connect", function() {
    console.log("Socket connected", socket.connected);
  });
};

export const disconnectSocket = () => {
  console.log("Disconnecting socket...");
  if (socket) socket.disconnect();
};

const unsubscribeFromSocketEvent = (eventName, socket) => {
  if (!socket) {
    return;
  }
  socket.removeAllListeners(eventName);
};

const subscribeToSocketEvent = (eventName, callback, allowMultiple) => {
  if (!socket || !callback) {
    return;
  }

  if (!allowMultiple && socket.listeners(eventName).length >= 1) {
    return;
  }

  socket.on(eventName, callback);
};

export const createSocketSubscription = (eventName) => ({
  subscribe: (callback, allowMultiple = false) =>
    subscribeToSocketEvent(eventName, callback, allowMultiple),
  unsubscribe: () => unsubscribeFromSocketEvent(eventName),
});

export const sendSocketEvent = (eventName, data) => {
  if (!socket) return;
  socket.emit(eventName, data);
}
