import { createStandaloneToast } from "@chakra-ui/toast";
import theme from "@utils/theme";
import { showErrorToast, showSuccessToast } from "@store/customActions";

const toast = createStandaloneToast({ theme });

const TOAST_STATUS = {
  [showSuccessToast.type]: "success",
  [showErrorToast.type]: "error",
}

const notificationMiddlewares = (_api) => (next) => (action) => {
  if (showSuccessToast.type === action.type || showErrorToast.type === action.type) {

    toast({
      description: action.payload,
      position: "top",
      status: TOAST_STATUS[action.type]
    });
  }

  return next(action);
};

export default notificationMiddlewares;
