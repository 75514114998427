import { useState, useEffect } from "react";
import { Flex } from "@chakra-ui/react";
import { useResizeDetector } from "react-resize-detector";
import { Navbar } from "@components/Navbar";
import loginBgLight from "@assets/login-bg-light.png";

const DEFAULT_CONTAINER_HEIGHT = "100dvh";
const DEFAULT_CONTAINER_WIDTH = "100dvw";

const MainContainer = ({ children, footer }) => {
  const [windowInnerHeight, setWindowInnerHeight] = useState(window.innerHeight);
  const { height: navbarHeight, ref: navbarRef } = useResizeDetector();
  const supportsDvh = CSS.supports("height: 100dvh");

  const containerHeight = supportsDvh ? DEFAULT_CONTAINER_HEIGHT : `${windowInnerHeight}px`;
  const containerWidth = supportsDvh ? DEFAULT_CONTAINER_WIDTH : "100%";
  const wrapperHeight = `calc(${containerHeight} - ${navbarHeight}px)`;

  useEffect(() => {
    const updateWindowHeight = () => {
      setWindowInnerHeight(window.innerHeight);
    };

    // Add the event listener as a fallback if the browser does not support dvh and dvw units
    if (!supportsDvh) {
      window.addEventListener("resize", updateWindowHeight);
    }

    return () => {
      if (!supportsDvh) {
        window.removeEventListener("resize", updateWindowHeight);
      }
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Flex
      backgroundImage={loginBgLight}
      backgroundSize="cover"
      height={containerHeight}
      width={containerWidth}
      direction="column"
    >
      <Navbar ref={navbarRef} />
      <Flex
        height={wrapperHeight}
        justifyContent="center"
        alignItems="center"
      >
        { children}
      </Flex>
      {footer}
    </Flex>
  );
};

export default MainContainer;
