import { VStack } from "@chakra-ui/react";
import { Input } from "@components";

const EditAgendaForm = ({ agenda, setAgendaDetails }) => {
  const { title, description } = agenda || {};

  return (
    <VStack>
      <Input
        size="sm"
        placeholder="Title"
        value={title}
        onChange={(e) => setAgendaDetails({ ...agenda, title: e.target.value })}
      />
      <Input
        size="sm"
        placeholder="Description"
        value={description}
        onChange={(e) =>
          setAgendaDetails({ ...agenda, description: e.target.value })
        }
      />
    </VStack>
  );
};

export default EditAgendaForm;
