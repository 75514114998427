import { useMemo } from "react";
import { useSelector } from "react-redux";
import { Tabs, AgendaList, EmptyState } from "@components";
import { createAgendasTabDataFromDefault } from "@utils/agenda";
import { defaultAgendaTabsData } from "@constants/agenda";

const AgendaTabs = ({
  isLoadingAgendas,
  setAgendaId,
  setIsDeleteModalOpen,
  setDuplicateModalOpen
}) => {
  const agendas = useSelector((state) => state.agendas.agendas);
  const pastAgendas = useSelector((state) => state.agendas.pastAgendas);

  const agendaTabsData = useMemo(() => {
    const agendasData = createAgendasTabDataFromDefault(defaultAgendaTabsData[0], {
      isLoadingAgendas,
      agendas,
      setAgendaDeleteModalOpen: setIsDeleteModalOpen,
    });

    const pastAgendasData = createAgendasTabDataFromDefault(defaultAgendaTabsData[1], {
      agendas: pastAgendas,
    });

    return [agendasData, pastAgendasData];
  }, [agendas, pastAgendas, isLoadingAgendas, setIsDeleteModalOpen]);

  const tabs = useMemo(() => {
    return agendaTabsData.map((tabData) => ({
      id: tabData.id,
      label: tabData.label,
      content: (
        <AgendaList
          {...tabData.content }
          setAgendaId={setAgendaId}
          setAgendaDuplicateModalOpen={setDuplicateModalOpen}
          emptyStateComponent={
            <EmptyState
              {...tabData.emptyStateComponentProps}
              container="true"
              imgProps={{ boxSize: "50px" }}
              textProps={{
                mt: "12px",
                textAlign: "center",
              }}
            />
          }
        />
      )
    }))}, [agendaTabsData, setAgendaId, setDuplicateModalOpen]);

  return <Tabs tabs={tabs} variant="agenda" isFitted={true} />;
}

export default AgendaTabs;
