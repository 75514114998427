import React, { useMemo } from "react";
import { Image, Button, Box, Text } from "@chakra-ui/react";
import { FieldArray, FormikProvider } from "formik";
import addAnswerIcon from "@assets/add-circle-icon.svg";
import { QuizAnswerInput } from "@components/ActivityTypes";
import { DEFAULT_MAX_OPTIONS } from "@constants/activities";

const MAX_QUIZ_OPTIONS = 5;

const QuizOptions = React.memo(
  ({ formik, sharedInputProps }) => {
    const { handleChange, values, errors, touched, handleBlur } = formik;

    const quizOptions = useMemo(() => {
      return values.options;
    }, [values.options]);

    const handleCheckboxChange = (index, arrayHelpers) => {
      const optionsCopy = [...quizOptions];
      const checkedArray = optionsCopy.map((opt, idx) => {
        if (index === idx) {
          return { ...opt, correct: !opt.correct };
        }
        return { ...opt, correct: false };
      });

      checkedArray.map((opt, idx) => arrayHelpers.replace(idx, opt));
    };

    const hasCorrectOptionError = typeof errors.options === "string";

    return (
      <FormikProvider value={formik}>
        {hasCorrectOptionError && (
          <Box mb={1.5}>
            <Text color="error" fontSize="x-small">
              {errors.options}
            </Text>
          </Box>
        )}
        <FieldArray
          name="options"
          render={(arrayHelpers) => (
            <>
              <Box
                borderRadius="8px"
                {...(hasCorrectOptionError && !Array.isArray(errors.options)
                  ? { boxShadow: "0 0 0 2px red" }
                  : {})}
              >
                {quizOptions.length > 0 &&
                  quizOptions.map((option, index) => (
                    <QuizAnswerInput
                      key={index}
                      index={index}
                      name={`options.${index}.option`}
                      checkboxName={`options.${index}.correct`}
                      option={option}
                      handleChange={handleChange}
                      handleCheckbox={() =>
                        handleCheckboxChange(index, arrayHelpers)
                      }
                      handleOnBlur={handleBlur}
                      handleRemoveInput={() => arrayHelpers.remove(index)}
                      isDisabled={
                        !quizOptions.length > DEFAULT_MAX_OPTIONS ||
                        quizOptions.length === DEFAULT_MAX_OPTIONS
                      }
                      isInvalid={
                        Array.isArray(errors.options) &&
                        errors.options &&
                        errors.options[index] &&
                        touched.options &&
                        touched.options[index]
                      }
                      {...sharedInputProps}
                    />
                  ))}
              </Box>
              <Button
                w="100%"
                borderRadius="6px"
                variant="secondary"
                fontSize="12px"
                fontWeight="400"
                rightIcon={<Image src={addAnswerIcon} />}
                onClick={() => {
                  arrayHelpers.push({
                    option: "",
                    correct: false,
                  });
                }}
                disabled={quizOptions.length >= MAX_QUIZ_OPTIONS}
              >
                Add Answer
              </Button>
            </>
          )}
        />
      </FormikProvider>
    );
  },
  (prevProps, nextprops) =>
    !(
      prevProps.formik.values.options !== nextprops.formik.values.options ||
      prevProps.formik.errors.options !== nextprops.formik.errors.options ||
      prevProps.formik.touched.options !== nextprops.formik.touched.options
    )
);

export default QuizOptions;
