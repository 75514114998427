import {
  InputGroup,
  Input,
  InputRightElement,
  Checkbox,
  Tooltip,
  Flex,
  CloseButton,
} from "@chakra-ui/react";
import { Field } from "formik";

const QuizAnswerInput = ({
  index,
  name,
  checkboxName,
  option,
  handleChange,
  handleCheckbox,
  handleOnBlur,
  handleRemoveInput,
  isDisabled,
  isInvalid,
  ...rest
}) => {
  return (
    <Flex
      alignItems="center"
      mb={2}
      border="1px solid"
      borderRadius="8px"
      borderColor="gray.200"
      {...(isInvalid ? { boxShadow: "0 0 0 2px red" } : {})}
    >
      <InputGroup
        size="md"
        border="0px solid transparent"
        borderStyle="none"
        borderRight="1px solid"
        borderRightColor="gray.200"
      >
        <Field
          as={Input}
          id={name}
          name={name}
          placeholder="Input answer"
          onChange={handleChange}
          onBlur={handleOnBlur}
          paddingRight={8}
          style={{
            border: "0px solid transparent",
          }}
          {...rest}
        />
        <InputRightElement top="5px">
          <Tooltip
            placement="bottom"
            hasArrow
            shouldWrapChildren
            label={
              option.correct
                ? "Selected correct option"
                : "Select as correct option"
            }
          >
            <Field
              as={Checkbox}
              name={checkboxName}
              variant="rounded"
              colorScheme="green"
              onChange={handleCheckbox}
              isChecked={option.correct}
            />
          </Tooltip>
        </InputRightElement>
      </InputGroup>
      <CloseButton
        m={1}
        size="sm"
        color="gray.400"
        onClick={handleRemoveInput}
        disabled={isDisabled}
      />
    </Flex>
  );
};

export default QuizAnswerInput;
