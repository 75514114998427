import { object, string, number, array, bool } from "yup";
import { activityTypes } from "@constants/activities";

const partValidations = {
  displayName: string().required(),
  title: string().min(3).required(),
  options: array().of(string().required()),
};

const partSettingsValidation = {
  duration: number().min(1).max(10).required(),
  showResult: bool(),
};

export const pollValidations = {
  ...partValidations,
  settings: object({ ...partSettingsValidation, pollType: string() }),
  options: array()
    .of(
      object({
        option: string().required(),
      })
    )
};

export const quizValidations = {
  ...partValidations,
  options: array()
    .of(
      object({
        option: string().required(),
        correct: bool().oneOf([true, false]),
      })
    )
    .test({
      name: "checkbox-test",
      message: "You must choose one correct option",
      test: (options) => options.some((option) => !!option.correct),
    }),
  settings: object({ ...partSettingsValidation }),
};

export const sendValidations = {
  ...partValidations,
  options: array()
    .of(
      object({
        option: string().url().required(),
      })
    ),
  settings: object({
    blast: bool(),
  }),
};

const optionsValidations = {
  [activityTypes.quiz]: quizValidations,
  [activityTypes.poll]: pollValidations,
  [activityTypes.send]: sendValidations,
};

export const activitySchema = (type) => ({
  validation: object({
    displayName: string().required(),
    title: string().required(),
    ...optionsValidations[type],
  }),
});
