import { useState, useEffect } from "react";
import {
    Box,
    Flex,
    Text,
    Image,
    Heading,
    Divider,
    List,
    ListItem,
    ListIcon,
    Button
  } from "@chakra-ui/react";
  import Modal from "@components/common/Modal";
  import { BiCheck } from "react-icons/bi";
  import SubscriptionHeaderIcon from "@assets/subscription.svg";
  import { useSdkUtils } from "@hooks";
  import { SUBSCRIPTION_TYPES } from "@constants";
import { LICENSE_TYPES } from "@constants/index";

  const subTierExceededModalContents = {
    Presenter: {
      message: "PRO Presenter",
      items: [
        "Full E-Report (including .csv and .pdf)",
        "3 events per month",
        "Up to 100 meeting attendees",
        "LIVE E-Pulse running engagement chart",
        "All features included in PRO Starter plan"
      ]
    },
    Enterprise: {
      message: "PRO Enterprise",
      items: [
        "Unlimited events per month",
        "Up to 1000 attendees",
        "Priority Support",
        "E-Pulse, E-Report and all features included in PRO Presenter plan"
      ]
    },
    actionUrl:  "https://www.townhall.pro/pricing-subscription"
  };

  const SubscriptionModal = ({ isOpen, onClose, licenseType }) => {
    const { openUrl } = useSdkUtils();
    const [data, setData] = useState();

    useEffect(() => {
      if (licenseType === LICENSE_TYPES.Pro) {
        setData(subTierExceededModalContents.Enterprise);
      } else {
        setData(subTierExceededModalContents.Presenter);
      }
    }, [data]);

    const handleClick = async () => {
      try {
        await openUrl(subTierExceededModalContents.actionUrl);
      } catch (error) {
        console.log(error);
      }
    };

    return data
      ? (<Modal isOpen={isOpen} onClose={onClose} size="sm" showCloseButton={true}>
          <Flex direction="column" alignItems="center" my="6">
            <Image
              src={SubscriptionHeaderIcon}
              width="68px"
              aria-hidden="true"
              alt=""
            />
            <Heading as="h4" fontWeight="bold" fontSize="16px" mt="2">
              Upgrade Plan
            </Heading>
            <Divider mt="3"/>
            <Box mt="3" px="2">
              <Text as="div" fontSize="xs">
                Sorry, you have reached the plan limit!
              </Text>
              <Text as="div" mt="3" fontSize="xs">
                Please upgrade to&nbsp;
                <Text as="span" fontWeight="semibold">{data.message}</Text>
                &nbsp;to receive:
              </Text>
              <List spacing={2} fontSize="11px" mt="2">
                { data.items.map((item, i) => (
                  <ListItem key={i}>
                    <Flex>
                      <ListIcon as={BiCheck} color={"green.500"} />
                      <Text as="span">{item}</Text>
                    </Flex>
                  </ListItem>
                ))}
              </List>
            </Box>
            <Divider mt="3"/>
            <Box mt="3" px="2" w="full">
              <Button size="large"
                variant="primary"
                isFullWidth
                fontSize="sm"
                h="8"
                onClick={handleClick}>
                Upgrade Plan
              </Button>
            </Box>
          </Flex>
        </Modal>
      ) : null;
  };

  export default SubscriptionModal;
