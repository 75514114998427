import {
  Flex,
  Text,
  Box,
  Alert,
  AlertDescription,
  Icon,
  Image,
  chakra,
} from "@chakra-ui/react";
import { CONTEXT_STATUS } from "@constants";
import { RiErrorWarningLine } from "react-icons/ri";
import ctaArrowImage from "@assets/svg/guest-cta-arrow.svg";

const Card = ({ alert, children }) => (
  <Box layerStyle={alert ? "guestCard" : "guestCardAlt"}>{children}</Box>
);

const ZoomAppAuthState = ({ userContextStatus }) => (
  <Flex justifyContent="center" alignItems="center" height="100%">
    <Flex
      flexDirection="column"
      alignItems="center"
      width="80%"
      zIndex="0"
      position="relative"
    >
      {userContextStatus === CONTEXT_STATUS.UNAUTHENTICATED && (
        <Card alert={true}>
          <Text textAlign="center" textColor="gray.800" fontSize="sm">
            You are not signed into Zoom. Please click the "
            <chakra.span color="purple.400">Sign in</chakra.span>" button.
            Thanks!
          </Text>
          <Alert variant="guest">
            <Icon as={RiErrorWarningLine} color="purple.300" mr="1" />

            <AlertDescription>
              You will be redirected back to this screen
            </AlertDescription>
          </Alert>
        </Card>
      )}
      {userContextStatus === CONTEXT_STATUS.AUTHENTICATED && (
        <Card alert={false}>
          <Text textAlign="center" textColor="gray.800" fontSize="sm">
            Click the "<chakra.span color="purple.400">Add</chakra.span>"
            button below to add the Townhall Zoom App.
          </Text>
        </Card>
      )}
      {(userContextStatus === CONTEXT_STATUS.UNAUTHENTICATED ||
      userContextStatus === CONTEXT_STATUS.AUTHENTICATED) && (
        <Image
          position="absolute"
          left="630px"
          top="310px"
          src={ctaArrowImage}
        />
      )}
    </Flex>
  </Flex>
);

export default ZoomAppAuthState;
