import { Flex, Button, Text, Heading } from "@chakra-ui/react";
import { Modal } from "@components";

const HelpModal = ({ isOpen, handleClose }) => {
  return (
    <Modal isOpen={isOpen} onClose={handleClose} size="xs">
      <>
        <Heading fontSize="16px" fontWeight="700" color="dark.600" mb="3">
          PRO TIP 💡
        </Heading>
        <Text color="gray.600" fontSize="14px" mb={4}>
          Do you see "---" for your E-Score? This means you are offline and not
          in a meeting!
        </Text>
        <Text color="gray.600" fontSize="14px" mb={4}>
          Please go back to your meeting and click the web link again.
        </Text>
        <Flex width="full">
          <Button variant="primary" size="sm" onClick={handleClose} flex={1}>
            Close
          </Button>
        </Flex>
      </>
    </Modal>
  );
};

export default HelpModal;
