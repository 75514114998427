import { useNavigate } from "react-router-dom";
import { Box } from "@chakra-ui/react";
import {
  TopSection,
  WelcomeSection,
  StartTracking,
  AgendaSection,
  ProStartTrialModal,
  ProTrialReminderModal,
} from "@components";
import { useProfile, useMeeting } from "@hooks";

const HostView = () => {
  const navigate = useNavigate();
  const { profile: { firstName, _id } = {} } = useProfile();
  const {
    isHostOrCoHost,
    isMeetingActive,
    meetingInstanceId,
    meetingParticipants,
    meetingInstance,
  } = useMeeting();

  const isUserHostOrCoHost = isHostOrCoHost(_id);
  const isLiveAgenda = meetingInstance?.agenda;
  const handleBackToHome = () => navigate("/");

  return (
    <Box p={4}>
      <ProStartTrialModal handleBackToHome={handleBackToHome} />
      <ProTrialReminderModal handleBackToHome={handleBackToHome} />
      <TopSection
        isUserHostOrCoHost={isUserHostOrCoHost}
        isMeetingActive={isMeetingActive}
        meetingParticipants={meetingParticipants}
        meetingInstance={meetingInstance}
      />
      <WelcomeSection firstName={firstName} userId={_id} />
      {!isLiveAgenda && (
        <StartTracking
          isUserHostOrCoHost={isUserHostOrCoHost}
          isMeetingActive={isMeetingActive}
          meetingInstanceId={meetingInstanceId}
          isLiveAgenda={isLiveAgenda}
        />
      )}
      <AgendaSection />
    </Box>
  );
};

export default HostView;
