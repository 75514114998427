import {
  Box,
  Icon,
  Text,
  useDisclosure,
  useClipboard,
} from "@chakra-ui/react";
import { Modal, SendLink } from "@components";
import { getSharableWebUrl } from "@utils/helpers";
import { useToastNotification } from "@hooks";
import { FiCopy } from "react-icons/fi";

const copiedColors = {
  background: "green.50",
  color: "green.600",
  _hover: {
    background: "green.50",
    color: "green.600",
  },
};
const hoverStyles = {
  background: "purple.50",
  color: "purple.800"
};

const ShareMeetingWebLink = ({ meetingInstance, trigger }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { showErrorToast } = useToastNotification();
  const shareableUrl = getSharableWebUrl([meetingInstance]);
  const { onCopy, hasCopied } = useClipboard(shareableUrl);

  const handleInviteParticipants = () => {
    onOpen();
  };

  const handleClose = () => {
    onClose();
  };

  const handleCopyLinkToClipboard = async () => {
    try {
      onCopy();
    } catch (error) {
      showErrorToast(
        "Something wrong happened. We couldn't copy the url to the clipboard"
      );
    }
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={handleClose}
        size="xs"
        heading="Invite Web Attendees"
        showCloseButton
        modalContainerProps={{ alignItems: "flex-start", paddingTop: "70px" }}
      >
        <Box color="gray.600" fontSize="12px" mb={4}>
          <Text fontWeight={600} color="gray.900" fontSize="14px" mb={1}>
            Let your participants join on the web
          </Text>
          <Text mb={2}>
            Share link below for web and mobile attendees to access Townhall
            without logging into Zoom!
          </Text>
          <SendLink
            text={hasCopied ? "Link Copied" : shareableUrl}
            afterIcon={
              <Icon as={FiCopy} color={hasCopied ? copiedColors.color : null} />
            }
            handleClick={handleCopyLinkToClipboard}
            styles={hasCopied ? copiedColors : null}
            borderRadius="full"
            p={4}
            _hover={hasCopied ? {} : hoverStyles}
          />
        </Box>
      </Modal>
      <Box onClick={handleInviteParticipants}>{trigger}</Box>
    </>
  );
};

export default ShareMeetingWebLink;
