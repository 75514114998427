import {
  Button,
  CircularProgress,
  CircularProgressLabel,
  forwardRef,
} from "@chakra-ui/react";

const ProTrialPopupButton = forwardRef(
  ({ daysRemaining, maxDays, onToggle }, ref) => {
    const trialExpired = daysRemaining < 1;
    const color = (base, error) => (daysRemaining <= 5 ? error : base);
    const progressLabel = trialExpired ? 0 : daysRemaining;
    const buttonText = trialExpired
      ? "Trial Expired"
      : `Day${daysRemaining !== 1 ? "s" : ""} Remaining`;

    return (
      <Button
        ref={ref}
        disabled={trialExpired}
        onClick={onToggle}
        bg="white"
        size="xs"
        fontWeight="400"
        color={color("blue.700", "red.700")}
        borderRadius="full"
        _disabled={{ bg: "gray.300" }}
        _hover={{
          bg: `${trialExpired ? "gray.300" : "white"}`,
        }}
        leftIcon={
          <CircularProgress
            max={maxDays}
            color={color("blue.700", "red.700")}
            size="20px"
            thickness="10px"
            trackColor={color("blue.50", "red.50")}
            value={daysRemaining}
            fontSize="30px"
            fontWeight="700"
          >
            <CircularProgressLabel>{progressLabel}</CircularProgressLabel>
          </CircularProgress>
        }
      >
        {buttonText}
      </Button>
    );
  }
);

export default ProTrialPopupButton;
