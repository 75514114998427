const guestCard = {
  background: "gray.25",
  borderRadius: "18px",
  width: "300px",
  p: "3",
  position: "relative",
  _after: {
    content: "''",
    width: "250px",
    height: "100px",
    borderRadius: "18px",
    background: "dark.500",
    left: "25px",
    bottom: "-15px",
    zIndex: -1,
    position: "absolute",
  },
};

const guestCardAlt = {
  background: "gray.25",
  borderRadius: "18px",
  width: "300px",
  p: "3",
  position: "relative",
  _after: {
    content: "''",
    width: "250px",
    height: "80px",
    borderRadius: "18px",
    background: "dark.500",
    left: "25px",
    bottom: "-15px",
    zIndex: -1,
    position: "absolute",
  },
};

export { guestCard, guestCardAlt };
