import { Text, Badge } from "@chakra-ui/react";

const NotificationCount = ({ label, count }) => {
  return (
    <>
      <Text mr="1">{label}</Text>
      <Badge
        w="24px"
        fontSize="0.6rem"
        borderRadius="4px"
        padding="4px"
        bg="rgba(18, 183, 106, 0.1)"
        color="green.400"
      >
        {count}
      </Badge>
    </>
  );
};

export default NotificationCount;
