import React from "react";
import { Box } from "@chakra-ui/react";

const HostCard = ({ children, ...rest }) => {
  return (
    <Box
      borderRadius="8px"
      p={4}
      mb={4}
      bg="white"
      boxShadow="sm"
      {...rest}
    >
      {children}
    </Box>
  )
}

export default HostCard;
