import { matchPath } from "react-router-dom";
import { routeConfig  } from "@routes";
import { isZoomApp } from "@utils/helpers";
import { APP_TYPE } from "@constants";

export const getRoutePathConfig = (pathname) => {
  const matchedRoute = Object.entries(routeConfig)
    .find(([routeName]) => matchPath({
      path: routeName,
    }, pathname)
  );

  const [path, config] = matchedRoute || [];
  return config || {}
};

export const getRouteNames = (routeConfig) => {
  const routePaths = {
    back: -1,
    forward: 1
  };

  for (const key in routeConfig) {
    if (Object.prototype.hasOwnProperty.call(routeConfig, key)) {
      const route = routeConfig[key];
      const pathWithoutParams = route.path.replace(/\/:\w+|\/\*$/g, "");
      routePaths[key] = pathWithoutParams;

      if (route.path.includes("/*") && route.subPages) {
        Object.entries(route.subPages).forEach(([subConfigKey, subConfigs]) => {
          routePaths[subConfigKey] = pathWithoutParams + subConfigs.path;
        });
      }
    }
  }

  return routePaths;
};

export const removeExcludedRoutes = (config) => {
  const filteredConfig = {};
  for (const key in config) {
    const excludedApps = config[key].exclude;
    const shouldExclude = excludedApps && (
      (isZoomApp && excludedApps?.includes(APP_TYPE.ZoomApp)) || (!isZoomApp && excludedApps?.includes(APP_TYPE.WebApp))
    )
    if (!shouldExclude) {
      filteredConfig[key] = config[key];
    }
  }
  return filteredConfig;
}
