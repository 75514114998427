import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useSidebar, useMeeting } from "@hooks";
import {
  Box,
  Text,
  Flex,
  Image,
  VStack,
  IconButton,
  Button,
} from "@chakra-ui/react";
import { $Routes } from "@routes";
import { Avatar } from "@components";
import { useSdkUtils } from "@hooks";
import { findGuestUser, isZoomApp } from "@utils/helpers";
import { RiLinkedinFill, RiFacebookFill } from "react-icons/ri";
import companyIcon from "@assets/svg/icon-company.svg";
import editProfileIcon from "@assets/svg/icon-edit.svg";

const SidebarProfile = () => {
  const navigate = useNavigate();
  const { guests } = useMeeting();
  const userProfile = useSelector((state) => state.profile.data);
  const [profile, setProfile] = useState(userProfile);
  const { sidebarProfile, handleViewUserProfile } = useSidebar();
  const { openUrl } = useSdkUtils();
  const isCurrentUser =
    Object.keys(sidebarProfile).length > 0
      ? sidebarProfile._id === userProfile._id
      : true;

  const {
    _id,
    firstName,
    lastName,
    jobTitle = "",
    city = "",
    country = "",
    organization = "",
    avatar = "",
    color,
  } = profile;

  const linkedIn = profile?.social?.linkedIn || "";
  const facebook = profile?.social?.facebook || "";
  const fullName = `${firstName} ${lastName}`;
  const isInactiveUser = findGuestUser(guests, _id);

  useEffect(() => {
    if (Object.keys(sidebarProfile).length > 0) {
      setProfile({
        ...sidebarProfile,
        ...(isCurrentUser && {avatar})
      });
    }
  }, [sidebarProfile, isCurrentUser, avatar]);

  return (
    <>
      <Box
        bg="dark.500"
        borderRadius="18px 18px 0 0"
        border="1px solid"
        borderColor="dark.400"
        p="5"
      >
        <Flex align="flex-start" gridColumnGap={5}>
          <Avatar
            color={color}
            avatar={avatar}
            size="sm"
            rounded={true}
            inactive={isInactiveUser}
          />
          <Flex direction="column">
            <VStack spacing="1" alignItems="flex-start">
              <Flex alignItems="center">
                <Text color="gray.25" fontWeight="600" fontSize="14px">
                  {fullName}
                </Text>
                {isCurrentUser && (
                  <IconButton
                    size="xs"
                    variant="ghostIconButton"
                    aria-label="Edit Profile"
                    ml="6"
                    icon={<Image src={editProfileIcon} />}
                    onClick={() => navigate($Routes.editProfilePage)}
                  />
                )}
              </Flex>
              <Text color="gray.400" fontSize="12px">
                {jobTitle}
              </Text>
              <Text color="gray.400" fontSize="12px">
                {city && country && `${city}, ${country}`}
              </Text>
              <Flex gridGap={1}>
                {linkedIn ? (
                  <IconButton
                    variant="social"
                    icon={<RiLinkedinFill />}
                    aria-label="LinkedIn"
                    rounded="full"
                    size="xs"
                    onClick={() => openUrl(linkedIn)}
                  />
                ) : null}
                {facebook ? (
                  <IconButton
                    variant="social"
                    icon={<RiFacebookFill />}
                    aria-label="Facebook"
                    rounded="full"
                    size="xs"
                    onClick={() => openUrl(facebook)}
                  />
                ) : null}
              </Flex>
            </VStack>
          </Flex>
        </Flex>
      </Box>
      <Box
        bg="dark.500"
        borderRadius="0 0 18px 18px"
        border="1px solid"
        borderColor="dark.400"
        p="5"
        mb="4"
      >
        <Flex gridColumnGap={5}>
          <Image src={companyIcon} />
          <Text color="gray.25" fontWeight="600" fontSize="14px">
            {organization}
          </Text>
        </Flex>
      </Box>
      {!isCurrentUser && (
        <Button
          bg="rgba(53, 192, 252, 0.1)"
          borderRadius="8px"
          color="blue.500"
          fontSize="x-small"
          fontWeight="normal"
          isFullWidth
          _hover={{ bg: "rgba(53, 192, 252, 0.3)" }}
          _active={{ bg: "rgba(53, 192, 252, 0.1)" }}
          onClick={() => handleViewUserProfile(userProfile)}
        >
          Switch back to your profile
        </Button>
      )}
    </>
  );
};

export default SidebarProfile;
