import React from "react";
import {
  IconButton,
  Editable,
  EditablePreview,
  EditableInput as ChakraEditableInput,
  useEditableControls,
  Input,
  Image,
  Icon,
} from "@chakra-ui/react";
import { FaCheck } from "react-icons/fa";
import editInputIcon from "@assets/edit-input-icon.svg";

const EditableControls = () => {
  const { isEditing, getSubmitButtonProps, getEditButtonProps } =
    useEditableControls();

  return isEditing ? (
    <IconButton
      aria-label="Save"
      size="xs"
      icon={<Icon as={FaCheck} w="3" h="3" />}
      {...getSubmitButtonProps()}
    />
  ) : (
    <IconButton
      aria-label="Edit"
      icon={<Image src={editInputIcon} />}
      height="4"
      bg="transparent"
      _hover={{ bg: "transparent" }}
      _active={{ bg: "transparent" }}
      {...getEditButtonProps()}
    />
  );
};

const ActivityEditableInput = React.memo(({ name, value, onChange }) => (
  <Editable
    value={value}
    isPreviewFocusable={false}
    fontWeight="700"
    fontSize="12px"
    color="dark.300"
    display="flex"
    alignItems="center"
  >
    <EditablePreview />
    <Input
      name={name}
      variant="flushed"
      as={ChakraEditableInput}
      fontSize="12px"
      fontWeight="700"
      color="dark.300"
      maxLength="30"
      maxWidth="100px"
      onChange={onChange}
    />
    <EditableControls />
  </Editable>
));

export default ActivityEditableInput;
