import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { customBaseQuery } from "@store/api/baseQueries";

export const agendaApi = createApi({
  reducerPath: "agendaApi",
  baseQuery: customBaseQuery(
    fetchBaseQuery({
      baseUrl: `${process.env.REACT_APP_ZOOMAPP_BACKEND_BASE_URL}/agenda`,
    }),
  ),
  endpoints: (builder) => {
    return {
      createNewAgenda: builder.mutation({
        query: (agenda) => ({
          url: "/",
          method: "POST",
          body: agenda,
        }),
      }),
      duplicateAgenda: builder.mutation({
        query: (agendaId) => ({
          url: `/duplicate/${agendaId}`,
          method: "POST",
        }),
      }),
      fetchAgendas: builder.query({
        query: () => ({
          url: "/",
          method: "GET",
        }),
      }),
      deleteAgenda: builder.mutation({
        query: (agendaId) => ({
          url: `/${agendaId}`,
          method: "DELETE",
        }),
      }),
      fetchSingleAgenda: builder.query({
        query: (id) => ({
          url: `/${id}`,
          method: "GET",
        }),
      }),
      updateSingleAgenda: builder.mutation({
        query: ({ agendaId, meetingData: body }) => ({
          url: `/${agendaId}`,
          method: "PUT",
          body,
        }),
      }),
      goLiveAgenda: builder.mutation({
        query: ({ agendaId, meetingInstanceId }) => ({
          url: `/goLive/${agendaId}`,
          method: "PUT",
          body: { meetingInstanceId }
        }),
      }),
      stopLiveAgenda: builder.mutation({
        query: (agendaId) => ({
          url: `/stopLive/${agendaId}`,
          method: "PUT",
        }),
      }),
      instantStart: builder.mutation({
        query: (body) => ({
          url: "/instant-start",
          method: "POST",
          body,
        }),
      }),
    };
  },
});

export const {
  useCreateNewAgendaMutation,
  useDuplicateAgendaMutation,
  useDeleteAgendaMutation,
  useFetchAgendasQuery,
  useFetchSingleAgendaQuery,
  useUpdateSingleAgendaMutation,
  useGoLiveAgendaMutation,
  useStopLiveAgendaMutation,
  useInstantStartMutation
} = agendaApi;
