import PropTypes from "prop-types";
import { useSidebar } from "@hooks";
import { Text, chakra } from "@chakra-ui/react";

const NotificationText = ({ type, meta }) => {
  const { isProView } = useSidebar();
  const { firstName, color, eventType, value } = meta || {};
  let renderContent;

  switch (type) {
    case "participant":
      renderContent = (
        <>
          <chakra.span color={color} fontWeight="semibold">
            {firstName}
          </chakra.span>{" "}
          {`${eventType}`} the meeting
        </>
      );

      break;
    case "points":
      renderContent = (
        <>
          Great job engaging!{" "}
          {eventType !== "RESPONDED_TO_ACTIVITY" && (
            <chakra.span color="dark.200" fontWeight="semibold">
              +{value}pts
            </chakra.span>
          )}
        </>
      );

      break;
    case "coins":
      renderContent = (
        <>
          You received{" "}
          <chakra.span color="yellow.400" fontWeight="semibold">
            1 coin
          </chakra.span>
        </>
      );

      break;

    default:
      break;
  }

  return (
    <Text
      fontSize="10"
      width="170px"
      whiteSpace="nowrap"
      color={isProView ? "dark.400" : "white"}
      _hover={{ cursor: "default" }}
    >
      {renderContent}
    </Text>
  );
};

NotificationText.propTypes = {
  mainText: PropTypes.string,
  subText: PropTypes.string,
  color: PropTypes.string,
  type: PropTypes.string,
};

export default NotificationText;
