import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import {
  toggleSidebar,
  toggleParticipantView,
  toggleTabIndex,
  loadProfile,
} from "@store/sidebarSlice";
import { $Routes } from "@routes";

const useSidebar = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const isSidebarOpen = useSelector((state) => state.sidebar.isSidebarOpen);
  const handleSidebarToggle = () => dispatch(toggleSidebar());
  const participantView = useSelector((state) => state.sidebar.participantView);
  const handleParticipantViewToggle = (view) =>
    dispatch(toggleParticipantView(view));
  const tabIndex = useSelector((state) => state.sidebar.tabIndex);
  const handleTabIndex = (index) => dispatch(toggleTabIndex(index));
  const handleLoadProfile = (profile) => dispatch(loadProfile(profile));
  const sidebarProfile = useSelector((state) => state.sidebar.profile);
  const handleViewUserProfile = (profile) => {
    if (!isSidebarOpen) {
      handleSidebarToggle();
    }

    handleLoadProfile(profile);
    handleTabIndex(2);
  };
  const isProView = location.pathname.includes($Routes.proPage);

  return {
    isSidebarOpen,
    handleSidebarToggle,
    participantView,
    handleParticipantViewToggle,
    tabIndex,
    handleTabIndex,
    handleViewUserProfile,
    sidebarProfile,
    isProView,
  };
};

export default useSidebar;
