import { Navigate, useLocation } from "react-router-dom";
import { useProfile } from "@hooks";
import { $Routes } from "@routes";
import { isZoomApp } from "@utils/helpers";

const RequireAuth = ({ children }) => {
  const { isAuthenticated, hasToken } = useProfile();
  const location = useLocation();

  // TODO: Move this into respective hooks so can be gotten from useSdk
  const path = isZoomApp ? $Routes.rootPage : $Routes.loginPage;
  return (isAuthenticated || hasToken)
    ? children
    : <Navigate to={path} replace={true} state={{ from: location }} />;
};

export default RequireAuth;
