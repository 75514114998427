import pollIcon from "@assets/svg/icon-poll.svg";
import quizIcon from "@assets/svg/icon-quiz.svg";
import qaIcon from "@assets/svg/icon-qa.svg";
import sendIcon from "@assets/svg/icon-send.svg";
import randomizerIcon from "@assets/svg/icon-randomizer.svg";

const activitiesIcons = {
  poll: pollIcon,
  quiz: quizIcon,
  qa: qaIcon,
  send: sendIcon,
  randomizer: randomizerIcon,
};

export default activitiesIcons;
