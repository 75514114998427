import { Box } from "@chakra-ui/react";
import { Navbar } from "@components";
import loginBgLight from "@assets/login-bg-light.png";

const GlobalContainer = ({ children }) => {
  return (
    <Box backgroundImage={loginBgLight} backgroundSize="cover" height="100vh">
      <Navbar />
      <Box
        bgColor="dark.800"
        maxW="sm"
        m="0 auto"
        height="calc(100% - 62px)"
        overflow="hidden"
      >
        {children}
      </Box>
    </Box>
  );
};

export default GlobalContainer;
