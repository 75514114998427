const Heading = {
  variants: {
    authPageH1: {
      color: "gray.200",
      fontSize: "xl",
      fontWeight: "extrabold",
    },
    reportHeading: {
      color: "dark.300",
      fontWeight: "normal",
      fontSize: "12px",
      marginRight: "1",
    },
    reportSummaryHeading: {
      color: "dark.500",
      fontWeight: "600",
      fontSize: "14px",
    },
    proOnboardingHeading: {
      fontSize: "16px",
      color: "gray.900",
      borderBottom: "1px solid",
      borderColor: "gray.200",
      padding: "8px",
      width: "100%",
      textAlign: "center",
    },
  },
};

export default Heading;
