import React from "react";
import { useSelector } from "react-redux";
import { Text, Button, Icon } from "@chakra-ui/react";
import { IoCheckmarkCircleSharp, IoCloseCircleSharp } from "react-icons/io5";

const QuizModalFooter = ({
  otherProps,
  isValidInput,
  handleSubmit,
}) => {
  const showResult = useSelector((state) => state.activity.showResult);
  const submittedTextColor = otherProps?.isCorrect ? "green.700" : "red.700";
  const footerText = otherProps?.isCorrect ? "Correct" : "Sorry, Good effort!";
  const footerIcon = (
    <Icon
      as={otherProps?.isCorrect ? IoCheckmarkCircleSharp : IoCloseCircleSharp}
      color={submittedTextColor}
      w={5}
      h={5}
      marginRight={1}
    />
  );

  return showResult ? (
    <Text
      color={submittedTextColor}
      fontSize="0.875rem"
      textAlign="center"
    >
      {footerIcon}
      {footerText}
    </Text>
  ) : (
    <Button
      variant="primary"
      isFullWidth
      onClick={handleSubmit}
      disabled={!isValidInput}
    >
      Submit
    </Button>
  )
};

export default QuizModalFooter;

