import { saveDataToLocalStorage, removeDataFromLocalStorage } from "@store/customActions";
import { saveToLocalStorage, removeFromLocalStorage } from "@utils/localStorage";

const generalUtilMiddleware = (api) => (next) => (action) => {
  if (saveDataToLocalStorage.type === action.type) {
    saveToLocalStorage(action.payload.key, action.payload.value);
  }

  if (removeDataFromLocalStorage.type === action.type) {
    removeFromLocalStorage(action.payload);
  }

  return next(action);
};

export default generalUtilMiddleware;
