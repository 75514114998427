import { createEventListener } from "@sdk/zoom/utils";
import {
  handleOnAuthorized,
  handleOnMyReaction,
  handleOnMyUserContextChange,
  handleOnMyMediaChange,
  handleOnRunningContextChange,
  handleOnMyActiveSpeakerChange
} from "@sdk/zoom/eventHandlers";

export const onAuthorizedEvent = createEventListener("onAuthorized", handleOnAuthorized);
export const onMyReactionEvent = createEventListener("onMyReaction", handleOnMyReaction);
export const onMyUserContextChangeEvent = createEventListener("onMyUserContextChange", handleOnMyUserContextChange);
export const onMyMediaChangeEvent = createEventListener("onMyMediaChange", handleOnMyMediaChange);
export const onRunningContextChangeEvent = createEventListener("onRunningContextChange", handleOnRunningContextChange);
export const onMyActiveSpeakerChangeEvent = createEventListener("onMyActiveSpeakerChange", handleOnMyActiveSpeakerChange);
