import { Text, Flex } from "@chakra-ui/react";
import { Card } from "@components";
import EPulseTimer from "./EPulseTimer";

const EPulseContainer = ({ agenda, children }) => {
  return (
    <Card p="10px" position="relative" id="step4">
      <Flex justifyContent="space-between">
        <Flex flexDirection="column">
          <Text fontWeight="600" color="dark.800">
            E-Pulse
          </Text>
        </Flex>
        <Flex alignItems="center">
          <EPulseTimer agenda={agenda} />
        </Flex>
      </Flex>
      {children}
    </Card>
  );
};

export default EPulseContainer;
