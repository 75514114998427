import { Icon, IconButton } from "@chakra-ui/react";

const sharedButtonProps = {
  variant: "secondary",
  size: "sm",
};

const sharedIconProps = {
  h: "16px",
  w: "16px",
};

const InviteButton = ({ icon, iconProps, iconButtonProps, onClick }) => {
  return (
    <IconButton
      onClick={onClick}
      {...sharedButtonProps}
      {...iconButtonProps}
    >
      <Icon as={icon} {...iconProps} {...sharedIconProps} />
    </IconButton>
  );
};

export default InviteButton;
