import { FormControl, FormLabel } from "@chakra-ui/react";
import { Select } from "chakra-react-select";
import styles from "./styles";

// TODO: There currently exists another <Select> component in Profile so need to refactor it before we can use this as the default component
const ProSelect = ({
  label,
  name,
  options,
  placeholder,
  value,
  onChange,
  ...rest
}) => {
  return (
    <FormControl>
      <FormLabel fontSize="12px" color="dark.500" fontWeight="600">
        {label}
      </FormLabel>
      <Select
        name={name}
        options={options}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        chakraStyles={styles}
        {...rest}
      />
    </FormControl>
  );
};

export default ProSelect;
