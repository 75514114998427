import { Button } from "@chakra-ui/react";
import { AlertDialog } from "@components";

const AgendaModal = ({
  title,
  body,
  variant,
  isOpen,
  isLoading,
  onClose,
  handlePrimaryAction,
  primaryButtonText,
  primaryButtonVariant,
}) => {
  return (
    <AlertDialog
      variant={variant}
      title={title}
      isOpen={isOpen}
      onClose={onClose}
      primaryButton={
        <Button
          variant={primaryButtonVariant || "primary"}
          size="sm"
          isLoading={isLoading}
          onClick={handlePrimaryAction}
          ml={3}
        >
          {primaryButtonText}
        </Button>
      }
      secondaryButton={
        <Button
          variant="secondary"
          size="sm"
          onClick={onClose}
          isDisabled={isLoading}
        >
          Cancel
        </Button>
      }
    >
      {body}
    </AlertDialog>
  );
};

export default AgendaModal;
