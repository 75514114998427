import { isZoomApp } from "@utils/helpers";

export const PROGRESS_INDICATOR = isZoomApp ? 4 : 3;

export const ONBOARDING_STEPS = [
  {
    title: "Score!",
    text: "Engage in the meeting to score points and coins!",
  },
  {
    title: "Engage",
    text: "Post Emojis to engage in the meeting. Watch your E-Score boost!",
  },
  {
    title: "Explore",
    text: "Expand the sidebar to check stats and news",
  },
  {
    title: "Host / Attendee",
    text: "Quickly toggle between Host and Attendee views",
  },
];
