import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { customBaseQuery } from "@store/api/baseQueries";

export const iconApi = createApi({
  reducerPath: "iconApi",
  baseQuery: customBaseQuery(
    fetchBaseQuery({
      baseUrl: `${process.env.REACT_APP_ZOOMAPP_BACKEND_BASE_URL}/icon`,
    }),
  ),
  endpoints: (builder) => {
    return {
      getAllIcons: builder.query({
        query: () => ({
          url: "/all",
          method: "GET",
        }),
      }),
    };
  },
});

export const { useGetAllIconsQuery } = iconApi;
