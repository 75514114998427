import { useState } from "react";
import { useParams } from "react-router-dom";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button
} from "@chakra-ui/react";
import { useAgendaActions, useMeeting, useProfile } from "@hooks";
import { EditAgendaForm } from "@components";
import { generateMeetingUuid } from "@utils/helpers";

const EditAgenda = ({
  isOpen,
  onClose,
  agenda
}) => {
  const { updateAgenda: { isLoading, handleUpdateAgenda } } = useAgendaActions();
  const { meetingObj } = useMeeting();
  const { profile } = useProfile();
  const { agendaId } = useParams();

  const [agendaDetails, setAgendaDetails] = useState({
    title: agenda?.title || "",
    description: agenda?.description || ""
  });

  const handleSaveForm = async () => {
    const meetingUuid = generateMeetingUuid(
      agendaDetails.meetingId,
      profile._id
    );

    handleUpdateAgenda(
      {
        agendaId,
        meetingId: meetingObj ? meetingObj._id : meetingUuid,
        ...agendaDetails
      },
      onClose
    );
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      closeOnOverlayClick={false}
      size="xs"
      isCentered
    >
      <ModalOverlay />
      <ModalContent borderRadius="12px">
        <ModalHeader fontWeight="600" fontSize="sm" color="dark.400">
          Event Information
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody color="gray.600" fontSize="12px">
          <EditAgendaForm
            agenda={agendaDetails}
            setAgendaDetails={setAgendaDetails}
          />
        </ModalBody>
        <ModalFooter>
          <Button
            fontSize="sm"
            fontWeight="500"
            color="gray.500"
            mr={3}
            onClick={onClose}
            isFullWidth
          >
            Cancel
          </Button>
          <Button
            bg="green.50"
            fontSize="sm"
            fontWeight="500"
            color="green.700"
            isFullWidth
            isLoading={isLoading}
            loadingText="Saving..."
            onClick={handleSaveForm}
          >
            Save
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default EditAgenda;
