import { EPulseContainer, EPulseChart } from "@components";

const EPulse = ({ agenda }) => {
  return (
    <EPulseContainer agenda={agenda}>
      <EPulseChart agenda={agenda} />
    </EPulseContainer>
  );
};

export default EPulse;
