import { Box } from "@chakra-ui/react";

const Dot = ({ active, handleClick }) => {
  return (
    <Box
      onClick={handleClick}
      as="span"
      height={2}
      width={2}
      background={active ? "dark.600" : "gray.300"}
      borderRadius="50%"
      display="inline-block"
      marginRight={3}
      _hover={{ cursor: "pointer" }}
    />
  );
};

export default Dot;
