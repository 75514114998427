const Input = {
  variants: {
    auth: {
      field: {
        fontSize: "14px",
        color: "gray.50",
        bg: "dark.700",
        border: "2px solid",
        borderColor: "transparent",
        _focus: {
          border: "2px solid",
          borderColor: "purple.300",
        },
        _invalid: {
          border: "2px solid",
          borderColor: "red.300",
        },
      },
    },
    iconPickerContainer: {
      field: {
        bg: "dark.400",
        color: "gray.300",
        fontSize: "12px",
        borderRadius: "6px",
        mb: "14px",
        _focus: {
          border: "2px solid",
          borderColor: "purple.300",
        },
        _placeholder: {
          color: "dark.100",
        },
      },
    },
  },
  defaultProps: {
    errorBorderColor: "red.300",
  },
};

export default Input;
