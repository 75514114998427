import { Image } from "@chakra-ui/react";
import deleteIcon from "./img/delete.svg";

const AlertDialogHeaderIcon = ({ variant, icon }) => {
  let src = icon;

  switch (variant) {
    case "delete":
      src = deleteIcon;
  }

  return <Image mr="14px" src={src} />;
};

export default AlertDialogHeaderIcon;
