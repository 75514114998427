import { Stack, Skeleton, HStack } from "@chakra-ui/react";

const AgendaLoader = () => {
  return (
    <Stack spacing={1} mt={2}>
      <HStack>
        <Skeleton w="33.3%" height="30px" />
        <Skeleton w="33.3%" height="30px" />
        <Skeleton w="33.3%" height="30px" />
      </HStack>
      <Skeleton height="30px" />
      <Skeleton height="50px" />
      <Skeleton height="150px" />
    </Stack>
  );
};

export default AgendaLoader;
