import { Text } from "@chakra-ui/react";
import { ProgressBar } from "@components";
import { getActivityAnswers } from "@utils/helpers";

const PollResults = ({ title, options, response }) => {
  const filterAnswerCallback = (response, option) => response.responseIds.includes(option._id);
  const responseArray = getActivityAnswers(options, response, filterAnswerCallback, response.length);

  const COLORS = ["#f3e7fa", "#fff8eb", "#ffe9ee", "#93FFD8", "#35C0FC"];

  return (
    <>
      <Text fontWeight="600" fontSize="12px" color="dark.400" mb="1">
        {title}
      </Text>
      {responseArray.map((res, index) => (
        <ProgressBar
          key={res.id}
          text={res.label}
          value={res.value}
          color={res.value > 0 ? COLORS[index] : "gray.100"}
        />
      ))}
    </>
  );
};

export default PollResults;
