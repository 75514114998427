import { Box } from "@chakra-ui/react";
import { HOST_CONTAINER_WIDTH } from "@constants/layoutDimensions";

const CenteredFixedWidthContainer = ({ children, bg }) => {
  return (
    <Box
      height="inherit"
      width="100%"
    >
      <Box
        margin="0 auto"
        width={{ base: "100%", md: HOST_CONTAINER_WIDTH }}
        maxW={HOST_CONTAINER_WIDTH}
        bg={bg || "dark.800"}
        height="inherit"
        overflow="hidden"
      >
        {children}
      </Box>
    </Box>
  );
};

export default CenteredFixedWidthContainer;
