import { Helmet } from "react-helmet-async";
import CryptoJS from "crypto-js";
import { MINIMUM_ESCORE, MINUTES_TO_LOCK } from "@constants";
import dayjs from "dayjs";
import { debounce } from "@utils/icon";
import {
  getFromLocalStorage,
  removeFromLocalStorage,
} from "@utils/localStorage";
import startActivityAudioFile from "@assets/audio/startActivity.mp3";
import {
  AUDIO_EVENTS,
  LICENSE_TYPES,
  MEETING_INFO_ENCRYPTION_KEY,
  USER_MEETING_INFO_KEY,
} from "@constants";
import { SUBSCRIPTION_TYPES } from "@constants/index";

export const randomNumber = (min, max) => {
  const randomNum = Math.random() * (max - min) + min;
  return randomNum;
};

export const capitalizeFirstLetter = (string) =>
  string.charAt(0).toUpperCase() + string.slice(1);

export const formatNumber = (num, digits) => {
  if (num >= 1e6) {
    return `${(num / 1e6).toFixed(digits)}M`;
  }

  if (num >= 1e3) {
    return `${(num / 1e3).toFixed(digits)}K`;
  }

  return num;
};

export const displayEscore = (isMeetingActive, eScore) =>
  isMeetingActive ? (eScore > MINIMUM_ESCORE ? `${eScore}%` : "Low") : "---";

// TODO: write test for this function
export const getActivityOptions = (activity) =>
  activity.options.map((option) => {
    return { ...option };
  });

// TODO: write test for this function
export const getOptionsArrayFromAnswerList = (activityAnswers) => {
  return activityAnswers.map((answer) => answer);
};

export const getPercentage = (count, totalCount) => {
  const value = (100 * count) / totalCount;
  return isFinite(value) ? value : 0;
};

// TODO: write test for this function
export const getActivityAnswers = (
  options,
  answers,
  filterCallback,
  totalAnswers = null
) =>
  options.map((opt) => {
    const optionAnswers = answers.filter((answer) =>
      filterCallback(answer, opt)
    );
    const { _id, option, ...rest } = opt;

    return {
      id: _id,
      label: option,
      value: Math.round(
        getPercentage(optionAnswers.length, totalAnswers || answers.length)
      ),
      ...rest,
    };
  });

export const LoadGoogleAnalytics = () => {
  const host = window.location.hostname;
  const tagIds = {
    "za.townhallspacetest.com": process.env.REACT_APP_GOOGLE_ANALYTICS_STAGING,
    "za.townhall.pro": process.env.REACT_APP_GOOGLE_ANALYTICS_PRODUCTION,
  };
  const tagId = tagIds[host];

  if (!tagId) {
    return null;
  }

  return (
    <Helmet>
      <script
        async
        src={`https://www.googletagmanager.com/gtag/js?id=${tagId}`}
      />
      <script
        dangerouslySetInnerHTML={{
          __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${tagId}');
          `,
        }}
      />
    </Helmet>
  );
};

export const truncateName = (name) => {
  let truncate = name.split("").slice(0, 5).join("");
  return name.length > 5 ? `${truncate}...` : name;
};

// TODO: write test for this function
export const isZoomApp = navigator.userAgent.indexOf("ZoomApps") !== -1;

// TODO: write test for this function
export const formattedDate = (date, format) => dayjs(date).format(format);

// TODO: write test for this function
export const timeInFutureFromNow = (now) => now.add(MINUTES_TO_LOCK, "minute");

// TODO: write test for this function
export const daysRemaining = (days, now, startDate) =>
  days - dayjs(now).diff(startDate, "days");

// TODO: write test for this function
export const openUrlFromWeb = (url) => window.open(url, "_blank");

// TODO: write test for this function
export const getMeetingIdFromUuid = (meetingUuid) =>
  meetingUuid?.split("#")?.[0];

// TODO: write test for this function
export const getUserIdFromMeetingInstanceId = (meetingInstanceId) =>
  meetingInstanceId?.split("#")?.[1];

// TODO: write test for this function
export const findGuestUser = (guests, id) =>
  guests.find((guest) => guest._id === id);

// TODO: write test for this function
const handlePlayAudio = (eventType) => {
  if (eventType === AUDIO_EVENTS.START_ACTIVITY) {
    const startActivityAudio = new Audio(startActivityAudioFile);
    startActivityAudio.load();
    startActivityAudio.play().catch((error) => {});
  }
};

// TODO: write test for this function
export const playAudio = (eventType) => {
  debounce(handlePlayAudio(eventType));
};

// TODO: write test for this function
export const generateMeetingUuid = (meetingId, profileId) =>
  meetingId ? `${meetingId}#${profileId}` : "";

export const isProUser = (licenseType) => licenseType === LICENSE_TYPES.Pro;

export const isBasicUser = (licenseType) => licenseType === LICENSE_TYPES.Basic;

export const isTrialUser = (licenseType) => licenseType === LICENSE_TYPES.Trial;

export const isDevUser = (licenseType) => licenseType === LICENSE_TYPES.Dev;

export const isProEnterpriseUser = (licenseType, subscriptionName) => {
  return (
    licenseType === LICENSE_TYPES.Pro &&
    subscriptionName === SUBSCRIPTION_TYPES.Enterprise
  );
};

// TODO: write test for this function
export const isWebUser = (profile) => !(isZoomApp && profile?.licenseType);

// TODO: write test for this function
export const getSharableWebUrl = (meetingInstance) => {
  const publicUrl = process.env.REACT_APP_WEB_URL;
  const platformMeetingId = getMeetingIdFromUuid(
    meetingInstance?.[0]?.meetingUuid
  );
  const platformMeetingInstanceId = encodeURIComponent(
    meetingInstance?.[0]?.platformMeetingInstanceId
  );
  return `${publicUrl}/meeting/?platformMeetingId=${platformMeetingId}&platformMeetingInstanceId=${platformMeetingInstanceId}`;
};

// TODO: write test for this function
export const encodeObject = (userMeetingInfo) => {
  const jsonString = JSON.stringify(userMeetingInfo);
  return CryptoJS.AES.encrypt(
    jsonString,
    MEETING_INFO_ENCRYPTION_KEY
  ).toString();
};

// TODO: write test for this function
export const decodeObject = (hash) => {
  try {
    const decrypted = CryptoJS.AES.decrypt(
      hash,
      MEETING_INFO_ENCRYPTION_KEY
    ).toString(CryptoJS.enc.Utf8);
    return JSON.parse(decrypted);
  } catch (error) {
    console.log("Decryption failed:", error);
  }
};

// TODO: write test for this function
export const getUserMeetingInfo = () => {
  const userMeetingInfo = getFromLocalStorage(USER_MEETING_INFO_KEY);
  if (userMeetingInfo) {
    return decodeObject(userMeetingInfo);
  }
};

// TODO: write test for this function
export const saveMeetingInfo = (payload) => {
  localStorage.setItem(USER_MEETING_INFO_KEY, payload);
};

// TODO: write test for this function
export const clearMeetingInfo = () =>
  removeFromLocalStorage(USER_MEETING_INFO_KEY);

export const generateUrlWithQueryParams = (path, queryParams = null) => {
  if (!queryParams) {
    return path;
  }

  const query = new URLSearchParams(queryParams).toString();
  return `${path}?${query}`;
};

export const isNotNullOrEmpty = (value) => {
  return value !== null && value !== undefined && value !== "";
};

export const mergeInitialValues = (defaultlValues, overrideValues) => {
  const mergedValues = { ...defaultlValues };

  for (const key in overrideValues) {
    if (
      overrideValues.hasOwnProperty(key) &&
      isNotNullOrEmpty(overrideValues[key])
    ) {
      mergedValues[key] = overrideValues[key];
    }
  }
  return mergedValues;
};

export const generateSendToolUrl = (url) => {
  return `${process.env.REACT_APP_WEB_URL}/send-link?url=${encodeURIComponent(
    new URL(url)
  )}`;
};
