import {
  Box,
  Button,
  Flex,
  Text,
  Heading,
  Popover,
  PopoverContent,
  PopoverTrigger,
  PopoverArrow,
  useDisclosure,
} from "@chakra-ui/react";
import { useAuth } from "@hooks";
import {
  ONBOARDING_STEPS,
  PROGRESS_INDICATOR,
} from "@constants/userOnboarding";
import { isWebUser, isZoomApp } from "@utils/helpers";

const UserOnboarding = ({
  index,
  isOpen,
  placement,
  onClick,
  isLoading,
  children,
}) => {
  const { onClose } = useDisclosure();
  const { profile } = useAuth();
  const buttonText =
    (isWebUser(profile) && index === 2) || (isZoomApp && index === 3)
      ? "Finish"
      : "Next";

  return (
    <Popover
      autoFocus={false}
      returnFocusOnClose={false}
      isOpen={isOpen}
      onClose={onClose}
      placement={placement || "top"}
      closeOnBlur={false}
      variant="onboarding"
    >
      <PopoverTrigger>{children}</PopoverTrigger>

      <PopoverContent>
        <PopoverArrow />
        <Box>
          <Heading fontSize="md" mb="2">
            {ONBOARDING_STEPS[index]?.title}
          </Heading>
          <Text fontSize="xs">{ONBOARDING_STEPS[index]?.text}</Text>
          <Flex justifyContent="space-between" mt="5">
            <Text
              as="span"
              fontSize="10px"
              p={2}
              bg="purple.600"
              borderRadius="6px"
            >
              {`${index + 1} of ${PROGRESS_INDICATOR}`}
            </Text>
            <Button
              zIndex={10000}
              variant="secondary"
              size="sm"
              onClick={onClick}
              isLoading={isLoading}
              disabled={isLoading}
            >
              {buttonText}
            </Button>
          </Flex>
        </Box>
      </PopoverContent>
    </Popover>
  );
};

export default UserOnboarding;
