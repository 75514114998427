import { useSelector } from "react-redux";
import { useSidebar, useMeeting, useEngagement } from "@hooks";
import { SidebarButton, SidebarClosed, SidebarOpen } from "@components";
import { Box } from "@chakra-ui/react";
import { SIDEBAR_WIDTH } from "@constants";

const Sidebar = ({ height }) => {
  const { isSidebarOpen } = useSidebar();
  const { meetingParticipants, guests, hostId } = useMeeting();
  const { points, coins } = useEngagement();
  const eScore = useSelector((state) => state.engagement.eScore);
  const profile = useSelector((state) => state.profile.data);

  return (
    <>
      <SidebarButton />
      <Box
        display="flex"
        flexDirection="column"
        height={height || "100%"}
        width={isSidebarOpen ? SIDEBAR_WIDTH : "110px"}
        background="dark.700"
        transition="width 0.3s cubic-bezier(0.820, 0.085, 0.395, 0.895)"
      >
        {isSidebarOpen ? (
          <SidebarOpen
            participants={meetingParticipants}
            guests={guests}
            hostId={hostId}
            points={points}
            coins={coins}
            eScore={eScore}
            profile={profile}
          />
        ) : (
          <SidebarClosed
            participants={meetingParticipants}
            guests={guests}
            hostId={hostId}
          />
        )}
      </Box>
    </>
  );
};

export default Sidebar;
