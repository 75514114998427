import React from "react";
import {
  InputGroup,
  NumberInput,
  NumberInputField,
  Text,
  InputRightAddon
} from "@chakra-ui/react";

const SettingsDurationInput = ({
  name,
  value,
  touched,
  errors,
  onChange,
  onBlur,
  sharedInputProps
}) => (
  <>
    <InputGroup size="md" mb="1" py="2px">
      <NumberInput
        value={value}
        name={name}
        max={10}
        min={1}
        w="100%"
        isInvalid={!!(touched?.settings?.duration && errors?.settings?.duration)}
      >
        <NumberInputField
          borderRightRadius={0}
          name={name}
          type="number"
          onChange={onChange}
          onBlur={onBlur}
          {...sharedInputProps}
        />
      </NumberInput>
      <InputRightAddon children="min" fontSize="12px" />
    </InputGroup>
    <Text color="dark.200" fontSize="10px">
      10 min time limit
    </Text>
  </>
);

export default SettingsDurationInput;
