import { useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  addNotificationToChatBox,
  addNotificationToSidebar,
  removeNotificationFromSidebar,
  removeNotificationFromChatBox,
  clearAllNotifications,
} from "@store/notificationsSlice";
import { useDeleteNotificationMutation } from "@store/api/notifications";

const useNotifications = () => {
  const dispatch = useDispatch();
  const notifications = useSelector((state) => state.notifications.sidebar);
  const chatNotification = useSelector((state) => state.notifications.chat);
  const [deleteNotification] = useDeleteNotificationMutation();

  const handleAddNotification = useCallback((data) => {
    dispatch(addNotificationToSidebar({ notification: data }));
    dispatch(addNotificationToChatBox({ notification: data }));

    setTimeout(() => {
      dispatch(removeNotificationFromChatBox());
    }, 2000);
  }, [dispatch]);

  const handleRemoveNotification = useCallback((notificationId, type) => {
    if (type === "participant") {
      dispatch(removeNotificationFromSidebar({ notificationId }));
      return;
    }

    dispatch(removeNotificationFromSidebar({ notificationId }));
    deleteNotification({
      notificationId,
    });
  }, [dispatch, deleteNotification]);

  const handleRemoveAllNotifications = useCallback(() => {
    dispatch(clearAllNotifications());
    deleteNotification();
  }, [dispatch, deleteNotification]);

  return {
    notifications,
    chatNotification,
    handleAddNotification,
    handleRemoveNotification,
    handleRemoveAllNotifications,
  };
};

export default useNotifications;
