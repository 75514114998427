import { useState, useEffect, useMemo } from "react";
import { useFetchProfileQuery } from "@store/api/auth";
import { useProfile, useMeetingActions } from "@hooks";
import { CONTEXT_STATUS, APP_TYPE } from "@constants";
import { getUserMeetingInfo } from "@utils/helpers";

const useWebView = () => {
  const [userContextStatus, setUserContextStatus] = useState(CONTEXT_STATUS.UNAUTHENTICATED);
  const { isAuthenticated, hasToken, profileId } = useProfile();
  useFetchProfileQuery(null, { skip: isAuthenticated || !hasToken  });
  const { checkUserLiveMeeting } = useMeetingActions();
  const [hasInValidMeetingInfo, setHasInValidMeetingInfo] = useState(false);

  const isAuthorized = useMemo(() => isAuthenticated || hasToken, [isAuthenticated, hasToken])

  useEffect(() => {
    const userMeetingInfo = getUserMeetingInfo();
    if (userMeetingInfo) {
      checkUserLiveMeeting(userMeetingInfo);
    }
  }, [checkUserLiveMeeting]);

  useEffect(() => {
    if (profileId) {
      setUserContextStatus(CONTEXT_STATUS.AUTHORIZED);
      setHasInValidMeetingInfo(false);
    }
  }, [profileId]);

  return {
    hasInValidMeetingInfo,
    userContextStatus,
    isAuthorized,
    appType: APP_TYPE.WebApp,
  };
}

export default useWebView;
