import React from "react";
import { Checkbox, Text } from "@chakra-ui/react";

const SettingsShowResultCheckbox = ({ value, onChange, name, label, size }) => (
  <Checkbox
    name={name}
    size={size || "md"}
    isChecked={value.settings.showResult}
    onChange={onChange}
    pt={2}
  >
    <Text fontSize="xs">{label}</Text>
  </Checkbox>
);

export default SettingsShowResultCheckbox