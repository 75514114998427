import {
  Box,
  Text,
  Button,
  useDisclosure,
} from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "@components";
import { showConnectFromAnotherClientModal } from "@store/notificationsSlice";

const TabChangeDisconnectModal = () => {
  const { onClose } = useDisclosure();
  const dispatch = useDispatch();
  const isOpen = useSelector((state) => state.notifications.showConnectFromAnotherClientModal);

  const handleClose = () => {
    dispatch(showConnectFromAnotherClientModal(false));
    onClose();
  };

  const handleReload = () => {
    window.location.reload();
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={handleClose}
        size="xs"
        showCloseButton
      >
        <Box fontSize="12px">
          <Text mb={2}>
            Oops! You are idle on this screen. Reconnect here? 
          </Text>
          <Button
            variant="primary"
            w="full"
            size="sm"
            onClick={handleReload}
          >
            Yes, reconnect here!
          </Button>
        </Box>
      </Modal>
    </>
  );
};

export default TabChangeDisconnectModal;
