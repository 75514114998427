import { APP_TYPE } from "@constants";

export const REFRESH_TOKEN_FAILED = {
  [APP_TYPE.ZoomApp]: "Authorization error. Please signin to Zoom or refresh Zoom apps if you're already signed in",
  [APP_TYPE.WebApp]: "Your session expired. Login to continue",
};

export const DEFAULT_TOAST_ERROR_MESSAGE = "We encountered a problem. Please try again";
export const MEETING_CONNECT_ERROR_MESSAGE = "We cannot connect you to this meeting. Ensure you're using the right invite link";
export const DEFAULT_ERROR_GOING_LIVE_MESSAGE = "Error going live, please try again";
export const DEFAULT_ERROR_STOPPING_AGENDA_MESSAGE = "Error stopping agenda, please try again";
export const DEFAULT_INVITE_PARTICIPANTS_ERROR_MESSAGE = "An error occured. Zoom App invite not sent";
export const DEFAULT_UPDATE_AGENDA_ERROR_MESSAGE = "Error updating agenda, please try again";
