import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  runTour: false,
  stepIndex: 0,
  isLoading: false,
  type: "",
};

export const tourSlice = createSlice({
  name: "tour",
  initialState,
  reducers: {
    updateRunTour: (state, action) => {
      state.runTour = action.payload;
    },
    updateTourStepIndex: (state, action) => {
      state.stepIndex = action.payload;
    },
    updateTourLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    updateTourType: (state, action) => {
      state.type = action.payload;
    },
  },
});

const { reducer, actions } = tourSlice;

export const {
  updateRunTour,
  updateTourStepIndex,
  updateTourLoading,
  updateTourType,
} = actions;

export default reducer;
