import { Flex, Button, Box, Badge } from "@chakra-ui/react";
import { useAgendaActions } from "@hooks";

const sharedButtonProps = {
  variant: "secondary",
  size: "sm",
  isFullWidth: true,
  mt: 4,
  height: "40px"
};

const CreateAgenda = () => {
  const { createAgenda: { isLoading, handleCreateAgenda } } = useAgendaActions();
  return (
    <Flex justifyContent="space-between" mb={4}>
      <Button
        width="48%"
        isLoading={isLoading}
        onClick={handleCreateAgenda}
        {...sharedButtonProps}
      >
        New
      </Button>
      <Box width="48%" position="relative" height="40px">
        <Badge
          p="1"
          ml="1"
          h="16px"
          textAlign="center"
          variant="solid"
          bgGradient="linear(to-b, purple.300, blue.300)"
          borderRadius="full"
          fontSize="9px"
          fontWeight="600"
          letterSpacing="1.25px"
          lineHeight="0.95"
          position="absolute"
          top="8px"
          right="50%"
          zIndex={999}
          transform="translate(50%)"
        >
          Coming Soon
        </Badge>
        <Button
          {...sharedButtonProps}
        >
          Template
        </Button>
      </Box>
    </Flex>
  )
}

export default CreateAgenda;
