import { useEffect, useCallback } from "react";
import { Box } from "@chakra-ui/react";
import { useSelector, useDispatch } from "react-redux";
import { getActivityAnswers } from "@utils/helpers";
import { ProgressAnswerBar } from "@components/ActivityTypes/common";
import { addUserResponse } from "@store/activitySlice";

const QuizResult = ({ setModalState }) => {
  const dispatch = useDispatch();
  const userResponse = useSelector((state) => state.activity?.userResponse);
  const response = useSelector((state) => state.activity.response);
  const options = useSelector((state) => state.activity.options);
  const showResult = useSelector((state) => state.activity.showResult);
  const profile = useSelector((state) => state.profile.data);

  const isAnswerCorrect = (userResponses = [], quizOptions = []) => {
    const correctOptions = quizOptions.filter((option) => option.correct);

    if (
      !userResponses.length ||
      correctOptions.length !== userResponses.length
    ) {
      return false;
    }
    return correctOptions.every((option) => userResponses.includes(option._id));
  };

  const filterAnswerCallback = (answer, option) =>
    answer.responseIds.includes(option._id);

  const getQuizAnswerLength = (response) =>
    [...response].reduce((a, b) => a + b, 0);

  const responseNumberArray = [...response].map(
    (answer) => answer.responseIds.length
  );

  const totalAnswerLength = getQuizAnswerLength(responseNumberArray);
  const answerArray = getActivityAnswers(
    options,
    response,
    filterAnswerCallback,
    totalAnswerLength
  );

  const getUserResponse = useCallback(
    (responses) => {
      const userProvidedResponse = responses.find(
        (response) => response?.user?.userId === profile._id
      );
      return userProvidedResponse?.responseIds || [];
    },
    [profile]
  );

  useEffect(() => {
    if (response.length > 0) {
      const currentUserResponse = getUserResponse(response);
      dispatch(addUserResponse({ userResponse: currentUserResponse }));
    }
  }, [response, dispatch, getUserResponse]);

  useEffect(() => {
    setModalState({
      otherProps: {
        isCorrect: isAnswerCorrect(userResponse, options),
      },
    });
  }, [options, showResult, setModalState, userResponse]);

  return (
    <Box p={2}>
      {answerArray.map((option) => (
        <ProgressAnswerBar
          key={option.id}
          option={option}
          userResponse={userResponse}
        />
      ))}
    </Box>
  );
};

export default QuizResult;
