import PropTypes from "prop-types";
import { useRef } from "react";
import {
  AlertDialog as ChakraDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogCloseButton,
} from "@chakra-ui/react";
import { AlertDialogHeaderIcon } from "@components";

const AlertDialog = ({
  isOpen,
  onClose,
  title,
  children,
  secondaryButton,
  primaryButton,
  variant,
  headerIcon,
}) => {
  const cancelRef = useRef();

  return (
    <ChakraDialog
      isOpen={isOpen}
      leastDestructiveRef={cancelRef}
      onClose={onClose}
      isCentered
    >
      <AlertDialogOverlay>
        <AlertDialogContent maxW="16rem">
          <AlertDialogHeader
            fontSize="14px"
            fontWeight="600"
            color="#000C3B"
            display="flex"
            alignItems="center"
          >
            {headerIcon ? (
              <AlertDialogHeaderIcon
                {...(variant && { variant: variant })}
                {...(headerIcon && { icon: headerIcon })}
              />
            ) : null}{" "}
            {title}
          </AlertDialogHeader>
          <AlertDialogCloseButton color="#D0D5DD" />
          <AlertDialogBody fontWeight="400" fontSize="12px" color="#475467">
            {children}
          </AlertDialogBody>
          <AlertDialogFooter>
            {secondaryButton}
            {primaryButton}
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </ChakraDialog>
  );
};

AlertDialog.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  title: PropTypes.string.isRequired,
  children: PropTypes.any.isRequired,
  primaryButton: PropTypes.element,
  secondaryButton: PropTypes.element,
  variant: PropTypes.string,
  headerIcon: PropTypes.string,
};

export default AlertDialog;
