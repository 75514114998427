import React from "react";
import { Box } from "@chakra-ui/react";
import { SettingsDurationInput, SettingsCheckbox } from "@components/ActivityTypes/common";

const QuizSettings = React.memo(({ formik, sharedInputProps }) => {
  const { handleChange, values, errors, touched, handleBlur } = formik;

  return (
    <Box
      overflowY="auto"
      w="100%"
      sx={{
        "&::-webkit-scrollbar": {
          width: "4px",
        },
        "&::-webkit-scrollbar-track": {
          backgroundColor: "white",
          borderRadius: "24px",
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: "red",
          borderRadius: "24px",
        },
        "&::-webkit-scrollbar-thumb:hover": {
          backgroundColor: "red",
        },
      }}
    >
      <SettingsDurationInput
        name="settings.duration"
        value={values?.settings?.duration}
        touched={touched}
        errors={errors}
        onChange={handleChange}
        onBlur={handleBlur}
        sharedInputProps={sharedInputProps}
      />
      <SettingsCheckbox
        value={values}
        onChange={handleChange}
        name="settings.showResult"
        label="Show Quiz result to attendees"
      />
    </Box>
  )
}, (prevProps, nextprops) => !(
    prevProps.formik.values.settings !== nextprops.formik.values.settings ||
    prevProps.formik.errors.settings !== nextprops.formik.errors.settings ||
    prevProps.formik.touched.settings !== nextprops.formik.touched.settings
  )
);

export default QuizSettings;
