import React from "react";
import { Icon, Tooltip as ChakraTooltip, Flex } from "@chakra-ui/react";
import { BsInfoSquareFill } from "react-icons/bs";

const TooltipTargetWrapper = React.forwardRef(({ children, ...props }, ref) => {
  return (
    <Flex ref={ref} {...props}>
      {children}
    </Flex>
  )}
);

const Tooltip = ({ label, trigger, position, bg, color, ...rest }) => (
  <ChakraTooltip
    placement={position || "auto"}
    label={label}
    bg={bg || "gray.900"}
    color={color || "white"}
    fontSize="14px"
    {...rest}
  >
    <TooltipTargetWrapper>
      {trigger || <Icon as={BsInfoSquareFill} color="gray.400" />}
    </TooltipTargetWrapper>
  </ChakraTooltip>
);

export default Tooltip;
