import { createSlice } from "@reduxjs/toolkit";
import { reportApi } from "@store/api/report";

const initialState = {
  eReport: {},
  lastTownhall: {},
};

const reportSlice = createSlice({
  name: "report",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(
      reportApi.endpoints.getAgendaReport.matchFulfilled,
      (state, { payload }) => {
        if (payload.leaderboard) {
          state.eReport = payload;
        } else {
          state.lastTownhall = payload;
        }
      }
    );
  },
});

const { reducer } = reportSlice;

export default reducer;
