import pollIcon from "@assets/svg/icon-poll.svg";
import quizIcon from "@assets/svg/icon-quiz.svg";
import qaIcon from "@assets/svg/icon-qa.svg";
import sendIcon from "@assets/svg/icon-send.svg";
import randomizerIcon from "@assets/svg/icon-randomizer.svg";

export const DEFAULT_MAX_OPTIONS = 2;

export const activityTypeObject = {
  poll: {
    id: 0,
    title: "Poll",
    description: "Ask attendees to vote",
    icon: pollIcon,
    vote: false,
    name: "poll",
    response: {
      singular: "Vote",
      plural: "Votes",
    },
    defaultOptions: new Array(DEFAULT_MAX_OPTIONS).fill({ option: "" }),
    defaultSettings: {
      duration: 2,
      pollType: "string",
      showResult: true,
    },
  },
  quiz: {
    id: 1,
    title: "Quiz",
    description: "Ask a multiple choice question",
    icon: quizIcon,
    vote: false,
    name: "quiz",
    response: {
      singular: "Answer",
      plural: "Answers",
    },
    defaultOptions: new Array(DEFAULT_MAX_OPTIONS).fill({
      option: "",
      correct: false,
    }),
    defaultSettings: {
      duration: 2,
      showResult: true,
    },
  },
  send: {
    id: 2,
    title: "Send",
    description: "Distribute event content (slides, etc.)",
    icon: sendIcon,
    vote: false,
    name: "send",
    response: {
      singular: "Viewer",
      plural: "Viewers",
    },
    defaultOptions: new Array(1).fill({ option: "" }),
    defaultSettings: {
      blast: true,
    },
  },
  // qa: {
  //   id: 3,
  //   title: "Q&A",
  //   description: "Call on attendees that have a question",
  //   icon: qaIcon,
  //   vote: true,
  //   name: "qa",
  //   response: {},
  // },
  // randomizer: {
  //   id: 4,
  //   title: "Randomizer",
  //   description: "Call on an attendee at random",
  //   icon: randomizerIcon,
  //   vote: true,
  //   name: "randomizer",
  //   response: {},
  // }
};

export const createActivityObjectbyKey = (key) =>
  Object.values(activityTypeObject).reduce(
    (acc, next) => ({ ...acc, [next.name]: next[key] }),
    {}
  );

export const activityTypesList = Object.values(activityTypeObject).sort(
  (a, b) => (a.id > b.id ? 1 : -1)
);

export const activityTypes = createActivityObjectbyKey("name");

export const activityIcons = createActivityObjectbyKey("icon");

export const activityResponseTypes = createActivityObjectbyKey("response");

export const defaultActivityOptions =
  createActivityObjectbyKey("defaultOptions");

export const defaultActivitySettings =
  createActivityObjectbyKey("defaultSettings");

export const activityTitles = createActivityObjectbyKey("title");
