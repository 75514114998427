import { useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { updateAgendas } from "@store/agendasSlice";
import { useNavigate } from "react-router-dom";
import {
  updateRunTour,
  updateTourStepIndex,
  updateTourLoading,
  updateTourType,
} from "@store/tourSlice";
import {
  useUpdateOnboardingMutation,
  useUpdateOnboardingProcessMutation,
} from "@store/api/auth";
import { TOUR_SAMPLE_AGENDA } from "@constants/tour";
import { STEPS } from "@constants";
import { $Routes } from "@routes";

const useTour = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [updateOnboarding] = useUpdateOnboardingMutation();
  const [updateOnboardingProcess] = useUpdateOnboardingProcessMutation();
  const runTour = useSelector((state) => state.tour.runTour);
  const stepIndex = useSelector((state) => state.tour.stepIndex);
  const isTourLoading = useSelector((state) => state.tour.isLoading);
  const isHostTour = useSelector((state) => state.tour.type === "host");
  const profile = useSelector((state) => state.profile.data);

  const handleRunTour = useCallback(
    (run) => dispatch(updateRunTour(run)),
    [dispatch]
  );
  const handleTourLoading = useCallback(
    (loading) => dispatch(updateTourLoading(loading)),
    [dispatch]
  );
  const handleStepIndex = useCallback(
    (stepIndex) => dispatch(updateTourStepIndex(stepIndex)),
    [dispatch]
  );
  const handleTourType = useCallback(
    (type) => dispatch(updateTourType(type)),
    [dispatch]
  );

  const handleTourCallback = useCallback(
    async (data) => {
      const { action, index, type } = data;

      if (type === "step:after" && index === 0) {
        handleStepIndex(1);
      } else if (type === "step:after" && index === 1) {
        handleRunTour(false);
        navigate(`${$Routes.agendaDetailPage}/640a1b0b69b7086d55f776e0`);

        if (action === "next") {
          handleRunTour(true);
        }
      } else if (type === "step:after" && action === "next") {
        handleStepIndex(index + 1);
      } else if (type === "tour:end") {
        try {
          handleTourLoading(true);

          await updateOnboarding({ host: true });
          updateOnboardingProcess({
            email: profile?.email,
            step: STEPS.Three,
            onboardingComplete: new Date(),
          });
        } catch (error) {
          console.log(error);
        } finally {
          handleTourLoading(false);
          dispatch(updateAgendas({ _id: TOUR_SAMPLE_AGENDA._id }));
          navigate($Routes.allAgendaPage);
          handleRunTour(false);
        }
      }
    },
    [
      handleStepIndex,
      handleRunTour,
      navigate,
      dispatch,
      updateOnboarding,
      profile?.email,
      handleTourLoading,
      updateOnboardingProcess,
    ]
  );

  return {
    isHostTour,
    runTour,
    stepIndex,
    handleRunTour,
    handleStepIndex,
    handleTourCallback,
    handleTourType,
    isTourLoading,
  };
};

export default useTour;
