import { useCallback } from "react";
import { useToast, Text } from "@chakra-ui/react";

const useToastNotification = () => {
  const toast = useToast();

  const generateToast = useCallback(
    (message, type, position, styles, duration = 5000) => {
      toast({
        position,
        description: message,
        status: type,
        isClosable: true,
        duration,
        containerStyle: styles,
      });
    },
    [toast]
  );

  const generateMessage = (message) => (
    <Text mr="5" lineHeight="1.5">
      {message}
    </Text>
  );

  const showSuccessToast = (message) =>
    generateToast(message, "success", "top");
  const showErrorToast = (message) => generateToast(message, "error", "top");
  const showInfoToast = (message) =>
    generateToast(generateMessage(message), "info", "top", {}, null);

  return { showSuccessToast, showErrorToast, showInfoToast };
};

export default useToastNotification;
