import { useRef } from "react";
import { useToastNotification } from "@hooks";
import { ACCEPTED_FILE_FORMATS } from "@constants";
import {
  Flex,
  Box,
  Text,
  VisuallyHiddenInput,
  IconButton,
  Icon,
  Button,
  VStack,
  chakra,
  DarkMode,
} from "@chakra-ui/react";
import { Avatar } from "@components";
import { BiTrash } from "react-icons/bi";

const ProfileAvatarSettings = ({
  color,
  avatarPreview,
  setAvatarPreview,
  setIsSaveDisabled,
}) => {
  const fileInputRef = useRef(null);
  const { showErrorToast } = useToastNotification();
  const buttonText = !avatarPreview?.length ? "Add" : "Update";

  const handleAddImage = () => {
    fileInputRef.current.click();
  };

  const handleUploadImage = (e) => {
    const fileInput = e.target;

    if (!fileInput.files.length) return;

    const reader = new FileReader();
    reader.onloadend = (e) => setAvatarPreview(e.target.result);

    if (fileInput.files[0].size > 614400) {
      showErrorToast("Please ensure file size is less than 600kb");
    } else {
      reader.readAsDataURL(fileInput.files[0]);
      setIsSaveDisabled(false);
    }
  };

  const handleRemoveImage = () => {
    setAvatarPreview("");
    setIsSaveDisabled(false);
  };

  return (
    <Flex direction="column" alignItems="center">
      <VStack spacing="3">
        <VisuallyHiddenInput
          ref={fileInputRef}
          type="file"
          id="fileInput"
          name="fileInput"
          accept={ACCEPTED_FILE_FORMATS}
          onChange={handleUploadImage}
        />
        <Avatar avatar={avatarPreview} size="lg" rounded={true} color={color} />
        <Flex>
          <DarkMode>
            <Button
              mr="1"
              size="xs"
              variant="secondary"
              onClick={handleAddImage}
            >
              {`${buttonText} Picture`}
            </Button>
            <IconButton
              size="xs"
              variant="secondary"
              icon={<Icon as={BiTrash} color="purple.300" />}
              isDisabled={!avatarPreview}
              onClick={handleRemoveImage}
            />
          </DarkMode>
        </Flex>
        <Box
          bg="dark.800"
          p="3"
          borderRadius="8px"
          border="1px solid"
          borderColor="dark.400"
        >
          <Text color="dark.100" fontSize="12px" fontWeight="400">
            *Upload a jpg or png image less than{" "}
            <chakra.span color="blue.500" fontStyle="italic">
              600kb
            </chakra.span>
          </Text>
        </Box>
      </VStack>
    </Flex>
  );
};

export default ProfileAvatarSettings;
