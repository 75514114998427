import React from "react";
import { AgendaModal } from "@components";
import { useAgendaActions } from "@hooks";

const DeleteAgendaModal = ({ isOpen, onClose, agendaId }) => {
  const { deleteAgenda: { isLoading, handleDeleteAgenda } } = useAgendaActions();
  return (
    <AgendaModal
      variant="delete"
      title="Delete Agenda"
      body="Are you sure you want to delete this agenda?"
      isOpen={isOpen}
      isLoading={isLoading}
      onClose={() => onClose(false)}
      handlePrimaryAction={() => handleDeleteAgenda(agendaId, () => onClose(false))}
      primaryButtonText="Delete"
      primaryButtonVariant="error"
      handleSecondaryAction={() => onClose(false)}
    />
  )
}

export default DeleteAgendaModal;
