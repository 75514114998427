import { useState } from "react";
import {
  Button,
  Heading,
  Image,
  VStack,
  Text,
  Box,
  Checkbox,
  chakra,
} from "@chakra-ui/react";
import { Modal } from "@components";
import { saveToLocalStorage } from "@utils/localStorage";
import { ENGAGEMENT_MODAL_HIDDEN_TOKEN_NAME } from "@constants";
import engagementIcon from "@assets/svg/icon-engagement.svg";
import confettiBgImage from "@assets/images/confetti.gif";

const EngagementModal = ({ isOpen, onClose }) => {
  const [checked, setChecked] = useState(false);

  const handleCheckboxChange = () => {
    const newCheckedState = !checked;
    setChecked(newCheckedState);
    saveToLocalStorage(ENGAGEMENT_MODAL_HIDDEN_TOKEN_NAME, newCheckedState);
  };

  return (
    <Modal
      size="xs"
      isOpen={isOpen}
      closeOnOverlayClick={false}
      onClose={onClose}
      showCloseButton
    >
      <VStack
        spacing="3"
        mb="3"
        bg={`url(${confettiBgImage}) no-repeat center center / cover`}
      >
        <Image src={engagementIcon} />
        <Heading color="dark.600" fontWeight="700" fontSize="md">
          Engage to Score!
        </Heading>
        <Box
          background="gray.25"
          borderWidth="1px"
          borderColor="gray.100"
          borderRadius="md"
          p="3"
        >
          <VStack spacing="2">
            <Text fontSize="xs" color="dark.400" textAlign="center">
              Watch your <chakra.span color="success">E-Score</chakra.span>{" "}
              skyrocket by engaging in the meeting!
            </Text>
            <Text fontSize="xs" color="dark.400" textAlign="center">
              How many points can you earn?
            </Text>
            <Button variant="primary" w="full" size="sm" onClick={onClose}>
              Let's Play
            </Button>
            <Checkbox
              variant="settings"
              size="sm"
              isChecked={checked}
              onChange={handleCheckboxChange}
              colorScheme="purple"
            >
              Do not show this again
            </Checkbox>
          </VStack>
        </Box>
      </VStack>
    </Modal>
  );
};

export default EngagementModal;
