import { Text, Icon } from "@chakra-ui/react";
import { EPulseChartMessageLoader } from "@components";
import { BsFillInfoSquareFill } from "react-icons/bs";

const EPulseChartMessage = ({ agendaStarted, ePulseDataIsShowing }) => {
  if (!agendaStarted) {
    return (
      <Text
        textAlign="center"
        color="gray.600"
        fontSize="x-small"
        position="absolute"
        top="50%"
      >
        <Icon as={BsFillInfoSquareFill} color="gray.600" mr={1} boxSize="3" />
        Click "Start" to track attendee engagement
      </Text>
    );
  }

  if (!ePulseDataIsShowing) {
    return (
      <EPulseChartMessageLoader ePulseDataIsShowing={ePulseDataIsShowing} />
    );
  }

  return null;
};

export default EPulseChartMessage;
