import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { ChakraProvider } from "@chakra-ui/react";
import customTheme from "./utils/theme";
import store from "./store";
import { GoogleOAuthProvider } from "@react-oauth/google";

import "@fontsource/inter/400.css";
import "@fontsource/inter/600.css";
import "@fontsource/inter/800.css";

ReactDOM.render(
  <Provider store={store}>
    <ChakraProvider theme={customTheme}>
      <GoogleOAuthProvider
        clientId={process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID}
      >
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </GoogleOAuthProvider>
    </ChakraProvider>
  </Provider>,
  document.getElementById("root")
);

reportWebVitals();
