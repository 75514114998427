import { useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { isWebUser } from "@utils/helpers";
import { useAuth } from "@hooks";
import { useUpdateIsFirstViewMutation } from "@store/api/auth";
import { updateRunTour, updateTourStepIndex } from "@store/tourSlice";

// TEMPORARY: This hook and accompanying <UserOnboarding> component will be removed and the tour component will be used
const useUserOnboarding = () => {
  const dispatch = useDispatch();
  const { profile } = useAuth();
  const [updateIsFirstView, { isLoading: updatingIsFirstView }] =
    useUpdateIsFirstViewMutation();
  const isFirstView = useSelector((state) => state.profile?.data?.isFirstView);
  const stepIndex = useSelector((state) => state.tour.stepIndex);
  const runTour = useSelector((state) => state.tour.runTour);

  const handleTooltipCallback = useCallback(() => {
    if (!isFirstView) return;

    if ((stepIndex === 2 && isWebUser(profile)) || stepIndex === 3) {
      updateIsFirstView({ isFirstView: false });
      dispatch(updateRunTour(false));
      return;
    }

    dispatch(updateTourStepIndex(stepIndex + 1));
  }, [dispatch, isFirstView, stepIndex, profile, isWebUser]);

  return {
    runTour,
    stepIndex,
    updatingIsFirstView,
    handleTooltipCallback,
  };
};

export default useUserOnboarding;
