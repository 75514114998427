import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  emitGetAnalytics,
  receiveEPulse,
} from "@socket";

const useSocketConnections = () => {
  const dispatch = useDispatch();
  const [triggerLiveAnaltyics, setTriggerLiveAnaltyics] = useState(false);
  const [isLive, setIsLive] = useState(false);
  const meeting = useSelector((state) => state.meeting.data?.meetingObject);
  const profile = useSelector((state) => state.profile.data);

  useEffect(() => {
    let interval;

    if (meeting && profile) {
      emitGetAnalytics({
        userId: profile?._id,
        meetingUuid: meeting.meetingInstances?.[0]?.meetingUuid,
      });

      interval = setInterval(() => {
        emitGetAnalytics({
          userId: profile?._id,
          meetingUuid: meeting.meetingInstances?.[0]?.meetingUuid,
        });
      }, 15000);
    }

    return () => {
      clearInterval(interval);
    };
  }, [dispatch, meeting, profile]);

  return {
    setTriggerLiveAnaltyics,
    receiveEPulse,
    setIsLive,
  };
};

export default useSocketConnections;
