import React, { useEffect } from "react";
import { ActivityEmptyState } from "@components";
import { useSelector, useDispatch } from "react-redux";
import { ActivityCard } from "@components/Activity";
import { checkForLiveActivity } from "@store/activitySlice";
import { Draggable } from "react-beautiful-dnd";

const ActivityList = ({ agenda, changeViewData, setActivityType }) => {
  const dispatch = useDispatch();
  const activities = useSelector((state) => state.activity.activities);

  useEffect(() => {
    dispatch(checkForLiveActivity());
  }, [dispatch]);

  return (
    <>
      {activities?.length ? (
        activities.map((activity, index) => (
          <Draggable
            key={activity._id}
            draggableId={activity._id}
            index={index}
          >
            {(provided) => (
              <div
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
              >
                <ActivityCard
                  key={activity._id}
                  activity={activity}
                  agenda={agenda}
                  changeViewData={changeViewData}
                  setActivityType={setActivityType}
                />
              </div>
            )}
          </Draggable>
        ))
      ) : (
        <ActivityEmptyState changeViewData={changeViewData} />
      )}
    </>
  );
};

export default ActivityList;
