import { Box } from "@chakra-ui/react";
import { HostView } from "@components"
import { NotFoundError, ZoomAppAuthFailed } from "@components/Errors";
import {
  Login,
  ZoomInstallation,
  Register,
  ConfirmEmail,
  ResetPassword,
  NewPassword,
  CheckEmail,
  VerifyEmail,
  Onboarding,
  Setup,
  AccountView,
  Home,
  MeetingView,
  AgendaView,
  BillingView,
  EditProfile,
  WebUserJoinMeeting,
  SelectUserType,
  SendToolRedirect
} from "@routes/lazyImports";
import { getRouteNames, removeExcludedRoutes } from "./utils";
import { APP_TYPE } from "@constants";

export const routeConfigurations = {
  rootPage: {
    component: Home,
    requiresAuthentication: true,
    conditionalRender: SelectUserType,
    path: "/"
  },
  homePage: {
    component: Home,
    requiresAuthentication: true,
    path: "/home"
  },
  loginPage: {
    component: Login,
    path: "/login",
    exclude: [APP_TYPE.ZoomApp]
  },
  zoomInstallationPage: {
    component: ZoomInstallation,
    path: "/zoomapp"
  },
  registerPage: {
    component: Register,
    path: "/register",
    exclude: [APP_TYPE.ZoomApp]
  },
  confirmEmailPage: {
    component: ConfirmEmail,
    path: "/confirm-email/:email",
    exclude: [APP_TYPE.ZoomApp]
  },
  resetPasswordPage: {
    component: ResetPassword,
    path: "/reset-password",
    exclude: [APP_TYPE.ZoomApp]
  },
  checkEmailPage: {
    component: CheckEmail,
    path: "/check-email",
    exclude: [APP_TYPE.ZoomApp]
  },
  createPasswordPage: {
    component: NewPassword,
    path: "/create-password",
    exclude: [APP_TYPE.ZoomApp]
  },
  setupPage: {
    component: Setup,
    requiresAuthentication: true,
    path: "/setup"
  },
  accountPage: {
    component: AccountView,
    requiresAuthentication: true,
    path: "/account/*",
    subPages: {
      editProfilePage: {
        path: "/edit-profile",
        component: EditProfile
      },
      billingPage: {
        path: "/billing",
        component: BillingView
      }
    }
  },
  onboardingPage: {
    component: Onboarding,
    requiresAuthentication: true,
    path: "/onboarding"
  },
  verifyAccountPage: {
    component: VerifyEmail,
    path: "/account/verify",
    exclude: [APP_TYPE.ZoomApp]
  },
  meetingPage: {
    component: MeetingView,
    requiresAuthentication: true,
    path: "/meetingview"
  },
  webUserJoinMeetingPage: {
    component: WebUserJoinMeeting,
    requiresAuthentication: true,
    path: "/meeting"
  },
  proPage: {
    component: HostView,
    requiresAuthentication: true,
    isPro: true,
    path: "/pro",
    exclude: [APP_TYPE.WebApp]
  },
  allAgendaPage: {
    component: HostView,
    requiresAuthentication: true,
    isPro: true,
    path: "/pro/agendas",
    exclude: [APP_TYPE.WebApp]
  },
  agendaDetailPage: {
    component: AgendaView,
    requiresAuthentication: true,
    isPro: true,
    path: "/pro/agendas/:agendaId",
    exclude: [APP_TYPE.WebApp]
  },
  zoomAppAuthorizationFailedPage: {
    component: ZoomAppAuthFailed,
    exclude: [APP_TYPE.WebApp],
    path: "/error/authorization",
  },
  sendToolRedirectPage: {
    component: SendToolRedirect,
    container: Box,
    path: "/send-link",
    exclude: [APP_TYPE.ZoomApp]
  },
  errorPage: {
    component: NotFoundError,
    path: "*",
  },
};

export const routeConfig = removeExcludedRoutes(routeConfigurations);

export const $Routes = getRouteNames(routeConfigurations);
