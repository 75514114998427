import { Link as ReactLink, useNavigate } from "react-router-dom";
import {
  Text,
  IconButton,
  Flex,
  Badge,
  Circle,
  ListItem,
  Link,
  Divider,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Portal,
} from "@chakra-ui/react";
import { $Routes } from "@routes";
import { BiTrash } from "react-icons/bi";
import { FiCopy } from "react-icons/fi";
import { FaChartLine } from "react-icons/fa";
import { IoEllipsisVertical } from "react-icons/io5";

const spanStyle = {
  position: "absolute",
  width: "75%",
  height: "100%",
  top: 0,
  bottom: 0,
  zIndex: 1,
};

const AgendaListItem = ({
  id,
  index,
  title,
  live,
  isPastAgenda,
  setAgendaId,
  agendaLength,
  setAgendaDeleteModalOpen,
  setAgendaDuplicateModalOpen,
  onMenuToggle,
}) => {
  const navigate = useNavigate();

  const handleDeleteAgenda = (id) => {
    setAgendaDeleteModalOpen(true);
    setAgendaId(id);
  };

  const handleDuplicateAgenda = (id) => {
    setAgendaDuplicateModalOpen(true);
    setAgendaId(id);
  };

  return (
    <>
      <ListItem
        p="2"
        cursor="pointer"
        _hover={{
          backgroundColor: "gray.50",
        }}
      >
        <Flex
          alignItems="center"
          justifyContent="space-between"
          position="relative"
        >
          <Link
            as={ReactLink}
            to={`${$Routes.agendaDetailPage}/${id}`}
            color="dark.400"
          >
            <Text fontSize="12px" isTruncated={true} maxW="90px">
              {title}
            </Text>
            <span style={spanStyle} />
          </Link>
          {!isPastAgenda && live && (
            <Badge variant="active">
              <Flex alignItems="center" justifyContent="center">
                <Circle mr="1" size="1" bg="green.500" />
                Active
              </Flex>
            </Badge>
          )}
          <Flex alignItems="flex-end">
            <Menu
              onOpen={() => onMenuToggle(true)}
              onClose={() => onMenuToggle(false)}
            >
              <MenuButton
                as={IconButton}
                aria-label="Options"
                icon={<IoEllipsisVertical />}
                variant="ghost"
                size="sm"
              />
              <Portal>
                <MenuList fontSize="xs">
                  {isPastAgenda ? (
                    <MenuItem
                      color="green.600"
                      icon={<FaChartLine />}
                      onClick={() =>
                        navigate(`${$Routes.agendaDetailPage}/${id}`, {
                          state: "full-report",
                        })
                      }
                    >
                      Full Report
                    </MenuItem>
                  ) : null}
                  <MenuItem
                    icon={<FiCopy />}
                    onClick={() => handleDuplicateAgenda(id)}
                  >
                    Copy
                  </MenuItem>
                  {!isPastAgenda ? (
                    <MenuItem
                      icon={<BiTrash />}
                      onClick={() => handleDeleteAgenda(id)}
                    >
                      Delete
                    </MenuItem>
                  ) : null}
                </MenuList>
              </Portal>
            </Menu>
          </Flex>
        </Flex>
      </ListItem>
      {index !== agendaLength - 1 ? <Divider /> : null}
    </>
  );
};

export default AgendaListItem;
