import React, { useCallback, useMemo } from "react";
import {
  Flex,
  IconButton,
  Image,
  Icon,
  Heading,
  Tooltip,
  Box,
  useDisclosure,
} from "@chakra-ui/react";
import { IoClose } from "react-icons/io5";
import { useFormik, Field, FormikProvider } from "formik";
import { useSelector, useDispatch } from "react-redux";
import { updateSelectedActivity } from '@store/activitySlice';
import {
  useCreateActivityMutation,
  useUpdateActivityMutation,
  useDeleteActivityMutation,
} from "@store/api/activities";
import { Card } from "@components";
import { SingleActivityFooter, ActivityEditableInput } from "@components/Activity";
import {
  activityIcons,
  defaultActivityOptions,
  activityTitles,
  defaultActivitySettings
} from "@constants/activities";
import { getActivityParts } from "@components/Activity/helpers";
import { useToastNotification } from "@hooks";
import { FaRegQuestionCircle } from "react-icons/fa";

const sharedInputProps = {
  fontSize: "12px",
  border: "1px solid #F5F5F7",
  borderRadius: "6px",
  _hover: {
    borderColor: "#dad1f3",
  },
  _focus: {
    borderColor: "#dad1f3",
  },
  _placeholder: {
    color: "gray.500",
  },
};

const SingleActivity = ({
  changeViewData,
  activityType,
  agendaId,
}) => {
  const activity = useSelector((state) => state.activity.selectedActivity)
  const activities = useSelector((state) => state.activity.activities)
  const isLoading = useSelector((state) => state.activity.isLoading)
  
  const dispatch = useDispatch();
  const { onClose, onOpen, isOpen } = useDisclosure();
  const [createActivity, { isLoading: isCreatingActivity }] = useCreateActivityMutation();
  const [updateActivity, { isLoading: isUpdatingActivity }] = useUpdateActivityMutation();
  const [deleteActivity,  { isLoading: isDeletingActivity }] = useDeleteActivityMutation();
  const { showSuccessToast, showErrorToast } = useToastNotification()

  const activityPartsObject = getActivityParts(activityType)
  const OptionsComponent = activityPartsObject.options;
  const SettingsComponent = activityPartsObject.settings;
  const TooltipComponent = activityPartsObject.optionTooltipLabel;
  const TitleComponent = activityPartsObject.titleField;
  const inputPlaceholder = activityPartsObject.placeholder;
  
  const { setView, views } = changeViewData;
  const activityTypeLength = activities.filter((act) => act.type === activityType).length;
  const hasActivity = !!Object.keys(activity).length;

  const formik = useFormik({
    initialValues: {
      displayName: hasActivity ? activity.displayName : `${activityTitles[activityType]} (${activityTypeLength + 1})`,
      title: hasActivity ? activity.title : "",
      settings: hasActivity ? activity.settings : defaultActivitySettings[activityType],
      options: hasActivity ? activity.options : defaultActivityOptions[activityType],
    },
    enableReinitialize: true,
    validationSchema: activityPartsObject.schema,
  });

  const { handleChange, values, errors, touched, handleBlur } = formik;

  const activityBody = useMemo(() => ({
    agendaId,
    type: activityType,
    displayName: values.displayName,
    title: values.title,
    options: values.options,
    settings: values.settings,
  }), [
    activityType,
    agendaId,
    values.displayName,
    values.options,
    values.settings,
    values.title
  ]);

  const isErrorFree =
    Object.keys(errors).length === 0 &&
    Object.values(values).every((val) => val);

  const handleSave = useCallback(async () => {
    try {
      if (hasActivity) {
        await updateActivity({ activityId: activity._id, body: activityBody }).unwrap();
        showSuccessToast(`${activityTitles[activityType]} updated successfully`)
        return setView(views.list);
      }

      await createActivity(activityBody).unwrap();
      showSuccessToast(`${activityTitles[activityType]} created successfully`)
      return setView(views.list)
    } catch (error) {
      showErrorToast(`${error?.data?.message}: Error creating ${activityTitles[activityType]}`);
    }
  }, [
    activityType,
    activityBody,
    activity._id,
    hasActivity,
    setView,
    views.list,
    showSuccessToast,
    showErrorToast,
    createActivity,
    updateActivity,
  ]);

  const handleDelete = useCallback(async (id) => {
    try {
      await deleteActivity(id).unwrap()
      onClose();
      showSuccessToast(`${activityTitles[activityType]} deleted successful`);
      return setView(views.list);
    } catch (error) {
      showErrorToast(`${activityTitles[activityType]} delete unsuccessful`)
    }
  }, [
    activityType,
    deleteActivity,
    onClose,
    setView,
    showErrorToast,
    showSuccessToast,
    views.list
  ]);

  return (
    <Card
      p={3}
      borderRadius="10px 10px 0 0"
      footer={
        <SingleActivityFooter
          activity={activity}
          isDisabled={!isErrorFree}
          handleSave={handleSave}
          isLoading={isLoading}
          isSavingActivity={isCreatingActivity || isUpdatingActivity}
          isDeletingActivity={isDeletingActivity}
          handleDelete={handleDelete}
          handleModalOpen={onOpen}
          handleModalClose={onClose}
          isModalOpen={isOpen}
          activityTitle={activityTitles[activityType]}
        />
      }
    >
      <Flex justifyContent="space-between">
        <Flex alignItems="center" mb={1.5}>
          <Image mr={1} h="6" w="6" src={activityIcons[activityType]} />
          <ActivityEditableInput
            name="displayName"
            value={values.displayName}
            onChange={handleChange}
          />
        </Flex>
        <IconButton
          aria-label="Close"
          icon={<Icon as={IoClose} color="dark.100" />}
          minWidth="0"
          height={5}
          width={5}
          p={1}
          onClick={() => {
            dispatch(updateSelectedActivity({ activity: {} }))
            setView(views.list)
          }}
        />
      </Flex>
      {TitleComponent && (
        <Box alignItems="flex-start">
          <FormikProvider value={formik}>
            <Field
              name="title"
              as={TitleComponent}
              placeholder={inputPlaceholder}
              onChange={handleChange}
              value={values.title}
              onBlur={handleBlur}
              isInvalid={!!(touched.title && errors.title)}
              {...sharedInputProps}
            />
          </FormikProvider>
        </Box>
      )}
      {activityPartsObject.options && (
        <Box mt={3}>
          <Flex alignItems="center" mb={1.5}>
            <Heading
              as="h4"
              fontWeight="600"
              fontSize="12px"
              color="dark.300"
              mr="1"
            >
              Options
            </Heading>
            {activityPartsObject.optionTooltipLabel && (
              <Tooltip
                placement="top"
                hasArrow
                shouldWrapChildren
                label={<TooltipComponent />}
                bg="gray.900"
                color="white"
                fontSize="12px"
                fontWeight="500"
                borderRadius="5px"
              >
                <Icon as={FaRegQuestionCircle} color="gray.500" w={3} h={3} />
              </Tooltip>
            )}
          </Flex>
          <OptionsComponent formik={formik} sharedInputProps={sharedInputProps} />
        </Box>
      )}
      {activityPartsObject.settings && (
        <Box mt={3}>
          <Heading as="h4" fontWeight="600" fontSize="12px" color="dark.300" mb={1.5}>
            Settings
          </Heading>
          <SettingsComponent formik={formik} sharedInputProps={sharedInputProps}
          />
        </Box>
      )}
    </Card>
  )
}

export default SingleActivity;
