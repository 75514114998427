import React from "react";
import { EmptyState } from "@components";
import activityEmptyImage from "@assets/svg/activity-session-empty.svg";

const ActivityEmptyState = ({ changeViewData }) => {
  const { views, setView } = changeViewData;
  return (
    <EmptyState
      container="true"
      containerProps={{
        mt: 0,
      }}
      imgSrc={activityEmptyImage}
      text="Your activity sessions will appear here."
      textProps={{
        mt: "12px",
        fontSize: "12px",
        fontWeight: "400",
        color: "dark.200",
        textAlign: "center",
      }}
    />
  );
};

export default ActivityEmptyState;
