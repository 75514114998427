import { Text, Flex, Box } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { $Routes } from "@routes";

export const NotFoundError = () => {
  return (
    <Flex justifyContent="center" alignItems="center" height="100%">
      <Flex
        flexDirection="column"
        alignItems="center"
        width="80%"
        zIndex="0"
        position="relative"
      >
        <Box layerStyle="guestCard">
          <Text as="h2" textAlign="center" mb={2} textColor="purple.500">Page not Found</Text>
          <Text textAlign="center" textColor="gray.800" fontSize="sm">
            The requested page could not be found. Please check the URL or navigate back to the
            {" "}
            <Link to={$Routes.homePage}><Text as="centerspan" textColor="purple.500" fontSize="sm">Homepage</Text></Link>
          </Text>
        </Box>
      </Flex>
    </Flex>
  )
}
