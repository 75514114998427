import { sendSocketEvent } from "@socket/utils";

export const emitSendIcon = (data) => sendSocketEvent("sendIcon", data);

export const emitIconUsage = (data) => sendSocketEvent("icon.handleUsage", data);

export const emitOnMyReaction = (data) => sendSocketEvent("onMyReaction", data);

export const emitOnMyActiveSpeakerChange = (data) => sendSocketEvent("onMyActiveSpeakerChange", data);

export const emitOnMyMediaChange = (data) => sendSocketEvent("onMyMediaChange", data);

export const emitGetAnalytics = ({ userId, meetingUuid }) => sendSocketEvent("getAnalytics", {
  userId,
  meetingUuid,
});

export const emitRemoveWebUserFromMeeting = ({
  userId,
  platformMeetingInstanceId
}) => sendSocketEvent("onRemoveWebUserFromMeeting", {
  userId,
  platformMeetingInstanceId,
});

export const emitOnUserRoleChange = (data) => sendSocketEvent("onUserRoleChange", data);
