import { Input as ChakraInput } from "@chakra-ui/react";

const Input = ({ placeholder, value, size, onChange, ...restProps }) => {
  return (
    <ChakraInput
      size={size}
      fontSize="12px"
      border="1px solid #F5F5F7"
      borderRadius="8px"
      _hover={{
        borderColor: "#dad1f3",
      }}
      _focus={{
        borderColor: "#dad1f3",
      }}
      _placeholder={{
        color: "gray.500",
      }}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      {...restProps}
    />
  );
};

export default Input;
