import { Progress } from "@chakra-ui/react";

const FullPageLoader = () => (
      <Progress
        width="15%"
        bgGradient="linear-gradient(to left, #8912CA, #3E00EA)"
        size="xs"
        borderRadius="50px"
        isIndeterminate
      />
);

export default FullPageLoader;
