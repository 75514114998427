import React from "react";
import { useSelector } from "react-redux";
import { Text, Skeleton } from "@chakra-ui/react";
import { useGetAgendaReportQuery } from "@store/api/report";
import { HostCard, LastTownhallReport, FirstTimerViewSampleReport } from "@components";

const responseFields = [ "eScore", "averageAttendance", "boost", "agendaStartTime"];

const WelcomeSection = ({ firstName, userId }) => {
  const { isLoading } = useGetAgendaReportQuery({ 
    userId,
    recent: true,
    include: responseFields
  });
  const lastTownhall = useSelector((state) => state.report.lastTownhall);

  const welcomeSectionContent = lastTownhall.agendaStartTime
    ? <LastTownhallReport lastTownhall={lastTownhall} />
    : <FirstTimerViewSampleReport />;

  return (
    <HostCard>
      <Text
        as="h3"
        fontSize="sm"
        fontWeight="600"
        mb={1}
      >
       {`Welcome ${firstName || "User"}!`}
      </Text>
      {
        isLoading ? <Skeleton w="100%" height="30px" /> : welcomeSectionContent
      }
    </HostCard>
  )
}

export default WelcomeSection;
