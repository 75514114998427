import { useState, useEffect, useMemo, useCallback } from "react";
import { useDispatch } from "react-redux";
import { useCheckExistingMeetingMutation } from "@store/api/meeting";
import { updatePlatformParticipantUuid } from "@store/profileSlice";
import { useFetchProfileQuery } from "@store/api/auth";
import { isZoomApp } from "@utils/helpers";
import { saveDataToLocalStorage } from "@store/customActions";
import { CONTEXT_STATUS, APP_TYPE, JWT_TOKEN_NAME } from "@constants";
import {
  configZoomSdk,
  zoomPromptAuthorize,
  authorize,
  getMeetingUuidAndUserContext,
  getZoomMediaState,
} from "@sdk/zoom/utils";
import {
  onAuthorizedEvent,
  onMyActiveSpeakerChangeEvent,
  onMyReactionEvent,
  onMyMediaChangeEvent,
  onMyUserContextChangeEvent,
  onRunningContextChangeEvent,
} from "@sdk/zoom/eventListeners";
import { emitOnMyMediaChange } from "@socket";
import { useMeeting, useProfile, useToastNotification } from "@hooks";

const useZoomSdk = () => {
  const [sdkConfigured, setSdkConfigured] = useState(false);
  const [token, setToken] = useState();
  const [userContextStatus, setUserContextStatus] = useState();
  const dispatch = useDispatch();
  const [checkExistingMeeting] = useCheckExistingMeetingMutation();
  const { refetch } = useFetchProfileQuery(null, {
    skip: !token,
  });
  const { showInfoToast } = useToastNotification();
  const { meetingInstanceId } = useMeeting();
  const {
    profileId,
    platformParticipantUuid: platformParticipantUuidFromStore,
  } = useProfile();
  const hasNoProfileOrConfiguredSdk = useMemo(
    () => !profileId || !sdkConfigured,
    [profileId, sdkConfigured]
  );
  const hasIncompleteData = useMemo(
    () => hasNoProfileOrConfiguredSdk || !meetingInstanceId,
    [hasNoProfileOrConfiguredSdk, meetingInstanceId]
  );
  const isAuthorized = useMemo(
    () => userContextStatus && userContextStatus === CONTEXT_STATUS.AUTHORIZED,
    [userContextStatus]
  );

  const updateMeetingStatus = useCallback(
    (platformParticipantUuid, platformMeetingInstanceId) => {
      checkExistingMeeting({
        platformParticipantUuid: platformParticipantUuid ?? null,
        platformMeetingInstanceId: platformMeetingInstanceId ?? null,
      });
      dispatch(updatePlatformParticipantUuid(platformParticipantUuid ?? null));
    },
    [checkExistingMeeting, dispatch]
  );

  const getMeetingStatus = useCallback(async () => {
    if (!profileId || !sdkConfigured) return;

    try {
      const { platformParticipantUuid, platformMeetingInstanceId } =
        await getMeetingUuidAndUserContext();
      updateMeetingStatus(platformParticipantUuid, platformMeetingInstanceId);
    } catch (error) {
      updateMeetingStatus();
    }
  }, [updateMeetingStatus, sdkConfigured, profileId]);

  useEffect(() => {
    if (isZoomApp && !sdkConfigured) {
      const configureSdk = async () => {
        const configResponse = await configZoomSdk();
        setSdkConfigured(true);
        setUserContextStatus(configResponse.auth.status);
      };
      configureSdk();
    }
  }, [sdkConfigured]);

  useEffect(() => {
    if (sdkConfigured && userContextStatus && !isAuthorized) {
      zoomPromptAuthorize();
    }
  }, [sdkConfigured, userContextStatus, isAuthorized]);

  useEffect(() => {
    if (sdkConfigured && !isAuthorized && !token) {
      try {
        authorize();
      } catch (e) {
        console.error(e);
      }
    }
  }, [sdkConfigured, token, isAuthorized]);

  useEffect(() => {
    if (!sdkConfigured) return;

    onAuthorizedEvent.addEventListener({
      onSuccessCallback: (response) => {
        dispatch(
          saveDataToLocalStorage({
            key: JWT_TOKEN_NAME,
            value: response.accessToken,
          })
        );
        setToken(response.accessToken);
        refetch();
      },
      onErrorCallback: () => {},
    });

    onRunningContextChangeEvent.addEventListener({
      onSuccessCallback: getMeetingStatus,
    });

    return () => {
      onAuthorizedEvent.removeEventListener();
      onRunningContextChangeEvent.removeEventListener();
    };
  }, [refetch, dispatch, getMeetingStatus, sdkConfigured]);

  useEffect(() => {
    if (hasIncompleteData) return;

    const data = { meetingInstanceId, userId: profileId };

    onMyReactionEvent.addEventListener(data);
    onMyActiveSpeakerChangeEvent.addEventListener(data);
    onMyMediaChangeEvent.addEventListener(data);
    onMyUserContextChangeEvent.addEventListener({
      onSuccessCallback: (event) => {
        setSdkConfigured(false);
        setUserContextStatus();

        if (event.role === "coHost") {
          showInfoToast(
            "You are now a Co-Host. You can now run PRO features (E-Pulse, E-Report, etc.) for this meeting."
          );
        }
      },
      userMeetingInfo: {
        meetingInstanceId,
        platformParticipantUuid: platformParticipantUuidFromStore,
      },
    });

    return () => {
      onMyReactionEvent.removeEventListener();
      onMyActiveSpeakerChangeEvent.removeEventListener();
      onMyMediaChangeEvent.removeEventListener();
      onMyUserContextChangeEvent.removeEventListener();
    };
  }, [
    meetingInstanceId,
    profileId,
    hasIncompleteData,
    platformParticipantUuidFromStore,
    getMeetingStatus,
  ]);

  useEffect(() => {
    if (hasNoProfileOrConfiguredSdk) return;
    getMeetingStatus();
  }, [hasNoProfileOrConfiguredSdk, getMeetingStatus]);

  useEffect(() => {
    if (hasIncompleteData) return;

    const defaultMediaChangeData = {
      meetingInstanceId,
      audioOn: false,
      videoOn: false,
      userId: profileId,
    };

    const getMediaState = async () => {
      try {
        const { audioOn, videoOn } = await getZoomMediaState();
        emitOnMyMediaChange({
          ...defaultMediaChangeData,
          audioOn,
          videoOn,
        });
      } catch (error) {
        emitOnMyMediaChange(defaultMediaChangeData);
      }
    };

    getMediaState();
  }, [sdkConfigured, profileId, meetingInstanceId, hasIncompleteData]);

  return {
    isAuthorized,
    sdkConfigured,
    userContextStatus,
    appType: APP_TYPE.ZoomApp,
  };
};

export default useZoomSdk;
