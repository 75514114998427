import React from "react";
import { useSelector } from "react-redux";
import { Box, Text, Flex, Icon, Button, DarkMode } from "@chakra-ui/react";
import dayjs from "dayjs";
import { useSdkUtils } from "@hooks";
import { RiFlashlightFill } from "react-icons/ri";
import { LICENSE_TYPES, SUBSCRIPTION_TYPES } from "@constants";
import { isBasicUser, isDevUser, isProEnterpriseUser, isProUser, isTrialUser } from "@utils/helpers";
import { TRIAL_DAYS } from "@constants/proTrial";

const durationDisplayName = {
  day: "Daily",
  week: "Weekly",
  month: "Monthly",
  year: "Yearly",
};

const UpgradeButton = ({openUrl, isDisabled}) => (
  <Button
    size="large"
    variant="primary"
    mt={4}
    px={2}
    fontSize="sm"
    h="8"
    onClick={() => openUrl("https://www.townhall.pro/pricing")}
    isDisabled={isDisabled}
  >
    <Flex alignItems="center" gridGap="6px">
      <Icon as={RiFlashlightFill} w="16px" h="16px" />
      Upgrade
    </Flex>
  </Button>
);

const currentPlan = (
  <Box as="div" fontSize="9px" fontWeight="bold" color="green.400" borderColor="green.500" borderWidth="1px" px="4px" py="2px" borderRadius="4px">
    Current plan
  </Box>
);

const trialMessage = (
  <>
    <Text color="gray.400" fontSize="xs" mt="3">
      {`We hope you are enjoying your ${TRIAL_DAYS}-day Free Trial of Townhall Pro!`}
    </Text>
    <Text color="gray.400" fontSize="xs" mt="3">
      Upgrade now so you do not lose access to premium features like E-Pulse and E-Report.
    </Text>
  </>
);

const getLicenseTitle = (licenseType, subscriptionName) => {
  const titles = {
    [LICENSE_TYPES.Trial]: "Trial",
    [LICENSE_TYPES.Dev]: "Elite",
    [LICENSE_TYPES.Basic]: "Starter",
    [LICENSE_TYPES.Pro]: {
      [SUBSCRIPTION_TYPES.Presenter]: "Presenter",
      [SUBSCRIPTION_TYPES.Enterprise]: "Enterprise",
    },
  };

  const title = titles[licenseType];
  
  return title[subscriptionName] || title;
};

const BillingView = () => {
  const { openUrl } = useSdkUtils();
  const { subscription, licenseType } = useSelector((state) => state.profile.data);
  const { name, interval, subscriptionEnd } = subscription ?? {};

  const now = dayjs();
  const subscriptionEndDate = subscriptionEnd ? dayjs(subscriptionEnd) : null;
  const messagePart = subscriptionEndDate > now ? "renews automatically" : "ended";
  const trialUser = isTrialUser(licenseType);
  const basicUser = isBasicUser(licenseType)
  const isPaidSubscription = isProUser(licenseType);
  const devUser = isDevUser(licenseType);

  return (
    <Flex width="full" flexDirection="column" gridGap="4px">
      {(name || trialUser || basicUser || devUser) &&
        <Box bg="dark.700" borderRadius="18px" p={5}>
          <Flex alignItems="center" gridGap="16px">
            <Text as="h3" color="gray.200" fontWeight="bold" fontSize="16px">
              {`PRO ${getLicenseTitle(licenseType, name)}`}
            </Text>
            {currentPlan}
          </Flex>
          { trialUser && trialMessage}
          <UpgradeButton openUrl={openUrl} isDisabled={isProEnterpriseUser(licenseType, name) || isDevUser(licenseType)}/>
        </Box>
      }

      { isPaidSubscription && durationDisplayName[interval] && subscriptionEndDate &&
        <Box bg="dark.700" borderRadius="18px" p={5}>
          <Text as="div" fontSize="xs" fontWeight="bold" color="gray.200">
            Your{" "}
            <Text as="span" color="gray.400">
              {`${name} - ${durationDisplayName[interval]}`}
            </Text>
            {` subscription ${messagePart} on `}
            <Text color="gray.400" as="span">
              {subscriptionEndDate.format("MMM D, YYYY")}
            </Text>
          </Text>
        </Box>
      }

      { isPaidSubscription &&
        <Box bg="dark.700" borderRadius="18px" p={5}>
          <Flex justifyContent="space-between" alignItems="center">
            <Text as="h4" fontSize="14px" color="gray.200" fontWeight="semibold">Billing Info</Text>
            <DarkMode>
              <Button variant="secondary" size="sm" fontSize="xs"
                onClick={() => openUrl(process.env.REACT_APP_STRIPE_CUSTOMER_PORTAL_URL)}
              >
                Manage
              </Button>
            </DarkMode>
          </Flex>
          <Text fontSize="xs" color="gray.400">Manage your subscription</Text>
        </Box>
      }

      {!devUser && (
        <Box bg="dark.700" borderRadius="18px" p={5}>
          <Text as="div" fontSize="xs" color="gray.400">
            Not sure which plan to choose?
          </Text>
          <Button
            variant="link"
            fontSize="sm"
            color="cyan.500"
            _active={{ color: "cyan.400" }}
            onClick={() => openUrl("https://www.townhall.pro/pricing")}
          >
            Compare Plans
          </Button>
        </Box>
      )}
    </Flex>
  )
}

export default BillingView;
