const Badge = {
  variants: {
    active: {
      fontSize: "8px",
      fontWeight: "500",
      borderRadius: "4px",
      bg: "green.50",
      color: "green.700",
      textTransform: "none",
    },
  },
};

export default Badge;
