import { Image, Text, Flex } from "@chakra-ui/react";

const EngagementBox = ({
  icon,
  label,
  labelTextColor,
  labelBackgroundColor,
  count,
  inline = true,
  bg = "dark.500",
  textColor = "white",
  ...restProps
}) => {
  return (
    <>
      <Flex
        p="10px 5px"
        flexDirection={inline ? "row" : "column"}
        bg={bg}
        borderRadius="8px"
        border="2px solid"
        borderColor="dark.400"
        m="10px 5px"
        display="flex"
        justifyContent="center"
        alignItems="center"
        grow={1}
        shrink={1}
        _after={{
          position: "absolute",
          bottom: "6px",
          content: `"${label}"`,
          color: labelTextColor,
          textAlign: "center",
          borderRadius: "14px",
          lineHeight: "0",
          padding: "5px",
          fontSize: "8px",
          fontWeight: "400",
          backgroundColor: labelBackgroundColor,
          whiteSpace: "nowrap",
        }}
        {...restProps}
      >
        {icon && <Image w="16px" h="16px" src={icon} />}
        <Text fontSize="14px" color={textColor} fontWeight={600}>
          {count}
        </Text>
      </Flex>
    </>
  );
};

export default EngagementBox;
