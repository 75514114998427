import { Flex, IconButton, Box } from "@chakra-ui/react";
import { NotificationImage, NotificationText } from "@components";
import { IoClose } from "react-icons/io5";

const NotificationItem = ({
  notification,
  onClose,
  containerProps,
  buttonProps,
}) => {
  return (
    <Flex
      alignItems="center"
      justifyContent="space-between"
      gap="10px"
      p="6px 8px"
      {...containerProps}
    >
      <Box width="16px" flexShrink={0}>
        <NotificationImage {...notification} />
      </Box>
      <Box ml="8px" width="calc(100% - 48px)">
        <NotificationText {...notification} />
      </Box>
      <Box width="24px">
        <IconButton
          icon={<IoClose color={buttonProps.color} />}
          size="xs"
          onClick={onClose}
          variant={buttonProps.variant || "solid"}
          bg={buttonProps.bg || "transparent"}
          _hover={buttonProps._hover}
          _active={buttonProps._active}
        />
      </Box>
    </Flex>
  );
};

export default NotificationItem;
