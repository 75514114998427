import { Avatar } from "@components";

const InactiveAvatar = ({ id, name, color, avatar, variant, onClick }) => {
  return (
    <Avatar
      id={id}
      name={name}
      color={color}
      avatar={avatar}
      variant={variant}
      showBorder={true}
      size="lg"
      inactive={true}
      onClick={onClick}
    />
  );
};

export default InactiveAvatar;
