import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { useSdkUtils } from "@hooks";
import { showErrorToast, showSuccessToast } from "@store/customActions";

const useInvite = () => {
  const dispatch = useDispatch();
  const { inviteParticipantsToApp } = useSdkUtils();

  const handleSendZoomInvite = useCallback(async () => {
    await inviteParticipantsToApp({
      onSuccess: () =>
        dispatch(
          showSuccessToast(
            "Zoom App invite successfully sent to participants"
          )
        ),
      onError: () =>
        dispatch(
          showErrorToast("An error occured. Zoom App invite not sent")
        ),
    });
  }, [dispatch, inviteParticipantsToApp]);

  return { handleSendZoomInvite };
};

export default useInvite;
