const borderRadius = "8px";
const boxShadow = "sm";
const display = "none";
const fontSize = "12px";
const fontWeight = "500";
const color = "dark.400";
const borderWidth = "1px";

const styles = {
  container: (provided) => ({
    ...provided,
    borderRadius,
  }),
  control: (provided) => ({
    ...provided,
    borderColor: "gray.300",
    boxShadow,
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    bg: "transparent",
    px: 2,
    color: "dark.200",
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    display,
  }),
  placeholder: (provided) => ({
    ...provided,
    color: "gray.500",
    fontSize,
  }),
  option: (provided, { isSelected }) => ({
    ...provided,
    color,
    fontSize,
    fontWeight,
    backgroundColor: isSelected ? "purple.25" : "inherit",
    "&:hover": {
      backgroundColor: "gray.100",
    },
  }),
  menuList: (provided) => ({
    ...provided,
    borderWidth,
    borderColor: "gray.200",
    borderRadius,
    boxShadow,
    bg: "white",
    minHeight: "10px",
  }),
  groupHeading: (provided) => ({
    ...provided,
    bg: "purple.25",
    color: "dark.500",
    fontSize,
    fontWeight: 400,
  }),
  clearIndicator: (provided) => ({
    ...provided,
    display,
  }),
  multiValue: (provided) => ({
    ...provided,
    borderWidth,
    borderColor: "purple.50",
    bg: "purple.25",
    boxShadow: "0px -1px 0px 0px rgba(160, 105, 247, 0.36) inset",
    w: "60px",
  }),
  multiValueLabel: (provided) => ({
    ...provided,
    color: "dark.500",
    fontSize,
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  }),
  multiValueRemove: (provided) => ({
    ...provided,
    display,
  }),
  singleValue: (provided) => ({
    ...provided,
    color,
    fontSize,
    fontWeight,
  }),
};

export default styles;
