import { useCallback } from "react";

const useHandleApiCall = () => {
  const handleApiCall = useCallback(async ({ endpoint, data, onSuccess, onError, afterAll = null }) => {
    try {
      const response = await endpoint(data).unwrap();
      if (onSuccess) {
        onSuccess(response)
      }
    } catch (error) {
      if (onError) {
        onError(error);
      }
    } finally {
      if (afterAll) {
        afterAll();
      }
    }
  }, []);

  return { handleApiCall };
};

export default useHandleApiCall;
