import { Box, Text, Flex, Icon, Avatar } from "@chakra-ui/react";
import { useProfile } from "@hooks";
import { IoCheckmarkCircleSharp, IoCloseCircleSharp } from "react-icons/io5";

const styles = { ml: "2", color: "white", w: "5", h: "5" };

const ProgressAnswerBar = ({ option, userResponse }) => {
  const { profile } = useProfile();
  const isQuiz = option.hasOwnProperty("correct");
  const isUserAnswer = (option) =>
    userResponse ? userResponse.includes(option.id) : false;

  const createState = (option, success, error, defaultValue) =>
    option.correct
      ? success
      : isUserAnswer(option) && option.correct === false
      ? error
      : defaultValue;

  const labelColor = createState(option, "white", "white", "dark.400");
  const progressBarColor = isQuiz
    ? createState(option, "green.400", "red.400", "gray.300")
    : isUserAnswer(option)
    ? "purple.100"
    : "gray.300";
  const iconType = createState(
    option,
    <Icon as={IoCheckmarkCircleSharp} {...styles} />,
    <Icon as={IoCloseCircleSharp} {...styles} />,
    null
  );
  const progressBarPercentage = isQuiz
    ? createState(option, "100", "100", option.value)
    : "100";

  return (
    <>
      <Text fontSize="md" fontWeight="600" color="dark.600">
        {`${option.value}%`}
      </Text>
      <Box
        overflow="hidden"
        position="relative"
        bg="gray.100"
        m="5px 0"
        borderRadius="4px"
      >
        <Box
          alignItems="center"
          justifyContent="flex-end"
          role="progressbar"
          aria-valuemax="100"
          aria-valuemin="0"
          aria-valuenow={option.value}
          width={`${progressBarPercentage}%`}
          height={8}
          bg={progressBarColor}
        >
          <Flex
            position="absolute"
            top="5px"
            justifyContent="space-between"
            width="100%"
          >
            <Text
              fontSize="md"
              fontWeight="600"
              color={labelColor}
              whiteSpace="nowrap"
              ml="2"
            >
              {option.label}
            </Text>
            <Flex alignItems="center" mr="1">
              {isUserAnswer(option) ? (
                <Avatar
                  shadow="sm"
                  size="xs"
                  src={profile?.avatar}
                  name={`${profile.firstName} ${profile.lastName}`}
                />
              ) : null}
              {iconType}
            </Flex>
          </Flex>
        </Box>
      </Box>
    </>
  );
};

export default ProgressAnswerBar;
