import { createSlice, isAnyOf, current } from "@reduxjs/toolkit";
import { meetingApi } from "@store/api/meeting";
import { agendaApi } from "@store/api/agenda";
import { getUserIdFromMeetingInstanceId } from "@utils/helpers";

const initialState = {
  data: {
    inAMeeting: false,
    meetingObject: null,
    meetingActivities: null,
    meetingHost: null,
  },
  isFetchingMeeting: true, 
  meetingHost: {
    host: null,
    coHosts: [],
  },
  ePulse: {
    data: [],
  },
  analytics: {
    live: false,
    data: [],
  },
};

// Hacky solution to fix issue with agenda when user joins or leaves meeting
// Should be removed when agenda, activity, and meeting slices are cleaned up
const generateNewMeetingPayload = (state, payload) => {
  const { meetingInstances } = payload;
  const { participantsInMeetingOnTH, participantsInMeetingOnZoom, participantsOpenedTH } = meetingInstances[0];

  const oldMeetingInstance = state.data.meetingObject?.meetingInstances[0];
  const newMeetingInstance = {
    ...oldMeetingInstance,
    participantsInMeetingOnTH,
    participantsInMeetingOnZoom,
    participantsOpenedTH
  };

  const newMeetingObject = { ...payload, meetingInstances: [newMeetingInstance] };
  return newMeetingObject;
};

// TODO: organize data in meeting slice (meetingObject, meetingInstance etc);
const meetingSlice = createSlice({
  name: "meeting",
  initialState,
  reducers: {
    updateParticipantJoinedMeeting: (state, { payload }) => {
      const { liveActivity = {} } = payload.meetingInstances[0]?.agenda || {};
      state.data.inAMeeting = true;
      const newMeetingObject = generateNewMeetingPayload(current(state), payload)
      state.data.meetingObject = newMeetingObject;
      state.data.meetingActivities = {
        [liveActivity?.type]: liveActivity?.data,
      };
      state.data.meetingHost =
        payload.meetingInstances[0].participantsInMeetingOnTH.find(
          (participant) => participant._id === payload.hostId
        );
    },
    updateParticipantLeft: (state, action) => {
      const { meetingObject, profileId } = action.payload;

      if (meetingObject?._id === state.data.meetingObject?._id) {
        const foundMe = meetingObject.meetingInstances[0].participantsInMeetingOnTH.find(
          (item) => {
            return item._id === profileId;
          }
        );

        if (foundMe) {
          const newMeetingObject = generateNewMeetingPayload(current(state), meetingObject)
          state.data.meetingObject = newMeetingObject;
        } else {
          return initialState;
        }
      }
    },
    updateLiveMeetingStatus: (state, action) => {
      state.analytics.live = action.payload;
    },
    updateAnalyticsData: (state, action) => {
      state.analytics.data.push({ uv: action.payload });
      if (state.analytics.data.length > 6) {
        state.analytics.data.shift();
      }
    },
    updateEPulseData: (state, action) => {
      state.ePulse.data = action.payload;
    },
    updateMeetingActivityStarted: (state, action) => {
      const { type, activity } = action.payload;
      const meetingActivities = state.data.meetingActivities || {};
      const updatedMeetingActivity = { ...meetingActivities, [type]: activity };
      state.data.meetingActivities = updatedMeetingActivity;
    },
    updateMeetingActivityEnded: (state, action) => {
      const { type, activityId } = action.payload;
      const activity = state.data.meetingActivities?.[type];
      if (activity && activity._id === activityId) {
        state.data.meetingActivities[type].live = false;
      }
    },
    clearMeetingState: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      isAnyOf(
        meetingApi.endpoints.checkExistingMeeting.matchFulfilled,
        meetingApi.endpoints.joinMeetingFromWeb.matchFulfilled,
        meetingApi.endpoints.checkWebUserLiveMeeting.matchFulfilled
      ),
      (state, { payload }) => {
        const { liveActivity = {} } = payload.meetingInstances[0]?.agenda || {};
        state.data.inAMeeting = !!payload.meetingInstances.length;
        state.data.meetingObject = payload;
        state.isFetchingMeeting = false;
        state.data.meetingActivities = liveActivity.type
          ? { [liveActivity?.type]: liveActivity?.data }
          : {};
        state.data.meetingHost = payload.meetingInstances.length ? (
          payload.meetingInstances[0].participantsInMeetingOnTH.find(
            (participant) => participant._id === getUserIdFromMeetingInstanceId(payload.meetingInstances[0]._id)
          )
        ) : null;
        state.meetingHost = payload.meetingInstances?.[0]?.platformMeetingHosts || initialState.meetingHost;
      }
    );
    builder.addMatcher(
      isAnyOf(
        meetingApi.endpoints.checkExistingMeeting.matchPending,
        meetingApi.endpoints.joinMeetingFromWeb.matchPending,
        meetingApi.endpoints.checkWebUserLiveMeeting.matchPending
      ), (state, _) => {
        state.isFetchingMeeting = true;
      }
    );
    builder.addMatcher(
      isAnyOf(
        meetingApi.endpoints.checkExistingMeeting.matchRejected,
        meetingApi.endpoints.joinMeetingFromWeb.matchRejected,
        meetingApi.endpoints.checkWebUserLiveMeeting.matchRejected
      ),
      (state, action) => {
        state.isFetchingMeeting = false;
      }
    );
    builder.addMatcher(
      meetingApi.endpoints.leaveMeetingFromWeb.matchFulfilled,
      () => initialState
    );
    builder.addMatcher(
      agendaApi.endpoints.stopLiveAgenda.matchFulfilled,
      (state) => {
        state.ePulse.data = [];
      }
    );
    // TODO: Move all agenda endpoints and related data to Agenda Slice
    builder.addMatcher(
      isAnyOf(
        agendaApi.endpoints.goLiveAgenda.matchFulfilled,
        agendaApi.endpoints.instantStart.matchFulfilled,
      ), (state, { payload }) => {
        state.data.meetingObject.meetingInstances[0].agenda = payload;
      }
    );
    builder.addMatcher(
      agendaApi.endpoints.stopLiveAgenda.matchFulfilled,
      (state) => {
        state.data.meetingObject.meetingInstances[0].agenda = null;
      }
    );
  },
});

const { reducer, actions } = meetingSlice;

export const {
  clearMeetingState,
  updateParticipantJoinedMeeting,
  updateParticipantLeft,
  updateLiveMeetingStatus,
  updateAnalyticsData,
  updateEPulseData,
  updateMeetingActivityStarted,
  updatePollAnswer,
  updateMeetingActivityEnded,
} = actions;
export default reducer;
