import { createAction } from "@reduxjs/toolkit";

export const showErrorToast = createAction("toastNotification/error");
export const showSuccessToast = createAction("toastNotification/success");
export const socketInitiated = createAction("TH_SOCKET/initiated");
export const saveUserMeetingInfo = createAction("TH/saveUserMeetingInfo");
export const saveDataToLocalStorage = createAction("TH/saveToLocalStorage");
export const removeDataFromLocalStorage = createAction("TH/removeDataFromLocalStorage");
export const removeWebUserFromMeeting = createAction("TH/removeWebUserFromMeeting");
export const triggerUserReauthenticate = createAction("TH/triggerUserReauthenticate");
