import { Flex, Icon } from "@chakra-ui/react";
import { CountUpTimer } from "@components";
import { MdAccessAlarms } from "react-icons/md";

const EPulseTimer = ({ agenda }) => {
  return (
    <Flex
      borderRadius="full"
      p="1.5"
      alignItems="center"
      bg="purple.25"
      border="1px"
      borderColor="purple.200"
      w="75px"
      h="20px"
    >
      <Icon as={MdAccessAlarms} color="purple.400" mr={1} boxSize="3" />
      <CountUpTimer
        startTimer={agenda.live}
        stopTimer={!agenda.live}
        startTime={agenda.startTime}
      />
    </Flex>
  );
};

export default EPulseTimer;
