import React from "react";
import { AgendaModal } from "@components";
import { useAgendaActions } from "@hooks";
import duplicateIcon from "@assets/svg/icon-duplicate.svg";

const DuplicateAgendaModal = ({ isOpen, onClose, agendaId }) => {
  const { duplicateAgenda: { isLoading, handleDuplicateAgenda } } = useAgendaActions();
  return (
    <AgendaModal
      title="Duplicate Agenda"
      body="Are you sure you want to duplicate this agenda?"
      icon={duplicateIcon}
      isOpen={isOpen}
      isLoading={isLoading}
      onClose={() => onClose(false)}
      handlePrimaryAction={() => handleDuplicateAgenda(agendaId, () => onClose(false))}
      primaryButtonText="Duplicate"
      handleSecondaryAction={() => onClose(false)}
    />
  )
}

export default DuplicateAgendaModal;
