import React from "react";
import { Text, Flex } from "@chakra-ui/react";
import { HostCard, Tooltip, AgendaContainer } from "@components";

const INFO_TEXT = "This section organizes Agendas and allows you to plan ahead. Create from scratch or use a template!";

const AgendaSection = () => {
  return (
    <HostCard>
      <Flex alignItems="center" justifyContent="flex-start">
        <Text mr={2} fontWeight="600" fontSize="sm">Your Agendas</Text>
        <Tooltip label={INFO_TEXT} hasArrow position="auto" />
      </Flex>
      <Text fontSize="xs" mt={2}>Plan ahead for an event and set up activities.</Text>
      <AgendaContainer />
    </HostCard>
  )
}

export default AgendaSection;
