import { Box } from "@chakra-ui/react";
import Dot from "./Dot";

const Stepper = ({ steps, index, handleClick }) => {
  return (
    <Box pb="24px">
      {steps.map((step, i) => (
        <Dot
          key={step.id}
          active={i === index}
          handleClick={(e) => handleClick(e, i)}
        />
      ))}
    </Box>
  );
};

export default Stepper;
