import { Box, Flex, Text, Image } from '@chakra-ui/react';
import { Tooltip } from "@components";

const RecentReportDataPills = ({ icon, value, tooltipText, color, bg }) => {
  const valuePercent = `${value}%`;
  return (
    <Box
      border="1px solid"
      borderRadius="24px"
      borderColor="gray.100"
      height="32px"
      width="31%"
      overflow="hidden"
    >
      <Tooltip
          label={tooltipText}
          hasArrow
          position="auto"
          trigger={
            <Box position="relative" height="32px" width="100%">
              <Flex
                height="32px"
                alignItems="center"
                justifyContent="center"
                position="absolute"
                width="100%"
                ml={0.25}
              >
                <Image src={icon} w={5} h={5} mr={1} />
                <Text fontSize="xs" fontWeight="700" color={color}>{valuePercent}</Text>
              </Flex>
              <Box bg={bg} height="32px" width={valuePercent} top={0}></Box>
            </Box>
          }
        />
    </Box>
  )
}

export default RecentReportDataPills;
