import { useNotifications, useSidebar } from "@hooks";
import { Flex } from "@chakra-ui/react";
import { Notifications, EmptyState, ClearAllButton } from "@components";
import { ActivityToolAccordion } from "@components/Activity";
import newsEmptyImage from "@assets/svg/icon-news-empty.svg";
import newsEmptyProImage from "@assets/svg/icon-news-empty-pro.svg";

const News = () => {
  const { notifications, handleRemoveAllNotifications } = useNotifications();
  const { isProView } = useSidebar();
  const areNotificationsAvailable = notifications?.length > 0;

  return (
    <Flex direction="column" justifyContent="center">
      <ActivityToolAccordion />
      {areNotificationsAvailable ? (
        <ClearAllButton
          isProView={isProView}
          handleRemoveAllNotifications={handleRemoveAllNotifications}
        />
      ) : null}
      {areNotificationsAvailable ? (
        <Notifications notifications={notifications} />
      ) : (
        <EmptyState
          imgSrc={isProView ? newsEmptyProImage : newsEmptyImage}
          imgProps={{ width: "100px", m: "0 auto", mt: 3 }}
          text="Congrats! You're caught up on your news."
          textProps={{
            color: "dark.100",
            textAlign: "center",
            mt: "5",
          }}
        />
      )}
    </Flex>
  );
};

export default News;
