import { useRef, useMemo } from "react";
import { useSelector } from "react-redux";
import { ResponsiveLine } from "@nivo/line";
import { Box, useToken } from "@chakra-ui/react";
import { EPulseChartMessage } from "@components";
import dayjs from "dayjs";

const EPulseChart = ({ agenda }) => {
  const ref = useRef(null);
  const [success, high, medium, low, textColor] = useToken("colors", [
    "success",
    "green.300",
    "red.300",
    "yellow.300",
    "dark.500",
  ]);

  const Epulse = useSelector((state) => state.meeting.ePulse);
  const defaultEpulse = { data: [] };
  const ePulseData = agenda.live ? Epulse : defaultEpulse;
  const agendaStarted = agenda.startTime && agenda.live;
  const ePulseDataIsShowing = useMemo(
    () => ePulseData?.data?.filter(({ pulse }) => pulse !== null).length >= 2,
    [ePulseData]
  );

  const commonProperties = {
    margin: { top: 20, right: 10, bottom: 10, left: 35 },
    animate: true,
    enableSlices: "x",
  };

  const blurStyles = {
    filter: "auto",
    blur: "2px",
  };

  const EPulseTick = (tick) => {
    const tickLabels = {
      50: { label: "High", color: high },
      0: { label: "Med", color: medium },
      "-50": { label: "Low", color: low },
    };

    const label = tickLabels[tick.value]?.label;
    const color = tickLabels[tick.value]?.color;

    if (!label) return null;

    return (
      <g transform={`translate(${tick.x - 5},${tick.y})`}>
        <text
          dominantBaseline="middle"
          textAnchor="end"
          transform="translate(-10,0)"
          style={{
            fontSize: "8px",
            fill: textColor,
          }}
        >
          {label}
        </text>
        <circle
          cx="2"
          cy="2"
          r="2"
          fill={color}
          transform="translate(-7, -2)"
        />
      </g>
    );
  };

  return (
    <>
      {!ePulseDataIsShowing ? (
        <EPulseChartMessage
          agendaStarted={agendaStarted}
          ePulseDataIsShowing={ePulseDataIsShowing}
        />
      ) : null}
      <Box
        ref={ref}
        position="relative"
        zIndex={0}
        height="150px"
        {...(!ePulseDataIsShowing ? blurStyles : null)}
      >
        <ResponsiveLine
          {...commonProperties}
          data={[
            {
              id: "points",
              data: ePulseData.data.map((item) => ({
                x: dayjs(item.time).toDate(),
                y: item.pulse,
              })),
            },
          ]}
          curve="monotoneX"
          colors={success}
          yScale={{
            type: "linear",
            stacked: false,
            min: -50,
            max: 50,
          }}
          enableGridX={false}
          isInteractive={false}
          axisBottom={null}
          animate={false}
          pointLabelYOffset={0}
          pointSize={5}
          enableArea={true}
          areaOpacity={0.1}
          areaBaselineValue={-50}
          axisLeft={{
            renderTick: EPulseTick,
          }}
        />
      </Box>
    </>
  );
};

export default EPulseChart;
