import { Checkbox } from "@chakra-ui/react";

const SendSettings = ({ formik }) => {
  const { values, handleChange } = formik;

  return (
    <Checkbox
      name="settings.blast"
      variant="settings"
      size="sm"
      isChecked={values.settings.blast}
      onChange={handleChange}
      colorScheme="purple"
    >
      Blast newly joined attendees 💥
    </Checkbox>
  );
};

export default SendSettings;
