import { Icon } from "@chakra-ui/react";

const ArrowUpRight = ({ color, ...rest }) => (
  <Icon viewBox="0 0 10 10" stroke={color} fill="none" { ...rest }>
    <path d="M1.25 8.75L8.75 1.25" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M1.25 1.25H8.75V8.75" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"/>
  </Icon>
);

export default ArrowUpRight;
