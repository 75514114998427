import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Button } from "@chakra-ui/react";
import { useSelector } from "react-redux";
import {
  useAuth,
  useSidebar,
  useUserOnboarding,
  useTour,
} from "@hooks";
import { UserOnboarding, ToggleButtonGroup } from "@components";
import { $Routes } from "@routes";

const AttendeeAndHostToggle = ({ isHostOrCoHost }) => {
  const [userMode, setUserMode] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const { runTour, stepIndex, handleTooltipCallback, updatingIsFirstView } = useUserOnboarding();
  const { isHostTour } = useTour();
  const { profile } = useAuth();
  const { isProView } = useSidebar();

  const liveAgenda = useSelector(
    (state) => state.meeting.data.meetingObject?.meetingInstances[0]?.agenda
  );

  const isEngagementPage = location.pathname.includes($Routes.homePage);
  const isRootPage = location.pathname === $Routes.rootPage;

  useEffect(() => {
    setUserMode(isProView ? "host" : isEngagementPage ? "engage" : "");
  }, [isProView, isEngagementPage]);

  const linkPath =
    isHostOrCoHost(profile?._id) &&
    liveAgenda &&
    liveAgenda?.userId === profile?._id
      ? `${$Routes.agendaDetailPage}/${liveAgenda._id}`
      : $Routes.allAgendaPage;

  if (isRootPage) {
    return null;
  }

  return (
    <UserOnboarding
      isLoading={updatingIsFirstView}
      isOpen={!isHostTour && runTour && stepIndex === 3}
      index={stepIndex}
      onClick={handleTooltipCallback}
    >
      <ToggleButtonGroup
        onChange={setUserMode}
        value={userMode}
        mr={2}
        bg="purple.700"
        p={1.5}
        borderRadius="8px"
      >
        <Button
          variant="primary"
          value="engage"
          size="sm"
          bg="blue.500"
          _hover={{
            bg: "blue.500"
          }}
          onClick={() => navigate($Routes.homePage)}
        >
          Engage
        </Button>
        <Button
          variant="primary"
          value="host"
          size="sm"
          onClick={() => navigate(linkPath)}
        >
          Host
        </Button>
      </ToggleButtonGroup>
    </UserOnboarding>
  )
}

export default AttendeeAndHostToggle;
