import React, { Fragment } from "react";
import {
  Flex,
  Heading,
  IconButton,
  VStack,
  Divider,
  Box,
  Icon,
} from "@chakra-ui/react";
import { IoClose } from "react-icons/io5";
import { Card } from "@components";
import { ActivityTypesItem } from "@components/Activity";
import { activityTypesList } from "@constants/activities";

const ActivityTypesCard = ({ changeViewData, setActivityType }) => {
  const { views, setView } = changeViewData;

  return (
    <Card>
      <Flex justifyContent="space-between" pt={4} px={3}>
        <Heading
          as="h4"
          fontWeight="semibold"
          fontSize="12px"
          color="dark.200"
          mb={3}
        >
          Activities
        </Heading>
        <IconButton
          aria-label="Close"
          icon={<Icon as={IoClose} color="gray.500" />}
          minWidth="0"
          height="4"
          bg="transparent"
          _hover={{ bg: "transparent" }}
          _active={{ bg: "transparent" }}
          onClick={() => setView(views.list)}
        />
      </Flex>
      <VStack
        alignItems="flex-start"
        spacing="0"
        overflowY="auto"
        maxHeight="350px"
        sx={{
          "&::-webkit-scrollbar": {
            width: "4px",
          },
          "&::-webkit-scrollbar-track": {
            backgroundColor: "#fdfdff",
            borderRadius: "24px",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#DDDEE5",
            borderRadius: "24px",
          },
          "&::-webkit-scrollbar-thumb:hover": {
            backgroundColor: "#c2c3c8",
          },
        }}
      >
        {activityTypesList.map((activity, index) => (
          <Fragment key={activity.id}>
            <ActivityTypesItem
              title={activity.title}
              description={activity.description}
              icon={activity.icon}
              vote={activity.vote}
              handleOpen={() => {
                if (!activity.vote) {
                  setView(views.add);
                  setActivityType(activity.name);
                }
              }}
            />
            {index !== activityTypesList.length - 1 && <Divider />}
          </Fragment>
        ))}
      </VStack>
    </Card>
  );
};

export default ActivityTypesCard;
