import { Flex } from "@chakra-ui/react";
import { EngagementBox } from "@components";
import pointsIcon from "@assets/svg/icon-points.svg";
import coinsIcon from "@assets/svg/icon-coin.svg";
import eScoreIcon from "@assets/svg/icon-escore.svg";

const Engagement = ({ points, coins, score, inline }) => {
  return (
    <Flex pos="relative" justifyContent="space-between">
      <EngagementBox
        icon={eScoreIcon}
        count={score}
        label="E-SCORE"
        labelTextColor="green.700"
        labelBackgroundColor="green.25"
        inline={inline}
      />
      <EngagementBox
        icon={pointsIcon}
        count={points}
        label="POINTS"
        labelTextColor="dark.500"
        labelBackgroundColor="gray.200"
        inline={inline}
      />
      <EngagementBox
        icon={coinsIcon}
        count={coins}
        label="COINS"
        labelTextColor="orange.800"
        labelBackgroundColor="orange.50"
        inline={inline}
      />
    </Flex>
  );
};

export default Engagement;
