const Tooltip = {
  baseStyle: {
    borderRadius: "6px",
    fontWeight: "400",
    fontSize: "10px",
    color: "gray.25",
    padding: "10px",
    bg: "dark.400",
  },
};

export default Tooltip;
