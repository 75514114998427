import epulseLoading1 from "@assets/svg/epulse-loading1.svg";
import epulseLoading2 from "@assets/svg/epulse-loading2.svg";
import epulseLoading3 from "@assets/svg/epulse-loading3.svg";
import epulseLoading4 from "@assets/svg/epulse-loading4.svg";
import epulseLoading5 from "@assets/svg/epulse-loading5.svg";

export const LOADER_MESSAGES = [
  { icon: epulseLoading1, text: "E-Pulse starting...", progress: 10 },
  {
    icon: epulseLoading2,
    text: "Get ready to view LIVE audience engagement!",
    progress: 25,
  },
  {
    icon: epulseLoading3,
    text: "Gathering attendee engagement data...",
    progress: 50,
  },
  { icon: epulseLoading4, text: "Almost there...", progress: 75 },
  {
    icon: epulseLoading5,
    text: "Make sure to check E-Report for detailed attendee analytics!",
    progress: 90,
  },
];
