import { Flex, Image, Text } from "@chakra-ui/react";
import userIcon from "@assets/svg/user-icon.svg";

const UserCount = ({ count, dark, ...rest }) => {
  return (
    <Flex
      alignItems="center"
      borderRadius="md"
      bg={dark ? "blue.25" : "white"}
      py={1}
      px={2}
      {...rest}
    >
      <Image w="18px" h="18px" mr={1.5} src={userIcon} />
      <Text fontSize="md" fontWeight="600">{count}</Text>
    </Flex>
  );
};

export default UserCount;
