import React from "react";
import { zeroPad } from "react-countdown";
import { Text } from "@chakra-ui/react";

const Duration = ({ minutes, seconds, completed }) => {
  if (completed) {
    return (
      <Text fontWeight="600" fontSize="12px" color="gray.300">
        Done
      </Text>
    );
  }

  return (
    <Text fontWeight="500" fontSize="xs" color="dark.400">
      {minutes}:{zeroPad(seconds)}
    </Text>
  );
};

export default Duration;
