import { authApi } from "@store/api/auth";
import { saveDataToLocalStorage, triggerUserReauthenticate } from "@store/customActions";
import { getFromLocalStorage } from "@utils/localStorage";
import { isZoomApp } from "@utils/helpers";
import { JWT_TOKEN_NAME, APP_TYPE } from "@constants";

const AppType = isZoomApp ? APP_TYPE.ZoomApp : APP_TYPE.WebApp;

export const addTokenToHeader = (headers) => {
  const baseHeaders = headers || {};

  const token = getFromLocalStorage(JWT_TOKEN_NAME);
  if (token) {
    baseHeaders["authorization"] = `Bearer ${token}`;
  }
  baseHeaders["apptype"] = AppType;

  return baseHeaders;
};

export const transformResponse = (response) => response?.content || {};

//TODO: add tests for this function
export const refreshAccessToken = async (api) => {
  const response = await api.dispatch(authApi.endpoints.refreshToken.initiate());
  const accessToken = response?.data?.accessToken;

  if (accessToken) {
    api.dispatch(saveDataToLocalStorage({
      key: JWT_TOKEN_NAME,
      value: accessToken
    }));
  }

  return accessToken;
};

//TODO: add tests for this function
export const refreshTokenAndRetryRequest = async (fetchBaseQuery, api, args, headers) => {
  const accessToken = await refreshAccessToken(api);

  if (accessToken) {
    const updatedHeaders = { ...headers, authorization: `Bearer ${accessToken}` };
    const refreshedArgs = { ...args, headers: updatedHeaders, credentials: "include" };
    return await fetchBaseQuery(refreshedArgs, api, {});
  }

  api.dispatch(triggerUserReauthenticate());
}
