import React from "react";
import { chakra, useCheckbox, Flex, Icon, Text } from "@chakra-ui/react";
import { FaCheck } from "react-icons/fa";

const Checkbox = (props) => {
  const { state, getCheckboxProps, getInputProps, getLabelProps, htmlProps } =
    useCheckbox(props);

  const handleOnKeyDown = (event) => {
    if (event.key === " ") {
      getInputProps().onChange();
    }
  };

  return (
    <chakra.label
      display="flex"
      flexDirection="row"
      alignItems="center"
      justifyContent="space-between"
      gridColumnGap={2}
      cursor="pointer"
      p={2}
      background={state.isChecked ? "green.400" : null}
      border="2px solid"
      borderColor={state.isChecked ? "transparent" : "gray.200"}
      mt={0}
      borderRadius={5}
      shadow={state.isChecked ? "md" : null}
      {...htmlProps}
    >
      <input {...getInputProps()} hidden />
      <Text
        w="250px"
        color={state.isChecked ? "white" : "dark.400"}
        fontWeight="600"
        {...getLabelProps()}
      >
        {props.children}
      </Text>
      <Flex
        alignItems="center"
        justifyContent="center"
        border="2px solid"
        borderRadius="4"
        borderColor={state.isChecked ? "transparent" : "gray.300"}
        background="white"
        w={4}
        h={4}
        shadow="sm"
        {...getCheckboxProps()}
        tabIndex={0}
        role="checkbox"
        aria-checked={state.isChecked}
        onKeyDown={handleOnKeyDown}
      >
        {state.isChecked && (
          <Icon as={FaCheck} color="green.500" fontSize="x-small" />
        )}
      </Flex>
    </chakra.label>
  );
};

export default Checkbox;
