import { showSuccessToast, socketInitiated } from "@store/customActions";
import { activityTitles, activityTypes } from "@constants/activities";
import {
  activityEnded,
  activityStarted,
  participantActivityEnded,
  activityResults
} from "@socket";
import {
  updateMeetingActivityEnded,
  updateMeetingActivityStarted
} from "@store/meetingSlice";
import {
  endActivity,
  updateLiveActivity,
  updateLiveActivityTool,
  addActivityResponse,
  prepareStateForLiveActivity,
  updateParticipantMeetingEnd,
} from "@store/activitySlice";
import { openSidebar, toggleTabIndex } from "@store/sidebarSlice";
import { AUDIO_EVENTS } from '@constants';
import { playAudio } from "@utils/helpers";

const activitySocketMiddlewares = (api) => (next) => (action) => {
  if (socketInitiated.type === action.type) {
    activityEnded.subscribe((data) => {
      api.dispatch(endActivity(data));
      api.dispatch(showSuccessToast(`${activityTitles[data.type]} Ended`));
    });

    activityStarted.subscribe((data) => {
      playAudio(AUDIO_EVENTS.START_ACTIVITY);
      const { activity } = data;
      if (activity.type === activityTypes.send) {
        api.dispatch(updateLiveActivityTool({ activity }));
        api.dispatch(openSidebar());
        api.dispatch(toggleTabIndex(1))
      } else {
        api.dispatch(updateLiveActivity({ activity }));
      }
      api.dispatch(updateMeetingActivityStarted({ activityId: activity._id, type: activity.type }));
      api.dispatch(prepareStateForLiveActivity());
    });

    participantActivityEnded.subscribe((data) => {
      const { activity: { _id, type, } } = data;
      api.dispatch(updateMeetingActivityEnded({ activityId: _id, type }));
      api.dispatch(updateParticipantMeetingEnd(data));
      api.dispatch(showSuccessToast(`${activityTitles[type]} has Ended`));
    });

    activityResults.subscribe((data) => {
      api.dispatch(addActivityResponse(data))
    });
  }
  return next(action);
};

export default activitySocketMiddlewares;
