import React from "react";
import { Flex, Text } from '@chakra-ui/react';
import dayjs from "dayjs";
import { RecentReportDataPills } from "@components";
import fullAttendance from "@assets/svg/full-attendance-icon.svg";
import engagementIcon from "@assets/svg/engagement-icon.svg";
import boostFireIcon from "@assets/svg/boost-fire-icon.svg";

const LastTownhallReport = ({ lastTownhall }) => (
  <>
    <Text fontSize="xs" lineHeight={1.5}>
      {`Last Townhall was ${dayjs(lastTownhall.agendaStartTime).format("M/DD/YYYY")}. Try to beat your score!`}
    </Text>
    <Flex mt={2} pt={2} justifyContent="space-between">
      <RecentReportDataPills
        icon={fullAttendance}
        value={lastTownhall.averageAttendance}
        tooltipText="FULL attendance"
        color="blue.800"
        bg="#D7FFF9"
      />
      <RecentReportDataPills
        icon={engagementIcon}
        value={lastTownhall.eScore}
        tooltipText="MED or HIGH engagement"
        color="green.800"
        bg="#EDFCBB"
      />
      <RecentReportDataPills
        icon={boostFireIcon}
        value={lastTownhall.boost}
        tooltipText="MAX boost from activities"
        color="red.800"
        bg="#FFD0CE"
      />
    </Flex>
  </>
);

export default LastTownhallReport;
