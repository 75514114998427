import {
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
  Icon,
} from "@chakra-ui/react";
import { IoCheckmarkCircleSharp, IoAlertCircleSharp } from "react-icons/io5";

export const AuthInput = ({
  type,
  name,
  value,
  placeholder,
  onChange,
  onBlur,
  readOnly,
  helperText,
  showSuccessElements,
  isInvalid,
  requireValidation,
  error,
  ...rest
}) => {
  return (
    <FormControl isInvalid={isInvalid} isRequired={requireValidation}>
      <FormLabel htmlFor={name} variant="auth">
        {placeholder}
      </FormLabel>
      <InputGroup>
        <Input
          name={name}
          type={type}
          placeholder={placeholder}
          value={value}
          readOnly={readOnly}
          onChange={onChange}
          onBlur={onBlur}
          variant="auth"
          {...rest}
        />
        {isInvalid && (
          <InputRightElement w="3rem">
            <Icon as={IoAlertCircleSharp} color="error" />
          </InputRightElement>
        )}
        {!isInvalid && showSuccessElements && (
          <InputRightElement w="3rem">
            <Icon as={IoCheckmarkCircleSharp} color="success" />
          </InputRightElement>
        )}
      </InputGroup>
      {isInvalid && (
        <FormErrorMessage color="error" fontSize={12}>
          {error}
        </FormErrorMessage>
      )}
      {!isInvalid && helperText && (
        <FormHelperText color="blue.500" fontSize={12}>
          {helperText}
        </FormHelperText>
      )}
    </FormControl>
  );
};
