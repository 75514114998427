import { Box, Flex } from "@chakra-ui/react";
import { MarketingQuote } from "@components/Marketing";

const NoAuthPagesContainer = ({ children }) => {
  return (
    <Flex
      background="dark.800"
      width="100%"
      height="inherit"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
    >
      <Box
        w="sm"
        p="4px"
        borderRadius={"2xl"}
        bgGradient="linear-gradient(236deg, rgba(137, 18, 202, 0) 0%, #3E00EA 100%)"
      >
        <Box
          p="42px"
          borderRadius="2xl"
          bgGradient="linear-gradient(27.34deg, rgba(6, 9, 33, 0.53) -10.93%, #11163F 80.46%)"
        >
          {children}
        </Box>
      </Box>
      <MarketingQuote />
    </Flex>
  );
};

export default NoAuthPagesContainer;
