import { InputGroup, Input, Flex, CloseButton } from "@chakra-ui/react";
import { Field } from "formik";

const PollAnswerInput = ({
  index,
  name,
  option,
  handleChange,
  handleOnBlur,
  handleRemoveInput,
  isDisabled,
  isInvalid,
  ...rest
}) => {
  return (
    <Flex
      alignItems="center"
      mb={2}
      border="1px solid"
      borderRadius="8px"
      borderColor="gray.200"
      {...(isInvalid ? { boxShadow: "0 0 0 2px red" } : {})}
    >
      <InputGroup size="md">
        <Field
          as={Input}
          id={name}
          fontSize="12px"
          placeholder="Input option"
          onChange={handleChange}
          onBlur={handleOnBlur}
          borderStyle="none"
          borderRight="1px solid"
          borderRightColor="gray.200"
          name={name}
          {...rest}
          borderRadius="none"
        />
      </InputGroup>
      <CloseButton
        m={1}
        size="sm"
        color="gray.400"
        onClick={handleRemoveInput}
        disabled={isDisabled}
      />
    </Flex>
  );
};

export default PollAnswerInput;
