import dayjs from "dayjs";
import {
  Flex,
  Text,
  Box,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  Circle,
  Icon,
  useToken,
  VStack,
} from "@chakra-ui/react";
import {
  ABUSE_MESSAGES,
  ICON_SPAM_COUNT_TOKEN,
  ICONS_UNLOCK_TIME_TOKEN,
} from "@constants";
import { getFromLocalStorage } from "@utils/localStorage";
import { GoAlert } from "react-icons/go";
import abuseModalBg from "@assets/images/abuse-modal-bg.png";

const AbuseIcon = () => (
  <Box position="relative" mr="3">
    <Box
      position="absolute"
      top="0"
      left="0"
      width="100%"
      height="100%"
      borderRadius="50%"
      bg="purple.400"
      opacity="0.1"
      zIndex="-1"
    />
    <Circle size="50px" bg="transparent" color="red.500">
      <GoAlert size="30" />
    </Circle>
  </Box>
);

const IconAbuseModal = ({ isOpen, handleClose }) => {
  const now = dayjs();
  const accountUnlockTime = getFromLocalStorage(ICONS_UNLOCK_TIME_TOKEN);
  const iconSpamCount = getFromLocalStorage(ICON_SPAM_COUNT_TOKEN);
  const [purple700] = useToken("colors", ["purple.700"]);
  const heading = ABUSE_MESSAGES[iconSpamCount - 1]?.heading;
  const body = ABUSE_MESSAGES[iconSpamCount - 1]?.body;

  let minutesLeft = 0;

  if (accountUnlockTime) {
    const unlockTime = dayjs(accountUnlockTime);

    if (now.isBefore(unlockTime)) {
      minutesLeft = Math.ceil(unlockTime.diff(now, "second") / 60);
    }
  }

  return (
    <Modal isOpen={isOpen} onClose={handleClose} isCentered size="xs">
      <ModalOverlay />
      <ModalContent
        containerProps={{ pointerEvents: "none" }}
        borderRadius="12px"
        bg={`url(${abuseModalBg}) ${purple700} no-repeat 200px 100%`}
      >
        <Flex>
          <Flex direction="column">
            <ModalHeader
              px="5"
              pb="0"
              color="white"
              fontWeight="700"
              fontSize="lg"
            >
              {heading}
            </ModalHeader>
            <ModalBody
              px="5"
              pb="5"
              color="purple.100"
              fontWeight="400"
              fontSize="sm"
            >
              {body}
              {minutesLeft ? (
                <Text mt="5">{`${minutesLeft} minute(s) remaining`}</Text>
              ) : null}
            </ModalBody>
          </Flex>
          <Box alignSelf="center">
            <Icon as={AbuseIcon} />
          </Box>
        </Flex>
      </ModalContent>
    </Modal>
  );
};

export default IconAbuseModal;
