import statIcon from "@assets/svg/icon-statistics.svg";
import chartIcon from "@assets/svg/icon-pie-chart.svg";
import warrantyIcon from "@assets/svg/icon-warranty.svg";

export const PRO_TRIAL_LIST_ITEMS = [
  {
    icon: statIcon,
    heading: "E-Pulse",
    text: "Engagement feedback when cameras are off",
  },
  {
    icon: chartIcon,
    heading: "E-Report",
    text: "Engagement data to see who participated",
  },
  {
    icon: warrantyIcon,
    heading: "Activities",
    text: "Attendance data to see who showed up",
  },
];

// Show popup for trial remaining days
export const DAYS = [0, 1, 3, 5, 6];
export const TRIAL_DAYS = 7;
export const TRIAL_CODE = "135246";
export const TRIAL_PERIOD_DAYS_TOKEN = "trialPeriodDays";
export const TRIAL_MODAL_SEEN_TOKEN_NAME = "trial-modal-seen";

const ACTIVE_BACKGROUND = "yellow.100";
const ACTIVE_TEXT = "yellow.900";
const ACTIVE_ICON = "yellow.600";
const INACTIVE_BACKGROUND = "red.100";
const INACTIVE_TEXT = "red.700";
const INACTIVE_ICON = "red.600";

const getTrialActiveMessage = (daysRemaining) =>
  `Trial ends in ${daysRemaining} day(s)`;
const TRIAL_INACTIVE_MESSAGE = "Your trial has ended";

export const TRIAL_MESSAGE = (daysRemaining) => {
  return daysRemaining > 0
    ? {
        messageText: getTrialActiveMessage(daysRemaining),
        backgroundColor: ACTIVE_BACKGROUND,
        textColor: ACTIVE_TEXT,
        iconColor: ACTIVE_ICON,
      }
    : {
        messageText: TRIAL_INACTIVE_MESSAGE,
        backgroundColor: INACTIVE_BACKGROUND,
        textColor: INACTIVE_TEXT,
        iconColor: INACTIVE_ICON,
      };
};
