import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { customBaseQuery } from "@store/api/baseQueries";

export const authApi = createApi({
  reducerPath: "authApi",
  baseQuery: customBaseQuery(
    fetchBaseQuery({
      baseUrl: `${process.env.REACT_APP_ZOOMAPP_BACKEND_BASE_URL}/user`,
    }),
  ),
  endpoints: (builder) => {
    return {
      oAuthSignIn: builder.mutation({
        query: (body) => ({
          url: "/oauth-signin",
          method: "POST",
          body,
        }),
      }),

      fetchProfile: builder.query({
        query: () => ({
          url: "/profile/me",
          method: "GET",
        }),
        transformResponse: (response) => response.user,
      }),

      updateProfile: builder.mutation({
        query: (body) => ({
          url: "/edit-profile",
          method: "POST",
          body,
        }),
      }),

      updateAvatar: builder.mutation({
        query: (body) => ({
          url: "/update-avatar",
          method: "POST",
          body,
        }),
      }),

      updateColor: builder.mutation({
        query: (body) => ({
          url: "/update-color",
          method: "POST",
          body,
        }),
      }),

      signUpUser: builder.mutation({
        query: (body) => ({
          url: "/local-signup",
          method: "POST",
          body,
        }),
      }),

      loginUser: builder.mutation({
        query: (body) => ({
          url: "/local-signin",
          method: "POST",
          body,
        }),
      }),

      verifyEmail: builder.mutation({
        query: (body) => ({
          url: "/verify-email",
          method: "POST",
          body,
        }),
      }),

      resendVerifyEmail: builder.mutation({
        query: (body) => ({
          url: "/resend-verify-email",
          method: "POST",
          body,
        }),
      }),

      verifyOnboarding: builder.mutation({
        query: (body) => ({
          url: "/update-onboarding",
          method: "POST",
          body,
        }),
      }),

      updateIsFirstView: builder.mutation({
        query: (body) => ({
          url: "/update-first-view",
          method: "POST",
          body,
        }),
      }),

      resetPasswordEmail: builder.mutation({
        query: (body) => ({
          url: "/reset-password-email",
          method: "POST",
          body,
        }),
      }),

      resetPassword: builder.mutation({
        query: (body) => ({
          url: "/reset-password",
          method: "POST",
          body,
        }),
      }),

      updateOnboardingProcess: builder.mutation({
        query: (body) => ({
          url: "/update-onboarding-process",
          method: "POST",
          body,
        }),
      }),

      updateOnboarding: builder.mutation({
        query: (body) => ({
          url: "/update-user-onboarding",
          method: "POST",
          body,
        }),
      }),

      refreshToken: builder.query({
        query: () => ({
          url: "/token/refresh",
          method: "GET",
        }),
      }),
    };
  },
});

export const {
  useOAuthSignInMutation,
  useFetchProfileQuery,
  useUpdateProfileMutation,
  useUpdateAvatarMutation,
  useSignUpUserMutation,
  useLoginUserMutation,
  useVerifyEmailMutation,
  useResendVerifyEmailMutation,
  useVerifyOnboardingMutation,
  useUpdateIsFirstViewMutation,
  useResetPasswordEmailMutation,
  useResetPasswordMutation,
  useUpdateColorMutation,
  useUpdateOnboardingProcessMutation,
  useUpdateOnboardingMutation,
} = authApi;
