import { IconButton } from "@chakra-ui/react";
import { FaEye, FaEyeSlash } from "react-icons/fa";

const PasswordVisibilityIcon = ({ showPassword, setShowPassword }) => {
  return (
    <IconButton
      aria-label="Password Visbility"
      icon={showPassword ? <FaEye /> : <FaEyeSlash />}
      color="#848CCA"
      h="1rem"
      onClick={() => setShowPassword(!showPassword)}
      variant="ghost"
      _hover={{
        bg: "transparent",
      }}
      _active={{
        bg: "transparent",
      }}
      cursor={"pointer"}
    />
  );
};

export default PasswordVisibilityIcon;
