import {
  Flex,
  Text,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Image,
} from "@chakra-ui/react";
import { Card } from "@components";
import { SendLink } from "@components/ActivityTypes/Send";
import sendIcon from "@assets/svg/icon-send.svg";
import { LinkIcon, ArrowUpRight } from "@components/Svg";

const SendView = ({ handleClick, activity }) => {
  return (
    <Card p={2} borderRadius="12px" background="white" mb={2}>
      <AccordionItem border={0}>
        <Flex justifyContent="space-between" alignItems="flex-start">
          <Flex alignItems="flex-start" justifyContent="space-between" mr="8px">
            <Image mr="8px" src={sendIcon} width="24px" height="24px" />
            <Text fontSize="12px" color="dark.400" fontWeight={600}>
              {activity?.title}
            </Text>
          </Flex>
          <AccordionButton
            borderRadius="full"
            w="inherit"
            p="0"
            bg="transparent"
            fontSize="12px"
            color="gray.400"
            _hover={{ bg: "gray.100" }}
            _active={{ bg: "transparent" }}
          >
            <AccordionIcon />
          </AccordionButton>
        </Flex>
        <AccordionPanel p="10px 0">
          <SendLink
            text={activity.options.map((option) => option.option)}
            handleClick={() => handleClick(activity)}
            beforeIcon={<LinkIcon color="dark.200" mr={2} width="12px" />}
            afterIcon={<ArrowUpRight ml={1} color="green.500" mr={1} width="10px" />}
          />
        </AccordionPanel>
      </AccordionItem>
    </Card>
  );
};

export default SendView;
