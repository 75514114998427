import {
  Flex,
  Text,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  AvatarGroup,
  Avatar,
} from "@chakra-ui/react";
import { activityResponseTypes, activityTypes } from "@constants/activities";
import {
  PollResults,
  QuizResults,
  SendResults,
} from "@components/ActivityTypes";

const activityResultObject = {
  [activityTypes.quiz]: QuizResults,
  [activityTypes.poll]: PollResults,
  [activityTypes.send]: SendResults,
};

const sharedStyles = {
  fontWeight: "400",
  fontSize: "12px",
  color: "dark.300",
};

const ActivityResultAccordion = ({
  options,
  type,
  response,
  settings,
  title,
  numberOfParticipants,
  isAccordionOpen,
  toggleAccordion,
}) => {
  const totalResponse = response.length;
  const participants = response.map((singleResponse) => singleResponse.user);

  const ResultComponent = activityResultObject[type];
  const handleOpenAccordion = isAccordionOpen ? 0 : -1;

  return (
    <Accordion allowToggle={true} index={handleOpenAccordion}>
      <AccordionItem border={0}>
        {({ isExpanded }) => (
          <>
            <Flex justifyContent="space-between" alignItems="center">
              <Flex alignItems="center">
                {participants.length ? (
                  <AvatarGroup size="xs" max={2} mr={2} fontSize="12px">
                    {participants.map((participant) => (
                      <Avatar
                        key={participant.userId}
                        name={`${participant.firstName} ${participant.lastName}`}
                        src={participant.avatar}
                      />
                    ))}
                  </AvatarGroup>
                ) : null}

                <Text {...sharedStyles}>
                  {totalResponse}{" "}
                  {totalResponse === 1
                    ? activityResponseTypes[type].singular
                    : activityResponseTypes[type].plural}
                </Text>
              </Flex>
              <AccordionButton
                w="inherit"
                p="0"
                bg="transparent"
                _hover={{ bg: "transparent" }}
                _active={{ bg: "transparent" }}
                onClick={() => toggleAccordion()}
                {...sharedStyles}
              >
                {`${isExpanded ? "Hide" : "Show"} Result`} <AccordionIcon />
              </AccordionButton>
            </Flex>
            <AccordionPanel p="10px 0">
              <ResultComponent
                options={options}
                response={response}
                title={title}
                settings={settings}
                numberOfParticipants={numberOfParticipants}
              />
            </AccordionPanel>
          </>
        )}
      </AccordionItem>
    </Accordion>
  );
};

export default ActivityResultAccordion;
