import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { logOutUser } from "@store/profileSlice";
import {
  useSignUpUserMutation,
  useResendVerifyEmailMutation,
  useResetPasswordEmailMutation,
  useLoginUserMutation,
  useResetPasswordMutation,
  useVerifyEmailMutation,
  useOAuthSignInMutation,
  useUpdateAvatarMutation,
  useUpdateColorMutation,
  useUpdateProfileMutation,
} from "@store/api/auth";
import { showErrorToast, showSuccessToast, saveDataToLocalStorage } from "@store/customActions";
import { $Routes } from "@routes";
import { useHandleApiCall } from "@hooks";
import { JWT_TOKEN_NAME } from "@constants";

const useAuthActions = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { handleApiCall } = useHandleApiCall();
  const [resetPasswordWithEmail, { isLoading: isPasswordResetLoading }] = useResetPasswordEmailMutation();
  const [signUpUser, { isLoading: isSignupLoading }] = useSignUpUserMutation();
  const [resendVerifyEmail, { isLoading: isResendVerifyEmailLoading }] = useResendVerifyEmailMutation();
  const [loginUser, { isLoading: isLoginUserLoading }] = useLoginUserMutation();
  const [resetPassword, { isLoading: isResetPasswordLoading }] = useResetPasswordMutation();
  const [oAuthSignIn, { isLoading: isOAuthLoading }] = useOAuthSignInMutation();
  const [updateAvatar, { isLoading: isUpdateAvatarLoading }] = useUpdateAvatarMutation();
  const [updateColor, { isLoading: isUpdateColorLoading }] = useUpdateColorMutation();
  const [updateProfile, { isLoading: isUpdateProfileLoading }] = useUpdateProfileMutation();
  const [verifyEmail] = useVerifyEmailMutation();

  const handleSuccessfulLogin = useCallback((response) => {
    dispatch(saveDataToLocalStorage({
      key: JWT_TOKEN_NAME,
      value: response.accessToken
    }));
    dispatch(showSuccessToast("Login was successful"));
    const path = location.state ? location.state.from : $Routes.rootPage;
    navigate(path);
  }, [dispatch, navigate, location.state])

  const sendPasswordResetRequest = useCallback(async (email) => {
    handleApiCall({
      data: { email },
      endpoint: resetPasswordWithEmail,
      onSuccess: () => {
        dispatch(showSuccessToast("Reset password email has been re-sent"));
        navigate($Routes.checkEmailPage, { replace: true, state: { email } });
      }
    });
  }, [handleApiCall, dispatch, navigate, resetPasswordWithEmail]);

  const signupUserWithLocalStrategy = useCallback(async (data) => {
    handleApiCall({
      data,
      endpoint: signUpUser,
      onSuccess: () => {
        navigate(`${$Routes.confirmEmailPage}/${data.email}`)
      }
    });
  }, [navigate, signUpUser, handleApiCall]);

  const resendVerifyEmailForLocalStrategy = useCallback(async (data) => {
    handleApiCall({
      data,
      endpoint: resendVerifyEmail,
      onSuccess: () => dispatch(showSuccessToast("Verification email has been sent"))
    });
  }, [resendVerifyEmail, dispatch, handleApiCall]);

  const loginUserWithLocalStrategy = useCallback(async (data) => {
    handleApiCall({
      data,
      endpoint: loginUser,
      onSuccess: (response) => {
        handleSuccessfulLogin(response);
      }
    });
  }, [handleApiCall, loginUser, handleSuccessfulLogin]);

  const resetUserPassword = useCallback(async (data) => {
    if (data.password !== data.confirmPassword) {
      dispatch(showErrorToast("Password confirmation failed. Please confirm both passwords match"));
      return;
    }

    const search  = location.search;
    const params = new URLSearchParams(search);
    const email = params.get("email");
    const token = params.get("token");

    handleApiCall({
      data: {
        email,
        accessToken: token,
        password: data.password
      },
      endpoint: resetPassword,
      onSuccess: () => {
        dispatch(showSuccessToast("Password reset is successful. Please login to continue"));
        navigate($Routes.loginPage);
      }
    });
  }, [dispatch, handleApiCall, location.search, navigate, resetPassword]);

  const localVerifyEmail = useCallback(async (data) => {
    handleApiCall({
      data,
      endpoint: verifyEmail,
      onSuccess: () => {
        dispatch(showSuccessToast("Email has been verified. Login to continue"));
        navigate( $Routes.rootPage);
      }
    });
  }, [dispatch, handleApiCall, navigate, verifyEmail]);

  const googleOAuthLogin = useCallback(async (data) => {
    handleApiCall({
      data,
      endpoint: oAuthSignIn,
      onSuccess: (response) => {
        handleSuccessfulLogin(response);
      }
    });
  }, [handleApiCall, handleSuccessfulLogin, oAuthSignIn]);

  const updateUserAvatar = async (data) => {
    handleApiCall({
      data,
      endpoint: updateAvatar,
      onSuccess: () => {
        dispatch(showSuccessToast("User avatar updated"));
      }
    });
  };

  const updateUserColor = async (data) => {
    handleApiCall({
      data,
      endpoint: updateColor,
      onSuccess: () => dispatch(showSuccessToast("User avatar updated"))
    });
  };

  const updateUserProfile = async (data) => {
    handleApiCall({
      data,
      endpoint: updateProfile,
      onSuccess: () => dispatch(showSuccessToast("User profile updated"))
    });
  };

  const logOut = useCallback(() => {
    dispatch(logOutUser())
  }, [dispatch]);

  return {
    signupUserWithLocalStrategy: {
      signupUser: signupUserWithLocalStrategy,
      isLoading: isSignupLoading
    },
    resendVerifyEmailForLocalStrategy: {
      resendVerificationEmail: resendVerifyEmailForLocalStrategy,
      isLoading: isResendVerifyEmailLoading,
    },
    sendPasswordResetRequest: {
      requestPasswordReset: sendPasswordResetRequest,
      isLoading: isPasswordResetLoading
    },
    loginUserWithLocalStrategy: {
      loginUser: loginUserWithLocalStrategy,
      isLoading: isLoginUserLoading
    },
    resetUserPassword: {
      resetPassword: resetUserPassword,
      isLoading: isResetPasswordLoading
    },
    googleOAuthLogin: {
      loginWithGoogle: googleOAuthLogin,
      isLoading: isOAuthLoading
    },
    updateAvatar: {
      updateUserAvatar,
      isLoading: isUpdateAvatarLoading
    },
    updateColor: {
      updateUserColor,
      isLoading: isUpdateColorLoading
    },
    updateProfile: {
      updateUserProfile,
      isLoading: isUpdateProfileLoading
    },
    localVerifyEmail,
    logOut
  }
}

export default useAuthActions;
