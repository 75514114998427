import {
  Modal as ChakraModal,
  ModalHeader,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  ModalCloseButton,
  Divider,
} from "@chakra-ui/react";

const boxShadow = `
    0px 1px 5px rgba(22, 0, 73, 0.05), 
    0px 7px 13px rgba(22, 0, 73, 0.08), 
    0px 17px 35px rgba(22, 0, 73, 0.11), 
    0px 33px 80px rgba(22, 0, 73, 0.16), 
    0px -11px 0px rgba(0, 12, 62, 0.05) inset
`;

const Modal = ({
  isOpen,
  onClose,
  size,
  showCloseButton,
  heading,
  footer,
  children,
  modalContainerProps,
  ...restProps
}) => {
  return (
    <ChakraModal
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      size={size}
      {...restProps}
    >
      <ModalOverlay />
      <ModalContent containerProps={modalContainerProps} borderRadius="12px" boxShadow={boxShadow}>
        {heading && (
          <ModalHeader fontWeight="600" fontSize="sm" color="dark.400">
            {heading}
          </ModalHeader>
        )}
        {showCloseButton && <ModalCloseButton />}
        {heading && <Divider />}
        <ModalBody pt="4" pb="4">
          {children}
        </ModalBody>
        {footer && <ModalFooter>{footer}</ModalFooter>}
      </ModalContent>
    </ChakraModal>
  );
};

export default Modal;
