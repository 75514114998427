const styles = {
  global: {
    html: {
      minHeight: "100%",
    },
    body: {
      minHeight: "100%",
      color: "gray.800",
      fontWeight: "normal",
      overflow: "hidden",
    },
    ".react-joyride__beacon": {
      display: "none !important",
    },
  },
};

export default styles;
