import { cssVar } from "@chakra-ui/react";
import colors from "@utils/theme/colors";

const $arrowBg = cssVar("popper-arrow-bg");
const $arrowShadowColor = cssVar("popper-arrow-shadow-color");

const Popover = {
  variants: {
    onboarding: {
      content: {
        width: "160px",
        background: "purple.500",
        color: "white",
        border: "none",
        padding: 2,
        fontSize: "0.875rem",
        [$arrowBg.variable]: colors.purple[500],
        [$arrowShadowColor.variable]: colors.purple[500],
      },
    },
  },
};

export default Popover;
