import { Box, Text } from "@chakra-ui/react";

const ProgressBar = ({ value, text, color, height }) => {
  return (
    <Box overflow="hidden" position="relative" bg="transparent" m="5px 0">
      <Box
        display="flex"
        alignItems="center"
        role="progressbar"
        aria-valuemax="100"
        aria-valuemin="0"
        aria-valuenow={value}
        minWidth={9}
        width={`${value > 0 ? value : 10}%`}
        height={height || 5}
        bg={color}
        borderRadius="full"
      >
        <Box minWidth={7} m="0 30px 0 10px">
          <Text fontSize="10px" fontWeight="800">
            {`${value}%`}
          </Text>
        </Box>
        <Text fontSize="10px" fontWeight="500" whiteSpace="nowrap">
          {text}
        </Text>
      </Box>
    </Box>
  );
};

export default ProgressBar;
