import { useCallback } from "react";
import { useInvite } from "@hooks";
import { Flex, Box } from "@chakra-ui/react";
import {
  UserCount,
  Tooltip,
  ShareMeetingWebLink,
  InviteButton,
} from "@components";
import { RiSendPlane2Line, RiShareLine } from "react-icons/ri";
import { WEB_INVITE_INFO, ZOOM_INVITE_INFO } from "@constants";

const TopSection = ({
  isUserHostOrCoHost,
  isMeetingActive,
  meetingParticipants,
  meetingInstance
}) => {
  const { handleSendZoomInvite } = useInvite();

  const isInviteAllowed = isUserHostOrCoHost && isMeetingActive;

  const sendZoomInvite = useCallback(() => {
    if (isInviteAllowed) {
      handleSendZoomInvite();
    }
  }, [isInviteAllowed, handleSendZoomInvite]);

  return (
    <>
      {isInviteAllowed ? (
        <Flex justifyContent="space-between" alignItems="center" mb={4}>
          <Flex>
            <ShareMeetingWebLink
              meetingInstance={meetingInstance}
              trigger={
                <Tooltip
                  label={WEB_INVITE_INFO}
                  hasArrow
                  trigger={
                    <InviteButton
                      icon={RiShareLine}
                      iconButtonProps={{ mr: 2 }}
                    />
                  }
                />
              }
            />
            <Tooltip
              label={ZOOM_INVITE_INFO}
              hasArrow
              trigger={
                <InviteButton
                  icon={RiSendPlane2Line}
                  onClick={sendZoomInvite}
                  iconProps={{ color: "blue.500" }}
                />
              }
            />
          </Flex>
          {isMeetingActive && <UserCount count={meetingParticipants.length} />}
        </Flex>
      ) : (
        <Box />
      )}
    </>
  );
};

export default TopSection;
