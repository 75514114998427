import { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { useGetAgendaReportQuery } from "@store/api/report";
import { Text } from "@chakra-ui/react";
import {
  EmptyState,
  ReportCard,
  ReportActivityItem,
  ReportLeaderboardItem,
  ReportButton,
} from "@components";
import { useSdkUtils, useToastNotification } from "@hooks";
import { isZoomApp } from "@utils/helpers";
import eReportEmptyImage from "@assets/e-report-empty.svg";
import eScoreIcon from "./svg/icon-escore.svg";
import activitiesIcon from "./svg/icon-activities.svg";
import boostIcon from "./svg/icon-boost.svg";
import leaderboardIcon from "./svg/icon-leaderboard.svg";

const MAX_REFETCH = 3;

const responseFields = [
  "title",
  "agendaStartTime",
  "reportSummary",
  "eScore",
  "activitiesParticipation",
  "boost",
  "activityReport",
  "leaderboard"
];

const EReport = ({ agendaId }) => {
  const refetchCount = useRef(0);
  const currentAgenda = useSelector((state) => state.agendas.currentAgenda);
  const eReport = useSelector((state) => state.report.eReport);
  const {
    isLoading: fetchingEReport, 
    isError: isFetchingEreportError,
    isSuccess: isFetchingReportSuccess
  } = useGetAgendaReportQuery(
    { 
      agendaId,
      include: responseFields
    },
    { 
      refetchOnMountOrArgChange: true,
      pollingInterval: 2000,
      skip: refetchCount.current >= MAX_REFETCH - 1
    }
  );

  const {
    title,
    startedOn,
    reportSummary,
    eScore,
    activitiesParticipation,
    boost,
    activityReport,
    leaderboard,
  } = eReport;
  const { showErrorToast } = useToastNotification();
  const { openUrl } = useSdkUtils();

  useEffect(() => {
    if (isFetchingReportSuccess) {
      refetchCount.current = MAX_REFETCH;
    }
  
    if (isFetchingEreportError) {
      refetchCount.current += 1;
    }
  }, [isFetchingEreportError, isFetchingReportSuccess]);

  const openExternalReport = async () => {
    try {
      const url = `${process.env.REACT_APP_ZOOMAPP_EREPORT_URL}/?agenda=${currentAgenda._id}`;
      await openUrl(url);
    } catch (error) {
      console.log(error);
      showErrorToast("Failed to download e-report");
    }
  };

  return !!currentAgenda.endTime && !fetchingEReport && eReport.title ? (
    <>
      <ReportCard summary={true} title={title} date={startedOn}>
        <Text variant="reportSummaryText">{reportSummary}</Text>
      </ReportCard>
      <ReportCard
        icon={leaderboardIcon}
        title="Townhall Top 5"
        tooltip="The Top 5 leaderboard shows which attendees interacted the most in Townhall for this meeting. Next time, change the meeting agenda and see if the leaderboard changes!"
      >
        {leaderboard?.length > 0
          ? leaderboard?.map((user, index) => (
              <ReportLeaderboardItem
                key={`${user.name}-${index}`}
                name={user.name}
                avatar={user.avatar}
                points={user.points}
              />
            ))
          : null}
      </ReportCard>
      <ReportButton
        isZoomApp={isZoomApp}
        link={`${process.env.REACT_APP_EREPORT_URL}/?agenda=${currentAgenda._id}`}
        onClick={() => openExternalReport()}
        text="View Full E-Report"
      />
      <ReportCard
        icon={eScoreIcon}
        title="E-Score"
        tooltip="This value highlights what percentage of attendees engaged at a Med or High level in a meeting."
      >
        <Text variant="reportPercentage">{eScore}%</Text>
        <Text variant="reportText">Medium or Strong Engagement</Text>
      </ReportCard>
      <ReportCard
        icon={activitiesIcon}
        title="Activities"
        tooltip="This value highlights what percentage of attendees participated in at least one activity. "
      >
        <Text variant="reportPercentage">{activitiesParticipation}%</Text>
        <Text variant="reportText">Participated in at Least One Activity</Text>
      </ReportCard>
      <ReportCard
        icon={boostIcon}
        title="Boost"
        tooltip="This value highlights the maximum E-Score boost for activities. Higher values mean the activities were effective at boosting engagement"
      >
        <Text variant="reportPercentage">{boost}%</Text>
        <Text variant="reportText">Highest increase in E-Score</Text>
      </ReportCard>
      {
        activityReport?.length > 0 ? (
          <ReportCard
            title="Activities"
            tooltip="This section details the impact of activities. Identify which activities given boosted engagement the most."
          >
            {activityReport?.map((activity, index) => {
              const text = activity.boost === 0
                ? "N/A - Activity ran for < 1 min"
                : `${activity.boost}% E-Score boost`;

              return (
                <ReportActivityItem
                  key={`${activity.type}-${index}`}
                  type={activity.type}
                  title={activity.title}
                  text={text}
                />
              );
            })}
          </ReportCard>
        ) : null
      }
    </>
  ) : (
    <EmptyState
      imgSrc={eReportEmptyImage}
      imgProps={{ m: "0 auto", mt: 3 }}
      text="Your don't have a report yet, check back later."
      textProps={{
        color: "dark.100",
        textAlign: "center",
        mt: "5",
      }}
    />
  );
};

export default EReport;
