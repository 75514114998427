import { useField } from "formik";
import {
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
  Icon,
} from "@chakra-ui/react";
import { IoCheckmarkCircleSharp, IoAlertCircleSharp } from "react-icons/io5";

const AuthInput = ({
  type,
  name,
  value,
  placeholder,
  requireValidation,
  readOnly,
  helperText,
  showSuccessElements,
}) => {
  const [field, meta] = useField(name);
  const { onChange, onBlur } = field;
  const isInvalid = requireValidation && meta.touched && meta.error;
  const isValid = requireValidation && meta.touched && !meta.error;

  return (
    <FormControl isInvalid={isInvalid} isRequired={requireValidation}>
      <FormLabel htmlFor={name} variant="auth">
        {placeholder}
      </FormLabel>
      <InputGroup>
        <Input
          name={name}
          type={type}
          placeholder={placeholder}
          value={value}
          readOnly={readOnly}
          onChange={onChange}
          onBlur={onBlur}
          variant="auth"
        />
        {isInvalid && (
          <InputRightElement w="3rem">
            <Icon as={IoAlertCircleSharp} color="error" />
          </InputRightElement>
        )}
        {isValid && showSuccessElements && (
          <InputRightElement w="3rem">
            <Icon as={IoCheckmarkCircleSharp} color="success" />
          </InputRightElement>
        )}
      </InputGroup>
      {isInvalid && (
        <FormErrorMessage color="error" fontSize={12}>
          {meta.error}
        </FormErrorMessage>
      )}
      {!isInvalid && helperText && (
        <FormHelperText color="blue.500" fontSize={12}>
          {helperText}
        </FormHelperText>
      )}
    </FormControl>
  );
};

AuthInput.defaultProps = {
  type: "text",
  readOnly: false,
};

export default AuthInput;
