import { memo } from "react";
import { Routes, Route } from "react-router-dom";
import { useSelector } from "react-redux";
import { routeConfig } from "@routes";
import { ElementBuilder } from "@routes/ElementBuilder";
import { isZoomApp } from "@utils/helpers";

const AppRoutes = memo(() => {
  const licenseType = useSelector((state) => state.profile.data?.licenseType);

  const routes = Object.values(routeConfig).map((config) => {
    const element = new ElementBuilder(config, licenseType)
      .withOwnContainer()
      .withAuthContainer()
      .withProContainer()
      .withConditionalRender(isZoomApp)
      .build();

      return <Route key={config.path} path={config.path} element={element} />;
    });

    return <Routes>{routes}</Routes>;
});

const SubRoutes = memo(({ routeName }) => {
  const subpages = routeConfig[routeName]?.subPages;

  if (!subpages) {
    return null;
  }

  const routes = Object.values(subpages)
    .map(({ path, component: Component }) => {
      return <Route key={path} path={path} element={<Component />} />;
    });

  return <Routes>{routes}</Routes>;
});

export { AppRoutes, SubRoutes };
