import { useSidebar, useUserOnboarding } from "@hooks";
import { Flex, Image, IconButton, Icon } from "@chakra-ui/react";
import { BiChevronLeft } from "react-icons/bi";
import sidebarToggleButtonImage from "@assets/sidebar-curve.svg";
import { UserOnboarding } from "@components";

const SidebarButton = () => {
  const { isSidebarOpen, handleSidebarToggle } = useSidebar();
  const { runTour, stepIndex, handleTooltipCallback } = useUserOnboarding();

  return (
    <Flex position="relative" width="27px">
      <Image maxW="27px" mt="-350px" src={sidebarToggleButtonImage} />
      <UserOnboarding
        placement="left"
        isOpen={runTour && stepIndex === 2}
        index={stepIndex}
        onClick={handleTooltipCallback}
      >
        <IconButton
          variant="ghostIconButton"
          icon={
            <Icon as={BiChevronLeft} color="purple.25" w="1.75em" h="1.75em" />
          }
          _hover={{ opacity: 1 }}
          opacity="0.5"
          position="absolute"
          top="50%"
          margin="-180px 0px 0 -5px"
          width="1.5em"
          height="1.5em"
          transform={isSidebarOpen ? "rotate(180deg)" : "rotate(0deg)"}
          transition="transform 0.3s cubic-bezier(0.820, 0.085, 0.395, 0.895)"
          onClick={handleSidebarToggle}
        />
      </UserOnboarding>
    </Flex>
  );
};

export default SidebarButton;
