import { Box, Flex, Text, chakra } from "@chakra-ui/react";
import { Avatar } from "@components";

const ReportLeaderboardItem = ({ avatar, name, points }) => {
  return (
    <Box p="9px" bg="gray.50" borderRadius="6px" mb="1">
      <Flex alignItems="center" justifyContent="space-between">
        <Flex alignItems="center">
          <Avatar
            mr="1"
            size="xs"
            avatar={avatar}
            rounded={true}
            darkIcon={true}
          />
          <Text variant="reportLeaderboardText">{name}</Text>
        </Flex>
        <Text variant="reportLeaderboardText">
          {points.toLocaleString()}
          <chakra.sup ml="1">pts</chakra.sup>
        </Text>
      </Flex>
    </Box>
  );
};

export default ReportLeaderboardItem;
