import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { customBaseQuery } from "@store/api/baseQueries";

export const notificationsApi = createApi({
  reducerPath: "notificationsApi",
  baseQuery: customBaseQuery(
    fetchBaseQuery({
      baseUrl: `${process.env.REACT_APP_ZOOMAPP_BACKEND_BASE_URL}/notification`,
    }),
  ),
  endpoints: (builder) => {
    return {
      getAllNotifications: builder.query({ query: () => "/" }),
      postNotification: builder.mutation({
        query: (body) => ({
          url: "/",
          method: "POST",
          body,
        }),
        transformResponse: (response) => response.notifications,
      }),
      deleteNotification: builder.mutation({
        query: (body) => ({
          url: "/delete",
          method: "POST",
          body,
        }),
      }),
    };
  },
});

export const {
  useGetAllNotificationsQuery,
  usePostNotificationMutation,
  useDeleteNotificationMutation,
} = notificationsApi;
