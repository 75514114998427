import {
  Popover as ChakraPopover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverCloseButton,
  PopoverHeader,
  PopoverTrigger,
} from "@chakra-ui/react";

const Popover = ({
  placement,
  trigger,
  button,
  heading,
  body,
  isOpen,
  onClose,
}) => {
  return (
    <ChakraPopover
      placement={placement}
      trigger={trigger}
      lazyBehavior="unmount"
      isLazy
      isOpen={isOpen}
      onClose={onClose}
    >
      <PopoverTrigger>{button}</PopoverTrigger>
      <PopoverContent w={{ sm: "280px", md: "330px" }} borderColor="purple.25">
        <PopoverArrow />
        <PopoverCloseButton color="dark.100" />
        <PopoverHeader
          display="flex"
          alignItems="center"
          fontWeight="700"
          fontSize="12px"
          color="dark.400"
        >
          {heading}
        </PopoverHeader>
        <PopoverBody>{body}</PopoverBody>
      </PopoverContent>
    </ChakraPopover>
  );
};

export default Popover;
