import { connectSocket, disconnectSocket } from "@socket/utils";
import { isZoomApp } from "@utils/helpers"
import { APP_TYPE } from "@constants";

const appType = isZoomApp ? APP_TYPE.ZoomApp : APP_TYPE.WebApp;


const initiateSocket = (userId, meetingInstanceId) => {
  connectSocket(process.env.REACT_APP_ZOOMAPP_SOCKET_IO_URL, {
    auth: {
      userId,
      meetingInstanceId,
      appType
    },
    withCredentials: true
  })
};

export * from "./listeners";
export * from "./emitters";
export { initiateSocket, disconnectSocket };
