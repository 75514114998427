import { useEffect, useState } from "react";
import { useSidebar } from "@hooks";
import { Box, Fade } from "@chakra-ui/react";
import { useResizeDetector } from "react-resize-detector";
import {
  Participants,
  Engagement,
  SidebarProfile,
  Tabs,
  News,
  NotificationCount,
} from "@components";
import { useMeeting, useNotifications } from "@hooks";
import { displayEscore } from "@utils/helpers";
import { SIDEBAR_WIDTH } from "@constants";

const ScrollableWrapper = ({ children }) => (
  <Box overflow-y="scroll" height="100%">
    {children}
  </Box>
);

const SidebarOpen = ({ participants, guests, hostId, points, coins, eScore, profile }) => {
  const { isMeetingActive } = useMeeting();
  const { notifications } = useNotifications();
  const [showItems, setShowItems] = useState(false);
  const { tabIndex, handleTabIndex, isSidebarOpen } = useSidebar();
  const { height: engagementContainerHeight, ref: engagementContainerRef } = useResizeDetector();
  const tabsData = [
    {
      id: 0,
      label: "Neighbors",
      content: (
        <ScrollableWrapper>
          <Participants participants={participants} guests={guests} hostId={hostId} isGridView={isSidebarOpen} />
        </ScrollableWrapper>
      ),
    },
    {
      id: 1,
      label: <NotificationCount label="News" count={notifications.length} />,
      content: <ScrollableWrapper><News /></ScrollableWrapper>,
    },
    { id: 2, label: "Profile", content: <SidebarProfile /> },
  ];

  useEffect(() => {
    if (isSidebarOpen) {
      let timer = setTimeout(() => setShowItems(true), 300);
      return () => clearTimeout(timer);
    }
  }, [isSidebarOpen]);

  return (
    showItems && (
      <Fade in={isSidebarOpen} style={{ height: "100%"}}>
        {/* 200px icon section + 12px space added by Fade component = 212px */}
        <Box height="calc(100% - 212px)">
          <Box margin="0.75rem" ref={engagementContainerRef}>
            <Engagement
              points={points?.toLocaleString()}
              coins={coins?.toLocaleString()}
              score={displayEscore(isMeetingActive, eScore)}
              inline={false}
            />
          </Box>
          <Box
            margin="0.75rem"
            position="relative"
            width={`calc(${SIDEBAR_WIDTH} - 1.5rem)`}
            //  12px top and 12px bottom marging (0.75rem) = 24px
            height={`calc(100% - 24px - ${engagementContainerHeight || 81}px)`}
          >
            <Tabs
              tabs={tabsData}
              variant="sidebar"
              index={tabIndex}
              onChange={handleTabIndex}
              isFitted={true}
              tabsProps={{ height: "100%" }}
              tabPanelProps={{ height: "100%", padding: "10px 0 0 0" }}
              tabPanelWrapperProps={{ height: "calc(100% - 43px)" }}
            />
          </Box>
        </Box>
      </Fade>
    )
  );
};

export default SidebarOpen;
