import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  useJoinMeetingFromWebMutation,
  useLeaveMeetingFromWebMutation,
  useCheckWebUserLiveMeetingMutation,
} from "@store/api/meeting";
import { useHandleApiCall } from "@hooks";
import { USER_MEETING_INFO_KEY } from "@constants";
import { $Routes } from "@routes";
import {
  showErrorToast,
  saveDataToLocalStorage,
  removeDataFromLocalStorage
} from "@store/customActions";
import { encodeObject } from "@utils/helpers";


const useMeetingActions = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [joinMeetingFromWeb] = useJoinMeetingFromWebMutation();
  const [checkWebUserLiveMeeting] = useCheckWebUserLiveMeetingMutation();
  const [leaveMeetingFromWeb] = useLeaveMeetingFromWebMutation();
  const { handleApiCall } = useHandleApiCall();

  const userJoinMeetingFromWeb = useCallback(async (data) => {
    handleApiCall({
      data,
      endpoint: joinMeetingFromWeb,
      onSuccess: () => {
        const hashedUserMeetingInfo = encodeObject(data);
        dispatch(saveDataToLocalStorage({
          key: USER_MEETING_INFO_KEY,
          value: hashedUserMeetingInfo
        }));
        navigate($Routes.rootPage, { state: { data } });
      },
      onError: (error) => {
        dispatch(removeDataFromLocalStorage(USER_MEETING_INFO_KEY));
        dispatch(
          showErrorToast(
            error?.data?.message || "We cannot connect you to this meeting. Ensure you're using the right invite link"
          )
        );
        navigate($Routes.rootPage);
      }
    });
  }, [dispatch, handleApiCall, joinMeetingFromWeb, navigate]);

  const checkUserLiveMeeting = useCallback((data) => {
    handleApiCall({
      data,
      endpoint: checkWebUserLiveMeeting,
      onError: (error) => {
        dispatch(removeDataFromLocalStorage(USER_MEETING_INFO_KEY));
      }
    });
  }, [handleApiCall, checkWebUserLiveMeeting, dispatch]);

  const webUserLeaveMeeting = useCallback((data) => {
    handleApiCall({
      data,
      endpoint: leaveMeetingFromWeb,
    });
  }, [handleApiCall, leaveMeetingFromWeb]);

  return {
    checkUserLiveMeeting,
    webUserLeaveMeeting,
    joinMeetingFromWeb: userJoinMeetingFromWeb,
  };
};

export default useMeetingActions;
