import { Icon, IconButton } from "@chakra-ui/react";

const VoteIcon = ({ selected, onClick }) => {
  return (
    <IconButton
      size="xs"
      variant="ghost"
      aria-label="Vote"
      onClick={onClick}
      icon={
        <Icon viewBox="0 0 12 15" w="12" h="15" width="4">
          <path
            d="M10.8375 6.31406C10.5 5.93906 10.05 5.75156 9.5625 5.75156H6.1125C6.0375 5.75156 6 5.71406 5.9625 5.67656C5.925 5.60156 5.925 5.52656 5.9625 5.45156C6.2625 4.77656 6.45 4.17656 6.6 3.42656C6.7125 3.01406 6.75 2.75156 6.75 2.48906C6.75 1.40156 5.925 0.989062 5.5125 0.914062C5.4375 0.914062 5.3625 0.914063 5.325 0.989063C5.2875 1.02656 2.925 4.43906 1.9875 5.37656C1.2 6.12656 0.75 7.06406 0.75 8.00156V10.4766C0.75 12.6141 2.5125 14.3766 4.6875 14.3766H8.175C9.4125 14.3766 10.425 13.4766 10.6125 12.2391L11.25 7.66406C11.325 7.17656 11.175 6.68906 10.8375 6.31406Z"
            fill={selected ? "#0BCEBB" : "transparent"}
            stroke={selected ? "transparent" : "#AAAEBE"}
          />
        </Icon>
      }
      _hover={{
        bg: "transparent",
      }}
      _active={{
        bg: "transparent",
      }}
    />
  );
};

export default VoteIcon;
