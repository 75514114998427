import React from "react";
import { useSelector } from "react-redux";
import { Flex, Button } from "@chakra-ui/react";

const QuizModalFooter = ({
  isValidInput,
  handleSubmit,
}) => {
  const showResult = useSelector((state) => state.activity.showResult);
  return showResult ? null : (
    <Flex justifyContent="flex-end">
      <Button
        variant="primary"
        isFullWidth
        onClick={handleSubmit}
        disabled={!isValidInput}
      >
        Submit
      </Button>
    </Flex>
  );
}

export default QuizModalFooter;
