import React from "react";
import { Heading, Text, Image, Flex, Box } from "@chakra-ui/react";
import { ActivityVote } from "@components";

const ActivityTypesItem = ({
  title,
  icon,
  description,
  vote,
  handleOpen,
}) => {
  return (
    <Flex
      pos="relative"
      w="100%"
      p={3}
      cursor="pointer"
      alignItems="center"
      _hover={{
        background:
          "linear-gradient(268.62deg, rgba(137, 18, 202, 0.1) -50.64%, rgba(62, 0, 234, 0) 73.74%)",
      }}
      onClick={() => handleOpen()}
    >
      {vote && (
        <Box pos="absolute" right={1} top="4px">
          <ActivityVote />
        </Box>
      )}
      <Image mr="12px" src={icon} width="35px" height="35px" />
      <Flex direction="column">
        <Heading as="h4" fontSize="13px" fontWeight="600" color="dark.400">
          {title}
        </Heading>
        <Text fontSize="10px" fontWeight="400" color="dark.200">
          {description}
        </Text>
      </Flex>
    </Flex>
  )
}

export default ActivityTypesItem;
