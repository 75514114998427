import React, { useCallback, useState } from "react";
import { Text, Button, Flex } from "@chakra-ui/react";
import { HostCard, Tooltip } from "@components";
import { useAgendaActions } from "@hooks";
import SubscriptionModal from "@components/Agenda/SubscriptionModal";
import useProfile from "@hooks/useProfile";
import { showErrorToast } from "@store/customActions";
import { useDispatch } from "react-redux";
import { DEFAULT_ERROR_GOING_LIVE_MESSAGE } from "@constants/errorMessages";

const INFO_TEXT = "Get engagement feedback from your audience in real-time! No camera required.";
const INSTANT_START_TOOLTIP_TEXT = "Host a meeting to start tracking engagement";

const StartTracking = ({
  isUserHostOrCoHost,
  isMeetingActive,
  meetingInstanceId,
  isLiveAgenda
}) => {
  const dispatch = useDispatch();
  const [subscriptionModalOpen, setSubscriptionModalOpen] = useState(false)
  const { profile } = useProfile();
  const { instantStart: { isLoading, handleInstantStart } } = useAgendaActions();

  const goLive = useCallback(async () => {
    handleInstantStart({
      meetingInstanceId,
      onError: (error) => {
        const message = error?.data?.message;
        const isSubscriptionError =
          message === "SUBSCRIPTION_ITEM_USAGE_LIMIT" ||
          message === "SUBSCRIPTION_ITEM_UNAVAILABLE";

        if (isSubscriptionError) {
          setSubscriptionModalOpen(true);
          return;
        }
        dispatch(showErrorToast(error.message || DEFAULT_ERROR_GOING_LIVE_MESSAGE));
      },
    });
  }, [meetingInstanceId]);

  return (
    <HostCard>
      <Flex alignItems="center" justifyContent="center" mb={4}>
        <Text mr={2} fontWeight="600" fontSize="sm">Start tracking engagement!</Text>
        <Tooltip label={INFO_TEXT} hasArrow position="auto" />
      </Flex>
      <SubscriptionModal
          isOpen={subscriptionModalOpen}
          onClose={() => setSubscriptionModalOpen(false)}
          licenseType={profile.licenseType}
        />
      <Tooltip
        label={!isMeetingActive ? INSTANT_START_TOOLTIP_TEXT : ""}
        hasArrow
        position="auto"
        trigger={
          <Button
            variant="success"
            size="sm"
            isFullWidth={true}
            isLoading={isLoading}
            onClick={goLive}
            isDisabled={!isMeetingActive || isLiveAgenda || !isUserHostOrCoHost}
          >
            Instant Start
          </Button>
        }
      />
    </HostCard>
  )
}

export default StartTracking;
