import { Box, Text, Icon } from "@chakra-ui/react";
import { useSelector } from "react-redux";
import { ProgressAnswerBar } from "@components/ActivityTypes/common";
import { getActivityAnswers } from "@utils/helpers";
import { IoCheckmarkCircleSharp } from "react-icons/io5";

const PollResult = () => {
  const response = useSelector((state) => state.activity.response);
  const options = useSelector((state) => state.activity.options);
  const userResponse = useSelector((state) => state.activity?.userResponse);

  const answerArray = getActivityAnswers(
    options,
    response,
    (response, option) => response.responseIds.includes(option._id),
    response.length
  );

  return (
    <Box p={2}>
      {answerArray.map((option) => (
        <ProgressAnswerBar
          key={option.id}
          option={option}
          userResponse={userResponse}
        />
      ))}
      <Box mt="5" borderRadius="4px" bg="green.50" p="1">
        <Text textAlign="center" color="green.700" fontWeight="600">
          Great Job{" "}
          <Icon
            as={IoCheckmarkCircleSharp}
            color="green.400"
            w={3}
            h={3}
            ml={1}
          />
        </Text>
      </Box>
    </Box>
  );
};

export default PollResult;
