import React from "react";
import { Link } from "react-router-dom";
import { MenuItem, Text, Icon } from "@chakra-ui/react";

const NavbarLink = ({ href, icon, text, onClick }) => {
  const menuItem = (
    <MenuItem
      py="14px"
      w="95%"
      margin="0 auto"
      _hover={{
        bg: "dark.500",
        borderRadius: "6px",
      }}
      { ...(onClick ? { onClick } : {}) }
    >
      {icon ? <Icon as={icon} color="gray.300" w="18px" h="18px" mr="15px" /> : null}
      <Text color="gray.100" fontWeight={600} fontSize="16px">
        {text}
      </Text>
    </MenuItem>
  );

  return href ? <Link to={href}>{menuItem}</Link> : menuItem;
}

export default NavbarLink;
