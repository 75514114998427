import { Box, Image, Text } from "@chakra-ui/react";
import { ConditionalWrapper } from "@components";

const EmptyState = ({ imgSrc, text, container, ...restProps }) => {
  return (
    <ConditionalWrapper
      condition={container}
      wrapper={(children) => (
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          mt="24px"
          p="14px"
          borderRadius="8px"
          {...restProps.containerProps}
        >
          {children}
        </Box>
      )}
    >
      <>
        <Image src={imgSrc} alt={text} {...restProps.imgProps} />
        <Text fontSize="12px" {...restProps.textProps}>
          {text}
        </Text>
      </>
    </ConditionalWrapper>
  );
};

export default EmptyState;
