import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { customBaseQuery } from "@store/api/baseQueries";

export const paymentsApi = createApi({
  reducerPath: "paymentsApi",
  baseQuery: customBaseQuery(
    fetchBaseQuery({
      baseUrl: `${process.env.REACT_APP_ZOOMAPP_BACKEND_BASE_URL}/payments`,
    }),
  ),
  endpoints: (builder) => {
    return {
      validate: builder.mutation({
        query: (body) => ({
          url: "/validateAccessCode",
          method: "PUT",
          body,
        }),
      }),
    };
  },
});

export const { useValidateMutation } = paymentsApi;
