import { Flex, Icon, Image, Text, Heading } from "@chakra-ui/react";
import { BiChevronRight } from "react-icons/bi";

const CardButton = ({ icon, heading, description, handleClick }) => {
  return (
    <Flex
      role="button"
      borderRadius="8px"
      p="18px"
      bg="white"
      boxShadow="sm"
      h="175px"
      w={{ base: "full", sm: "300px" }}
      _hover={{
        cursor: "pointer",
        boxShadow: "lg",
        transition: "all .2s ease-in-out",
      }}
      onClick={handleClick}
    >
      <Flex direction="column">
        <Image boxSize="10" src={icon} mb="12px" />
        <Heading fontSize="md" fontWeight="700" color="dark.500" mb="12px">
          {heading}
        </Heading>
        <Text color="dark.200">{description}</Text>
      </Flex>
      <Icon color="dark.200" as={BiChevronRight} />
    </Flex>
  );
};

export default CardButton;
