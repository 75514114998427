import { createSlice } from "@reduxjs/toolkit";
import { meetingApi } from "@store/api/meeting";

const initialState = {
  isSidebarOpen: false,
  tabIndex: 0,
  participantView: "avatar",
  profile: {},
};

export const sidebarSlice = createSlice({
  name: "sidebar",
  initialState,
  reducers: {
    toggleSidebar: (state) => {
      state.isSidebarOpen = !state.isSidebarOpen;
    },
    toggleParticipantView: (state, action) => {
      state.participantView = action.payload;
    },
    toggleTabIndex: (state, action) => {
      state.tabIndex = action.payload;
    },
    loadProfile: (state, action) => {
      state.profile = action.payload;
    },
    openSidebar: (state) => {
      state.isSidebarOpen = true;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      meetingApi.endpoints.checkExistingMeeting.matchFulfilled,
      (state, { payload }) => {
        if (payload.meetingInstances[0]?.agenda?.liveTools?.length) {
          state.isSidebarOpen = true;
          state.tabIndex = 1;
        }
      }
    );
  },
});

const { reducer, actions } = sidebarSlice;

export const {
  toggleSidebar,
  toggleParticipantView,
  toggleTabIndex,
  loadProfile,
  openSidebar,
} = actions;

export default reducer;
