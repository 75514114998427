import dayjs from "dayjs";
import { useState, useMemo } from "react";
import { useSelector } from "react-redux";
import { useHandleIconClick, useAuth, useMeeting } from "@hooks";
import {
  Box,
  Input,
  InputLeftElement,
  InputGroup,
  InputRightElement,
  CloseButton,
  Flex,
  useToken,
} from "@chakra-ui/react";
import { IconPickerCategory } from "@components/IconPicker";
import {
  getFromLocalStorage,
  saveToLocalStorage,
  removeFromLocalStorage,
} from "@utils/localStorage";
import { timeInFutureFromNow } from "@utils/helpers";
import {
  MAX_ABUSE_CLICK,
  TOP_HEIGHT,
  CONTENT_HEIGHT,
  POLL_ICONS_ORDER,
  ICON_SPAM_COUNT_TOKEN,
  ICONS_UNLOCK_TIME_TOKEN,
  OFFENSE_COUNT,
} from "@constants";
import { IoSearch } from "react-icons/io5";

const IconPickerContainer = ({ handleOpenAbuseModal }) => {
  const [dark500] = useToken("colors", ["dark.500"]);
  const icons = useSelector((state) => state.icons.data);
  const isLoading = useSelector((state) => state.icons.isLoading);
  const { broadcastIconClick, increaseIconUsage, setIconCategory } =
    useHandleIconClick(icons);
  const { profile } = useAuth();
  const { isMeetingActive } = useMeeting();

  const meetingData = useSelector((state) => state.meeting.data?.meetingObject);
  const userId = useSelector((state) => state.profile.data._id);

  const [iconSearchQuery, setIconSearchQuery] = useState("");
  const [clickCount, setClickCount] = useState(1);

  const moodIcons = setIconCategory("mood");
  const statusIcons = setIconCategory("status");
  const vibeIcons = setIconCategory("vibe");
  const pollIcons = setIconCategory("poll");

  const orderedPollIcons = useMemo(() => {
    const orderedArray = pollIcons.sort((a, b) => {
      const indexOfA = POLL_ICONS_ORDER.indexOf(a.name);
      const indexOfB = POLL_ICONS_ORDER.indexOf(b.name);

      return indexOfA - indexOfB;
    });

    return orderedArray;
  }, [pollIcons]);

  const allIconsFiltered = useMemo(
    () =>
      icons?.all.filter((currentIcon) =>
        currentIcon.name.includes(iconSearchQuery.toLowerCase())
      ) || [],
    [iconSearchQuery, icons]
  );

  const searchTitle =
    !allIconsFiltered.length && iconSearchQuery.length
      ? `No search results for "${iconSearchQuery}"`
      : `Search "${iconSearchQuery}"`;

  const handleIconSearchInput = (event) => {
    setIconSearchQuery(event.target.value);
  };

  const handleIconAbuse = (now, accountUnlockTime) => {
    if (accountUnlockTime && now.isAfter(dayjs(accountUnlockTime))) {
      removeFromLocalStorage(ICON_SPAM_COUNT_TOKEN);
      removeFromLocalStorage(ICONS_UNLOCK_TIME_TOKEN);
    } else if (accountUnlockTime) {
      handleOpenAbuseModal();
      return;
    }

    if (isMeetingActive && clickCount >= MAX_ABUSE_CLICK) {
      let currentIconSpamCount = getFromLocalStorage(ICON_SPAM_COUNT_TOKEN);

      currentIconSpamCount += 1;
      saveToLocalStorage(ICON_SPAM_COUNT_TOKEN, currentIconSpamCount);

      if (currentIconSpamCount === OFFENSE_COUNT) {
        saveToLocalStorage(ICONS_UNLOCK_TIME_TOKEN, timeInFutureFromNow(now));
      }

      handleOpenAbuseModal();
    }
  };

  const handleIcon = (iconId) => {
    const { color } = profile;
    const now = dayjs();
    const accountUnlockTime = getFromLocalStorage(ICONS_UNLOCK_TIME_TOKEN);

    if (accountUnlockTime && now.isAfter(dayjs(accountUnlockTime))) {
      removeFromLocalStorage(ICON_SPAM_COUNT_TOKEN);
      removeFromLocalStorage(ICONS_UNLOCK_TIME_TOKEN);
    } else if (accountUnlockTime) {
      handleOpenAbuseModal();
      return;
    }

    handleIconAbuse(now, accountUnlockTime);
    setClickCount(clickCount + 1);
    broadcastIconClick({
      iconId,
      color,
      meetingInstanceUuid: meetingData?.meetingInstances[0]?._id,
      userId,
    });

    // TODO: This is not very accurate because what happens when one clicks different icons in the
    // debounce timeframe? The iconId will not be the true val, because it'll only rep the last clicked icon
    // we need something like managing the state of the currently clicked icons in that timeframe
    // [ {iconId: string, clickCount: number} ] - should be ideal thing sent to the backend
    increaseIconUsage({
      iconId,
      color,
      usageCount: clickCount,
      meetingInstanceUuid: meetingData?.meetingInstances[0]?._id,
      userId,
      setClickCount,
    });
  };

  return (
    <Flex
      direction="column"
      backgroundColor="dark.800"
      width="100%"
      height="200px"
      bottom="0"
      borderRadius="18px 18px 0 0"
      boxShadow={`0px -3px 1px ${dark500}`}
    >
      <Box padding="10px" height={TOP_HEIGHT}>
        <InputGroup size="sm">
          <InputLeftElement
            pointerEvents="none"
            children={<IoSearch color="white" />}
          />
          <Input
            size="sm"
            fontSize="sm"
            value={iconSearchQuery}
            onChange={handleIconSearchInput}
            placeholder="Search Reactions"
            variant="iconPickerContainer"
          />
          {iconSearchQuery.length > 0 ? (
            <InputRightElement>
              <CloseButton
                color="gray.400"
                size="sm"
                onClick={() => setIconSearchQuery("")}
              />
            </InputRightElement>
          ) : null}
        </InputGroup>
        <Box
          overflowY="scroll"
          height={CONTENT_HEIGHT}
          sx={{
            "&::-webkit-scrollbar": {
              display: "none",
            },
          }}
        >
          {iconSearchQuery.length > 0 ? (
            <IconPickerCategory
              title={searchTitle}
              icons={allIconsFiltered}
              divider={true}
              handleIcon={handleIcon}
              isLoading={isLoading}
            />
          ) : null}
          <IconPickerCategory
            title="Frequently Used"
            icons={icons?.favs}
            divider={true}
            handleIcon={handleIcon}
            isLoading={isLoading}
          />
          <IconPickerCategory
            title="Mood"
            icons={moodIcons}
            divider={true}
            handleIcon={handleIcon}
            isLoading={isLoading}
          />
          <IconPickerCategory
            title="Status"
            icons={statusIcons}
            divider={true}
            handleIcon={handleIcon}
            isLoading={isLoading}
          />
          <IconPickerCategory
            title="Vibe"
            icons={vibeIcons}
            divider={true}
            handleIcon={handleIcon}
            isLoading={isLoading}
          />
          <IconPickerCategory
            title="Poll"
            icons={orderedPollIcons}
            divider={false}
            handleIcon={handleIcon}
            isLoading={isLoading}
          />
        </Box>
      </Box>
    </Flex>
  );
};

export default IconPickerContainer;
