const Alert = {
  baseStyle: {
    container: {
      borderRadius: "4px",
      py: "5px",
      my: "2",
      paddingInline: "5px",
    },
    description: {
      fontSize: "small",
    },
  },
  variants: {
    guest: {
      container: {
        background: "gray.100",
        color: "white",
      },
      description: {
        color: "dark.400",
      },
    },
  },
};

export default Alert;
