import { useCallback } from "react";
import { useFormik } from "formik";
import { useValidation } from "@hooks";
import { mergeInitialValues } from "@utils/helpers";

const useForm = (
  schema,
  initialValuesOverride = {},
  errorCheckFunction,
  additionalOptions = { enableReinitialize: true }
) => {
  const { initialValues, validationSchema } = useValidation(schema);
  const formik = useFormik({
    validationSchema,
    initialValues: mergeInitialValues(initialValues, initialValuesOverride),
    ...additionalOptions,
  });

  const { handleChange, values, errors, touched, handleBlur, setFieldTouched } = formik;

  const hasError = !(Object.keys(errors).length === 0 && Object.values(values).every((val) => val));

  const handleInputChange = useCallback((event) => {
    setFieldTouched(event.target.name, true, false);
    handleChange(event);
  }, [setFieldTouched, handleChange])

  const setInputProps = useCallback(({
    name,
    variant = "primary",
    inputProps = {},
    requireValidation = false,
    validateOnChange = false
  }) => ({
    variant,
    autoCapitalize: "none",
    requireValidation,
    name: name,
    value: values[name],
    error: errors[name],
    onChange: validateOnChange ? handleInputChange : handleChange,
    onBlur: handleBlur,
    isInvalid: !!(touched[name] && errors[name]),
    ...inputProps
  }), [handleBlur, handleChange, handleInputChange, values, errors, touched]);

  return {
    ...formik,
    hasError: errorCheckFunction ? errorCheckFunction(values, errors, touched) : hasError,
    setInputProps
  };
};

export default useForm;
