import { useState, useEffect } from "react";
import {
  Avatar,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Box,
  Image,
  Text,
  CircularProgress,
  CircularProgressLabel,
  VStack,
  useToken,
} from "@chakra-ui/react";
import { useSelector } from "react-redux";
import { Card, CardFooter } from "@components";
import { activityTitles } from "@constants/activities";
import hostIcon from "@assets/svg/host-status.svg";
import confettiBgImage from "@assets/images/confetti.gif";
import avatarDefaultIcon from "@assets/avatar-default.svg";

const ActivityPrepModal = () => {
  const [purple900] = useToken("colors", ["purple.900"]);
  const [progressValue, setProgressValue] = useState(20);
  const [timeLeft, setTimeLeft] = useState(5);
  const activity = useSelector((state) => state.activity);
  const activityTitle = activityTitles[activity.liveActivity.type];
  const meetingData = useSelector((state) => state.meeting.data);
  const host = meetingData?.meetingHost;

  useEffect(() => {
    if (progressValue < 100) {
      const interval = setInterval(() => {
        setProgressValue((prev) => prev + 20);
        setTimeLeft((prev) => prev - 1);
      }, 1000);

      return () => clearInterval(interval);
    }
  }, [progressValue]);

  return (
    <Modal closeOnOverlayClick={false} isOpen={true} isCentered size="xs">
      <ModalOverlay />
      <ModalContent bg="transparent">
        <ModalBody>
          <Card
            p={4}
            border="none"
            borderRadius="10px 10px 0 0"
            backgroundColor="purple.900"
            background={`url(${confettiBgImage}) no-repeat top center / contain ${purple900}`}
            footer={<CardFooter p={1} background="purple.900" border="none" />}
          >
            <VStack flexDirection="column" alignItems="center">
              <Box position="relative">
                <Image
                  src={hostIcon}
                  position="absolute"
                  top="0"
                  right="0"
                  zIndex="1"
                />
                <Avatar
                  size="md"
                  src={host?.avatar}
                  mr="2"
                  borderRadius="xl"
                  icon={<Image src={avatarDefaultIcon} />}
                  bg={host?.color}
                />
              </Box>
              <Text
                align="center"
                fontWeight="600"
                fontSize="x-large"
                color="white"
              >
                Get Ready
              </Text>
              <Text align="center" fontWeight="600" fontSize="lg" color="white">
                {`${activityTitle} is starting...`}
              </Text>
              <CircularProgress value={progressValue} size="120px">
                <CircularProgressLabel color="white" fontWeight="600">
                  {timeLeft}
                </CircularProgressLabel>
              </CircularProgress>
            </VStack>
          </Card>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ActivityPrepModal;
