import { CircularProgress, Text, Flex, Box } from "@chakra-ui/react";

const CharacterCounter = ({ count, max }) => {
  const color = count === max
    ? "error"
    : (
      max - count < 15 ? "yellow.400" : "purple.400"
    );
  return (
    <Box position="absolute" bottom="5px" right="10px">
      <Flex alignItems="center" justifyContent="flex-end">
        <CircularProgress
          color={color}
          max={max}
          value={count}
          size="12px"
          thickness="15px"
          mr="1"
          capIsRound={true}
          trackColor="gray.300"
        />
        <Text color="gray.400" fontSize="10px" w="30px">
          {count}/{max}
        </Text>
      </Flex>
    </Box>
  );
};

export default CharacterCounter;
