import currentAgendaEmptyImage from "@assets/current-agenda-empty.svg";
import pastAgendaEmptyImage from "@assets/past-agenda-empty.svg";

export const defaultAgendaTabsData = [
  {
    id: `agendas-list-0`,
    label: "Agendas",
    content: {},
    emptyStateComponentProps: {
      text: "Your current agenda panel is empty",
      imgSrc: currentAgendaEmptyImage,
      containerProps: {
        background:
          "linear-gradient(268.62deg, rgba(137, 18, 202, 0.03) -50.64%, rgba(62, 0, 234, 0.03) 73.74%)",
      }
    }
  },
  {
    id: `agendas-list-past-agenda-1`,
    label: "Past Agendas",
    content: {
      isPastAgenda: true,
    },
    emptyStateComponentProps: {
      text: "Your past agenda panel is empty",
      imgSrc: pastAgendaEmptyImage
    }
  }
];
