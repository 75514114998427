import {
  InputGroup,
  InputLeftElement,
  Input,
  Icon,
  Box,
} from "@chakra-ui/react";
import { LinkIcon } from "@components/Svg";

const SendLinkInput = ({
  name,
  index,
  value,
  onChange,
  onBlur,
  isInvalid,
  readOnly,
  disabled,
}) => {
  return (
    <Box
      alignItems="center"
      mb={2}
      background="gray.50"
      border="1px solid"
      borderRadius="8px"
      borderColor="gray.50"
      {...(isInvalid ? { boxShadow: "0 0 0 2px red" } : {})}
    >
      <InputGroup key={index} background="gray.50">
        <InputLeftElement
          pointerEvents="none"
          children={
            <Icon bg="green.50" borderRadius="6px">
              <LinkIcon color="success" width="12px" />
            </Icon>
          }
        />
        <Input
          fontSize="12px"
          color="gray.500"
          readOnly={readOnly}
          variant="sendInput"
          name={name}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          placeholder="Add Link"
          isInvalid={isInvalid}
          disabled={disabled}
        />
      </InputGroup>
    </Box>
  );
};

export default SendLinkInput;
