const Select = {
  variants: {
    primary: {
      field: {
        fontSize: "14px",
        color: "gray.50",
        bg: "dark.700",
        border: "2px solid",
        borderColor: "transparent",
        _focus: {
          border: "2px solid",
          borderColor: "purple.300",
        },
        _invalid: {
          border: "2px solid",
          borderColor: "red.300",
        },
      },
      icon: {
        color: "dark.200",
      },
    },
    auth: {
      field: {
        fontSize: "14px",
        color: "gray.50",
        bg: "dark.700",
        border: "2px solid",
        borderColor: "transparent",
        _focus: {
          border: "2px solid",
          borderColor: "purple.300",
        },
        _invalid: {
          border: "2px solid",
          borderColor: "red.300",
        },
        _disabled: {
          color: "gray.400",
        },
      },
      icon: {
        color: "dark.200",
      },
    },
  },
};

export default Select;
