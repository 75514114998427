import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import { agendaApi } from "@store/api/agenda";

const initialState = {
  agendas: [],
  pastAgendas: [],
  currentAgenda: {},
  isLoading: true,
};

const agendasSlice = createSlice({
  name: "agenda",
  initialState,
  reducers: {
    updateAgendas: (state, action) => {
      const { _id } = action.payload;
      state.agendas = state.agendas.filter((agenda) => agenda._id !== _id);
    },
    updateCurrentAgenda: (state, action) => {
      const { agenda } = action.payload;
      state.currentAgenda = agenda;
    },
    updateIsAgendaLoading: (state, action) => {
      const { isLoading } = action.payload;
      state.isLoading = isLoading;
    },
    stopCurrentAgenda: (state) => {
      state.currentAgenda.live = false;
    },
    clearAgendaState: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      agendaApi.endpoints.fetchSingleAgenda.matchPending,
      (state, action) => {
        state.isLoading = true;
        state.currentAgenda = {};
      }
    );
    builder.addMatcher(
      isAnyOf(
        agendaApi.endpoints.fetchSingleAgenda.matchFulfilled,
        agendaApi.endpoints.goLiveAgenda.matchFulfilled,
        agendaApi.endpoints.stopLiveAgenda.matchFulfilled,
        agendaApi.endpoints.updateSingleAgenda.matchFulfilled
      ), (state, { payload }) => {
        state.isLoading = false;
        const index = state.agendas.findIndex(el => el._id === payload._id);

        if (index >= 0) {
          state.agendas[index] = payload;
        }

        state.currentAgenda = payload;
      }
    );
    builder.addMatcher(
      agendaApi.endpoints.createNewAgenda.matchFulfilled,
      (state, { payload }) => {
        state.agendas = [payload.agenda, ...state.agendas]
      }
    );
    builder.addMatcher(
      agendaApi.endpoints.duplicateAgenda.matchFulfilled,
      (state, { payload }) => {
        state.agendas = [payload, ...state.agendas]
      }
    );
    builder.addMatcher(
      agendaApi.endpoints.fetchAgendas.matchFulfilled,
      (state, { payload }) => {
        const { agendas } = payload;
        state.agendas = agendas?.agendas || [];
        state.pastAgendas = agendas?.pastAgendas || []
      }
    );
    builder.addMatcher(
      agendaApi.endpoints.deleteAgenda.matchFulfilled,
      (state, action) => {
        const agendaId = action.meta.arg?.originalArgs;
        state.agendas = state.agendas.filter((agenda) => agenda._id !== agendaId);
        state.currentAgenda = {};
      }
    );
    builder.addMatcher(
      agendaApi.endpoints.instantStart.matchFulfilled,
      (state, { payload }) => {
        state.agendas = [payload, ...state.agendas]
        state.currentAgenda = payload;
      }
    );
  },
});

const { reducer, actions } = agendasSlice;

export const {
  updateAgendas,
  updateCurrentAgenda,
  updateIsAgendaLoading,
  stopCurrentAgenda,
  clearAgendaState,
} = actions;
export default reducer;
