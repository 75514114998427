import { createSlice } from "@reduxjs/toolkit";
import { authApi } from "@store/api/auth";
import { paymentsApi } from "@store/api/payments";

const initialState = {
  data: {},
  isAuthenticated: false,
  isFirstView: false,
  platformParticipantUuid: null,
  isFetchingProfile: false,
  hasFetchProfileError: false,
};

export const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    addProfileData: (state, action) => {
      state.data = action.payload;
      state.isAuthenticated = true;
    },
    updateProfileData: (state, action) => {
      state.data = { ...state.data, ...action.payload };
    },
    removeAvatarImage: (state) => {
      state.data.avatar = "";
    },
    logOutUser: () => initialState,
    updatePlatformParticipantUuid: (state, { payload }) => {
      state.platformParticipantUuid = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      authApi.endpoints.fetchProfile.matchFulfilled,
      (state, { payload }) => {
        state.data = payload;
        state.isAuthenticated = true;
        state.isFetchingProfile = false;
        state.hasFetchProfileError = false;
      }
    );
    builder.addMatcher(
      authApi.endpoints.fetchProfile.matchPending,
      (state, _) => {
        state.isFetchingProfile = true;
      }
    );
    builder.addMatcher(
      authApi.endpoints.fetchProfile.matchRejected,
      (state, _) => {
        state.hasFetchProfileError = true;
      }
    );
    builder.addMatcher(
      authApi.endpoints.loginUser.matchFulfilled,
      (state, { payload }) => {
        state.data = payload.user;
        state.isAuthenticated = true;
      }
    );
    builder.addMatcher(
      authApi.endpoints.oAuthSignIn.matchFulfilled,
      (state, { payload }) => {
        state.data = payload.user;
        state.isAuthenticated = true;
      }
    );
    builder.addMatcher(
      authApi.endpoints.signUpUser.matchFulfilled,
      (state, { payload }) => {
        state.data = payload.user;
        state.isAuthenticated = true;
      }
    );
    builder.addMatcher(
      authApi.endpoints.updateProfile.matchFulfilled,
      (state, { payload }) => {
        state.data = payload;
        state.data = { ...state.data, ...payload.user };
      }
    );
    builder.addMatcher(
      authApi.endpoints.updateIsFirstView.matchFulfilled,
      (state, { payload }) => {
        state.data = payload.user;
      }
    );
    builder.addMatcher(
      paymentsApi.endpoints.validate.matchFulfilled,
      (state, { payload }) => {
        state.data.licenseType = payload.licenseType;
        state.data.trialPeriodStartDate = payload.trialPeriodStartDate;
      }
    );
    builder.addMatcher(
      authApi.endpoints.updateOnboarding.matchFulfilled,
      (state, { payload }) => {
        state.data = payload.user;
      }
    );
  },
});

const { reducer, actions } = profileSlice;

export const {
  addProfileData,
  updateProfileData,
  logOutUser,
  onboardUser,
  updatePlatformParticipantUuid,
  removeAvatarImage,
} = actions;
export default reducer;
