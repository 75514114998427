const Link = {
  baseStyle: {
    color: "blue.400",
    _hover: {
      textDecoration: "none",
    },
  },
};

export default Link;
