import React, { useMemo } from "react";
import { Image, Button } from "@chakra-ui/react";
import { FieldArray, FormikProvider } from "formik";
import addAnswerIcon from "@assets/add-circle-icon.svg";
import { PollAnswerInput } from "@components/ActivityTypes";
import { DEFAULT_MAX_OPTIONS } from "@constants/activities";

const MAX_POll_OPTIONS = 5;

const PollOptions = React.memo(
  ({ formik, sharedInputProps }) => {
    const { handleChange, values, errors, touched, handleBlur } = formik;

    const options = useMemo(() => {
      return values.options;
    }, [values.options]);

    return (
      <FormikProvider value={formik}>
        <FieldArray
          name="options"
          render={(arrayHelpers) => (
            <>
              {options.length > 0 &&
                options.map((option, index) => (
                  <PollAnswerInput
                    key={index}
                    index={index}
                    name={`options.${index}.option`}
                    option={option}
                    handleChange={handleChange}
                    handleOnBlur={handleBlur}
                    handleRemoveInput={() => arrayHelpers.remove(index)}
                    isDisabled={
                      !options.length > DEFAULT_MAX_OPTIONS ||
                      options.length === DEFAULT_MAX_OPTIONS
                    }
                    isInvalid={
                      errors.options &&
                      errors.options[index] &&
                      touched.options &&
                      touched.options[index]
                    }
                    {...sharedInputProps}
                  />
                ))}
              <Button
                w="100%"
                size="sm"
                variant="secondary"
                rightIcon={<Image src={addAnswerIcon} />}
                onClick={() => {
                  arrayHelpers.push({
                    option: "",
                    correct: false,
                  });
                }}
                disabled={options.length >= MAX_POll_OPTIONS}
              >
                Add Options
              </Button>
            </>
          )}
        />
      </FormikProvider>
    );
  },
  (prevProps, nextprops) =>
    !(
      prevProps.formik.values.options !== nextprops.formik.values.options ||
      prevProps.formik.errors.options !== nextprops.formik.errors.options ||
      prevProps.formik.touched.options !== nextprops.formik.touched.options
    )
);

export default PollOptions;
