import { configureStore } from "@reduxjs/toolkit";
// API
import { iconApi } from "./api/icon";
import { authApi } from "./api/auth";
import { analyticsApi } from "./api/analytics";
import { meetingApi } from "./api/meeting";
import { notificationsApi } from "./api/notifications";
import { agendaApi } from "./api/agenda";
import { activitiesApi } from "./api/activities";
import { paymentsApi } from "./api/payments";
import { reportApi } from "./api/report";
// Slices
import iconReducer from "./iconSlice";
import profileReducer from "./profileSlice";
import meetingReducer from "./meetingSlice";
import sidebarReducer from "./sidebarSlice";
import notificationsReducer from "./notificationsSlice";
import engagementReducer from "./engagementSlice";
import agendasReducer from "./agendasSlice";
import analyticsReducer from "./analyticsSlice";
import activityReducer from "./activitySlice";
import tourReducer from "./tourSlice";
import reportReducer from "./reportSlice";

//Middlewares
import activitySocketMiddlewares from "./middlewares/activitySocketMiddlewares";
import notificationMiddlewares from "./middlewares/notificationMiddlewares";
import meetingSocketMiddlewares from "./middlewares/meetingSocketMiddlewares";
import authenticationMiddleware from "./middlewares/authenticationMiddleware";
import generalUtilMiddleware from "./middlewares/generalUtilMiddleware";

const store = configureStore({
  reducer: {
    [iconApi.reducerPath]: iconApi.reducer,
    [authApi.reducerPath]: authApi.reducer,
    [analyticsApi.reducerPath]: analyticsApi.reducer,
    [meetingApi.reducerPath]: meetingApi.reducer,
    [notificationsApi.reducerPath]: notificationsApi.reducer,
    [agendaApi.reducerPath]: agendaApi.reducer,
    [activitiesApi.reducerPath]: activitiesApi.reducer,
    [paymentsApi.reducerPath]: paymentsApi.reducer,
    [reportApi.reducerPath]: reportApi.reducer,

    icons: iconReducer,
    profile: profileReducer,
    meeting: meetingReducer,
    sidebar: sidebarReducer,
    notifications: notificationsReducer,
    engagement: engagementReducer,
    agendas: agendasReducer,
    analytics: analyticsReducer,
    activity: activityReducer,
    tour: tourReducer,
    report: reportReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat([
      iconApi.middleware,
      authApi.middleware,
      analyticsApi.middleware,
      meetingApi.middleware,
      notificationsApi.middleware,
      agendaApi.middleware,
      activitiesApi.middleware,
      paymentsApi.middleware,
      reportApi.middleware,
      generalUtilMiddleware,
      authenticationMiddleware,
      activitySocketMiddlewares,
      notificationMiddlewares,
      meetingSocketMiddlewares,
    ]),
});

export default store;
