import React from "react";
import { Box, Flex } from "@chakra-ui/react";
import { IconViewer, CenteredFixedWidthContainer } from "@components";
import { HOST_CONTAINER_WIDTH } from "@constants/layoutDimensions";

const HostContainer = ({ children }) => {
  return (
    <CenteredFixedWidthContainer>
      <Flex
        justifyContent="flex-end"
        height="100%"
        width="100%"
        position="relative"
      >
        <Box
          height="100%"
          width="20px"
          maxW={HOST_CONTAINER_WIDTH}
          left="2"
          position="absolute"
        >
          <IconViewer />
        </Box>
        <Flex flexDirection="column" height="100%" width="275px" bg="gray.100">
          <Box height="calc(100% - 50px - 16px)" overflowY="scroll">
            {children}
          </Box>
        </Flex>
      </Flex>
    </CenteredFixedWidthContainer>
  );
};

export default HostContainer;
