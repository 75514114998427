import { createSlice } from "@reduxjs/toolkit";

const initialState = {
 data :{}
};

const AnalyticsSlice = createSlice({
  name: "analytics",
  initialState,
  reducers: {
    startAnalyticsred: (state, action) => {
      state.data = action.payload;;
      state.data = action.payload;
    },
    stopAnalyticsred: (state, action) => {
      return initialState;
    },
  },
});








const { reducer, actions } = AnalyticsSlice;

export const {
  startAnalyticsred,
  stopAnalyticsred
} = actions;

export default reducer;

