import { Link, Image, Flex } from "@chakra-ui/react";
import { useSdkUtils } from "@hooks";
import { generateSendToolUrl } from "@utils/helpers";

const IconLink = ({ icon, url, text }) => {
  const { openUrl } = useSdkUtils();

  return (
    <Flex alignItems="center">
      <Image
        src={icon}
        boxSize="5"
        mr="1"
        _hover={{ cursor: "pointer" }}
        onClick={() => openUrl(generateSendToolUrl(url))}
      />
      <Link
        href="#"
        isExternal
        fontSize="sm"
        fontWeight="700"
        color="primary.400"
        textDecoration="underline"
        textUnderlineOffset="4px"
        onClick={() => openUrl(generateSendToolUrl(url))}
      >
        {text}
      </Link>
    </Flex>
  );
};

export default IconLink;
