const Checkbox = {
  variants: {
    // TODO: create standardized style for checkbox with label
    rounded: {
      control: {
        borderRadius: "full",
      },
    },
    settings: {
      icon: {
        color: "white",
        boxSize: "1.5",
      },
      control: {
        border: "1px",
        borderColor: "gray.300",
        borderRadius: "4px",
      },
      label: {
        fontWeight: "400",
        fontSize: "10px",
        color: "dark.400",
      },
    },
    primary: {
      control: {
        border: "1px",
        borderColor: "gray.300",
        borderRadius: "3px",
      },
      label: {
        fontWeight: "500",
        fontSize: "12px",
        color: "dark.400",
      },
    },
  },
};

export default Checkbox;
