import { Heading, Grid, Divider, IconButton, Skeleton, Box } from "@chakra-ui/react";
import SVG from "react-inlinesvg";

const sharedprops = {
  mt: 1,
  w: "full",
  height: "30px"
}

const IconPickerCategory = ({ isLoading, title, icons, divider, handleIcon }) => {
  return (
    <>
      <Heading
        bgGradient="linear(to-r, purple.500, purple.400)"
        fontSize="12px"
        fontWeight="700"
        color="gray.300"
        borderRadius={6}
        p="2"
      >
        {title}
      </Heading>
      {
        isLoading ? (
          <Skeleton {...sharedprops} />
        ) : (
          icons.length > 0 ? (
            <Grid gridTemplateColumns="repeat(6, 1fr)" gridGap="10px" rowGap="0">
              {icons.map((icon) => (
                <IconButton
                  key={icon.id}
                  variant="ghostIconButton"
                  icon={
                    <SVG
                      id={icon.id}
                      width="100%"
                      height="18px"
                      fill="white"
                      cursor="pointer"
                      src={icon.url}
                      onClick={() => handleIcon(icon.id)}
                    />
                  }
                />
              ))}
            </Grid>
          ) : <Box {...sharedprops} />
        )
      }
      {divider ? <Divider mb="2" borderBottomColor="dark.300" /> : null}
    </>
  );
};

export default IconPickerCategory;
