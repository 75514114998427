import { Link } from "react-router-dom";
import {
  Box,
  Image,
  Icon,
  Flex,
  useMediaQuery,
  Button,
  useDisclosure,
  DarkMode,
  forwardRef
} from "@chakra-ui/react";
import { useDispatch } from "react-redux";
import { useAuth, useMeeting, useProfile } from "@hooks";
import {
  isWebUser,
  isZoomApp,
  generateUrlWithQueryParams,
} from "@utils/helpers";
import {
  NavbarDropdown,
  LeaveMeetingModal,
  HelpModal,
  AttendeeAndHostToggle
} from "@components";
import { $Routes } from "@routes";
import { removeWebUserFromMeeting } from "@store/customActions";
import townhallLogoOnly from "@assets/townhall-logo-only.svg";
import townhallText from "@assets/townhall_text.svg";
import { IoHelpCircle } from "react-icons/io5";

const Navbar = forwardRef(({_}, ref) => {
  const [isMobile] = useMediaQuery("(max-width: 375px)");
  // TODO: Refactor to have only one useDisclosure and modal to display help and leave meeting
  const {
    isOpen: isLeaveMeetingOpen,
    onOpen: onOpenLeaveMeeting,
    onClose: onCloseLeaveMeeting,
  } = useDisclosure();
  const {
    isOpen: isHelpOpen,
    onOpen: onOpenHelp,
    onClose: onCloseHelp,
  } = useDisclosure();
  const dispatch = useDispatch();
  const { profile } = useAuth();
  const { isAuthenticated } = useProfile();
  const {
    isMeetingActive,
    platformMeetingId,
    platformMeetingInstanceId,
    isHostOrCoHost,
  } = useMeeting();

  const homeUrl = isZoomApp
    ? $Routes.homePage
    : isMeetingActive
    ? generateUrlWithQueryParams($Routes.rootPage, {
        platformMeetingId,
        platformMeetingInstanceId,
      })
    : $Routes.homePage;

  const leaveMeeting = () => {
    dispatch(
      removeWebUserFromMeeting({
        platformMeetingInstanceId,
        userId: profile?._id,
      })
    );
    onCloseLeaveMeeting();
  };

  return (
    <Box
      bgColor="dark.800"
      padding="0.5rem"
      boxShadow="0px 5px 140px rgba(0, 12, 59, 0.2)"
      position="static"
      ref={ref}
    >
      <LeaveMeetingModal
        isOpen={isLeaveMeetingOpen}
        handleClose={onCloseLeaveMeeting}
        handleLeaveMeeting={leaveMeeting}
      />
      <HelpModal isOpen={isHelpOpen} handleClose={onCloseHelp} />
      <Flex maxWidth="9xl" justifyContent="space-between" alignItems="center">
        <Link to={homeUrl}>
          <Flex>
            <Image
              src={townhallLogoOnly}
              alt="Townhall"
              width={isMobile ? 12 : 14}
            />
            <Image
              src={townhallText}
              alt="Townhall"
              display={isMobile && "none"}
            />
          </Flex>
        </Link>
        <Flex alignItems="center" justify="space-around" width="30">
          {isAuthenticated && isWebUser(profile) && !isMeetingActive ? (
            <DarkMode>
              <Button
                leftIcon={
                  <Icon as={IoHelpCircle} color="dark.200" boxSize="5" />
                }
                variant="secondary"
                mr={3}
                onClick={onOpenHelp}
              >
                Help
              </Button>
            </DarkMode>
          ) : null}
          {isWebUser(profile) && isMeetingActive ? (
            <Button variant="error" mr={3} onClick={onOpenLeaveMeeting}>
              Leave
            </Button>
          ) : null}
          {isZoomApp && isAuthenticated ? <AttendeeAndHostToggle isHostOrCoHost={isHostOrCoHost} /> : null}
          {isAuthenticated && (
            <Flex p={1} bg="purple.700" borderRadius="full">
              <NavbarDropdown user={profile} />
            </Flex>
          )}
        </Flex>
      </Flex>
    </Box>
  );
});

export default Navbar;
