import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { customBaseQuery } from "@store/api/baseQueries";

export const meetingApi = createApi({
  reducerPath: "meetingApi",
  baseQuery: customBaseQuery(
    fetchBaseQuery({
      baseUrl: `${process.env.REACT_APP_ZOOMAPP_BACKEND_BASE_URL}/meeting`,
    }),
  ),
  endpoints: (builder) => {
    return {
      checkExistingMeeting: builder.mutation({
        query: (body) => ({
          url: "/participant/rejoin",
          method: "POST",
          body,
        }),
      }),

      joinMeeting: builder.mutation({
        query: (body) => ({
          url: "/participant/join",
          method: "POST",
          body,
        }),
      }),

      joinMeetingFromWeb: builder.mutation({
        query: (body) => ({
          url: "/web/participant/join",
          method: "POST",
          body,
        }),
      }),

      leaveMeetingFromWeb: builder.mutation({
        query: (body) => ({
          url: "/web/participant/leave",
          method: "POST",
          body,
        }),
      }),

      checkWebUserLiveMeeting: builder.mutation({
        query: (body) => ({
          url: "/live",
          method: "POST",
          body,
        }),
      }),
    };
  },
});

export const {
  useCheckExistingMeetingMutation,
  useJoinMeetingMutation,
  useJoinMeetingFromWebMutation,
  useLeaveMeetingFromWebMutation,
  useCheckWebUserLiveMeetingMutation,
  useGetMeetingHostsQuery
} = meetingApi;
