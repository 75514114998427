import { Icon } from "@chakra-ui/react";

const LinkIcon = ({ color, ...rest }) => (
  <Icon viewBox="0 0 14 14" stroke={color} fill="none" { ...rest }>
    <path
      d="M7.41291 10.7123L6.58795 11.5372C5.44892 12.6762 3.60219 12.6762 2.46316 11.5372C1.32413 10.3982 1.32413 8.55145 2.46316 7.41242L3.28812 6.58746M10.7127 7.41242L11.5377 6.58746C12.6767 5.44843 12.6767 3.6017 11.5377 2.46267C10.3987 1.32364 8.55193 1.32364 7.41291 2.46267L6.58795 3.28763M4.95876 9.04159L9.04209 4.95826"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Icon>
);

export default LinkIcon;
