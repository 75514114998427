import { createSocketSubscription } from "@socket/utils";

export const iconChat = createSocketSubscription("sendIcon");
export const receiveEPulse = createSocketSubscription("sendEpulse");
export const sendPoints = createSocketSubscription("sendPoints");

export const participantJoinedMeeting = createSocketSubscription("meeting.participant_joined");
export const participantLeftMeeting = createSocketSubscription("meeting.participant_left");
export const meeetingEnded = createSocketSubscription("meeting.ended");

export const recieveAnalytics = createSocketSubscription("sendAnalytics");
export const notification = createSocketSubscription("notification");
export const participantActivityEnded = createSocketSubscription("participant.activityEnded");

export const activityStarted = createSocketSubscription("sendActivityStarted");
export const activityEnded = createSocketSubscription("sendActivityEnded");
export const activityResults = createSocketSubscription("sendActivityResults");
export const disconnectSocketListener = createSocketSubscription("disconnect");
