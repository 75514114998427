import { Button, Image, useTheme } from "@chakra-ui/react";

const SecondaryButton = ({ icon, iconSize, onClick, isLoading, width, children }) => {
  const theme = useTheme();

  return (
    <Button
      isLoading={isLoading}
      onClick={onClick}
      backgroundColor="granite"
      border={`2px solid ${theme.colors.granite}`}
      width={width || 36}
      color="gray.50"
      fontSize={12}
      _hover={{
        border: `2px solid ${theme.colors.granite}`,
        backgroundColor: "transparent",
      }}
      _active={{
        backgroundColor: "transparent",
        transform: "scale(0.98)",
      }}
      {...(icon && { leftIcon: <Image src={icon} w={iconSize || 6} /> })}
    >
      {children}
    </Button>
  );
};

export default SecondaryButton;
