import { Box, Flex, Text, Button } from "@chakra-ui/react";

const AgendaMeetingItem = ({ title, onEdit, isDisabled }) => {
  return (
    <Box border="1px solid #F5F5F7" p="10px" borderRadius="8px" mb="8px">
      <Flex alignItems="center" justifyContent="space-between">
        <Text fontSize="12px" isTruncated={true} maxW="180px">
          {title}
        </Text>
        <Flex>
          <Button
            variant="secondary"
            size="xs"
            onClick={onEdit}
            disabled={isDisabled}
          >
            Edit
          </Button>
        </Flex>
      </Flex>
    </Box>
  );
};

export default AgendaMeetingItem;
