import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { customBaseQuery } from "@store/api/baseQueries";

export const reportApi = createApi({
  reducerPath: "reportApi",
  baseQuery: customBaseQuery(
    fetchBaseQuery({
      baseUrl: `${process.env.REACT_APP_ZOOMAPP_BACKEND_BASE_URL}/report`,
    }),
  ),
  endpoints: (builder) => {
    return {
      getAgendaReport: builder.query({
        query: (query) => ({
          url: `/`,
          method: "GET",
          params: query
        }),
      }),      
    };
  },
});

export const { useGetAgendaReportQuery } = reportApi;
