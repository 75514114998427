import { useMemo, memo } from "react";
import { Icon, Text, Alert, AlertDescription } from "@chakra-ui/react";
import { FormikProvider, Field } from "formik";
import { FiInfo } from "react-icons/fi";
import { SendLinkInput } from "@components/ActivityTypes/Send";

const SendOptions = memo(
  ({ formik }) => {
    const { values, errors, touched } = formik;

    const options = useMemo(() => {
      return values.options;
    }, [values.options]);

    return (
      <>
        <FormikProvider value={formik}>
          {options.length > 0 &&
            options.map((_, index) => (
              <Field
                key={`sendurl-${index}`}
                index={index}
                as={SendLinkInput}
                name={`options[${index}].option`}
                isInvalid={
                  errors.options &&
                  errors.options[index] &&
                  touched.options &&
                  touched.options[index]
                }
              />
            ))}
        </FormikProvider>
        <Alert variant="agenda">
          <Icon as={FiInfo} color="gray.600" mr="1" />
          <AlertDescription>
            Link must include{" "}
            <Text as="span" color="gray.500">
              https://
            </Text>{" "}
            or{" "}
            <Text as="span" color="gray.500">
              http://
            </Text>
          </AlertDescription>
        </Alert>
      </>
    );
  },
  (prevProps, nextprops) =>
    !(
      prevProps.formik.values.options !== nextprops.formik.values.options ||
      prevProps.formik.errors.options !== nextprops.formik.errors.options ||
      prevProps.formik.touched.options !== nextprops.formik.touched.options
    )
);

export default SendOptions;
