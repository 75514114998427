import { Box } from "@chakra-ui/react";

const CardFooter = ({ children, ...restProps }) => {
  return (
    <Box
      borderRadius="0 0 10px 10px"
      border="1px solid #EEEFF2"
      boxShadow="0px 10px 50px rgba(0, 35, 178, 0.05)"
      {...restProps}
    >
      {children}
    </Box>
  );
};

export default CardFooter;
