import { useSelector } from "react-redux";
import { getFromLocalStorage } from '@utils/localStorage';
import { JWT_TOKEN_NAME } from "@constants";

const useProfile = () => {
  const profile = useSelector((state) => state.profile.data);
  const isAuthenticated = useSelector((state) => state.profile.isAuthenticated);
  const profileColor = useSelector((state) => state.profile.data?.color);
  const platformParticipantUuid = useSelector((state) => state.profile?.platformParticipantUuid);
  const profileId = useSelector((state) => state.profile.data?._id);
  const hasFetchProfileError = useSelector((state) => state.profile.hasFetchProfileError);
  const isFetchingProfile = useSelector((state) => state.profile.isFetchingProfile);

  // TODO: save token in store and update store when token changes
  // this prevents the expensive operation of having to go to local storage every time
  const token = getFromLocalStorage(JWT_TOKEN_NAME);
  const hasToken = !!token;

  return {
    profile,
    profileColor,
    platformParticipantUuid,
    isAuthenticated,
    hasToken,
    profileId,
    hasFetchProfileError,
    isFetchingProfile
  };
};

export default useProfile;
