import { Button } from "@chakra-ui/react";

const PrimaryButton = ({
  type,
  isLoading,
  loadingText,
  isDisabled,
  isFullWidth,
  onClick,
  children,
  leftIcon,
  rightIcon,
  ...restProps
}) => {
  return (
    <Button
      type={type}
      bg="linear-gradient(268.62deg, #8912CA -50.64%, #3E00EA 73.74%)"
      fontSize="15px"
      borderRadius="8px"
      color="white"
      fontWeight="800"
      onClick={onClick}
      isLoading={isLoading}
      loadingText={loadingText}
      isDisabled={isDisabled}
      lineHeight="15px"
      isFullWidth={isFullWidth}
      disabled={isDisabled}
      leftIcon={leftIcon}
      rightIcon={rightIcon}
      {...restProps}
      _hover={{
        bg: `{${isDisabled} ? "granite": "linear-gradient(268.62deg, #8912CA -50.64%, #3E00EA 73.74%)"}`,
        ...(restProps._hover || {})
      }}
      _active={{
        bg: "linear-gradient(268.62deg, #8912CA -50.64%, #3E00EA 73.74%)",
        transform: "scale(0.98)",
        ...(restProps._active || {})
      }}
      _disabled={{
        bg: "granite",
        ...(restProps._disabled || {})
      }}
    >
      {children}
    </Button>
  );
};

PrimaryButton.defaultProps = {
  type: "submit",
};

export default PrimaryButton;
